import useSWR from "swr";

import { ContractId } from "@/services/ContractsService";

import { useChatService } from "../ChatService.context";

function useConnectChannel({ contractId }: { contractId: ContractId }) {
  const { streamClient } = useChatService();
  const channelId = contractId ? `CHAT_${contractId}` : null;

  const { data: channel, isLoading } = useSWR(
    !!streamClient && !!channelId ? `/chats/${channelId}/connect` : null,
    async () => {
      if (!streamClient || !channelId) {
        return Promise.reject();
      }

      const channel = streamClient.channel("messaging", channelId);

      return Promise.resolve(channel);
    },
    {
      initialData: { channel: null },
      errorRetryInterval: 10000,
      errorRetryCount: 5,
      revalidateOnFocus: false,
    }
  );

  return {
    channel: channel || null,
    isConnecting: isLoading,
  };
}

export default useConnectChannel;
