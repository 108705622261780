"use client";

import React, { Fragment, useCallback } from "react";
import { ChannelList } from "stream-chat-react";
import styled from "@emotion/styled";

import ChatCard, { ChatCardLoading } from "@/features/chat/ChatCard";
import { useAuth } from "@/services/Authentication";
import { useContractUrlId } from "@/services/ContractsService";
import ErrorBoundary from "@/components/input/ErrorBoundary";

import { StyledChatList } from "../ChatsPage.styles";
import NoChats from "./NoChats";

const loadingChatListJsx = (
  <>
    <ChatCardLoading />
    <hr />
    <ChatCardLoading />
    <hr />
    <ChatCardLoading />
  </>
);

const StyledNoChatsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChatList: React.FC = () => {
  const { loggedInUserId } = useAuth();
  const { idFromUrl } = useContractUrlId();

  const render = useCallback(
    () => (
      <ChannelList
        key="test"
        showChannelSearch
        allowNewMessagesFromUnfilteredChannels
        recoveryThrottleIntervalMs={100000}
        sendChannelsToList
        filters={{ members: { $in: [`${loggedInUserId}`] } }}
        sort={{ last_message_at: -1 }}
        LoadingIndicator={() => loadingChatListJsx}
        Preview={(item) => {
          const { displayTitle = "", lastMessage, channel, unread } = item;
          const contractId = (channel?.cid || "").split("messaging:CHAT_")[1];
          const lastMessageDate = lastMessage?.updated_at
            ? new Date(lastMessage.updated_at)
            : undefined;

          const hasAttachments =
            !!lastMessage?.attachments?.length ||
            (channel?.state?.messageSets).some((set) =>
              set.messages.some((message) => !!message.attachments?.length)
            );

          if (!contractId || !displayTitle) {
            return null;
          }

          const isActive = contractId === idFromUrl;

          return (
            <Fragment>
              <ChatCard
                active={isActive}
                title={displayTitle}
                content={lastMessage?.text || ""}
                contractId={contractId}
                lastMessageDate={lastMessageDate}
                unreadMessageCount={unread || 0}
                username=""
                avatarImage=""
                hasAttachment={hasAttachments}
              />
              <hr />
            </Fragment>
          );
        }}
        EmptyStateIndicator={() => (
          <StyledNoChatsContainer>
            <NoChats />
          </StyledNoChatsContainer>
        )}
      />
    ),
    [idFromUrl, loggedInUserId]
  );

  return (
    <StyledChatList>
      <ErrorBoundary fallback={loadingChatListJsx}>{render()}</ErrorBoundary>
    </StyledChatList>
  );
};

export default ChatList;
