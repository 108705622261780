import React, { useCallback, useEffect, useState } from "react";
import lodashGet from "lodash/get";

import Icon from "@/components/misc/Icon";
import { Value } from "@/features/contracts/ContractWorkInput";
import Button from "@/components/input/Button";
import {
  useContractDetails,
  useContractMilestone,
  useReviewContractMilestone,
} from "@/services/ContractsService";
import { FileObject } from "@/features/input/FilesInput";
import ContractChatButton from "@/features/contracts/ContractChatButton";

import {
  StyledBottomSection,
  StyledButtonsContainer,
  StyledContainer,
  StyledContent,
  StyledInput,
} from "./ReviewSubmission.styles";

interface IReviewWorkButtonProps {
  contractId: string;
  milestoneId: number;
  className?: string;
  onApprove?: (p: Promise<any>) => void;
  onIsApprovingChange?: (value: boolean) => void;
}

const ReviewSubmission: React.FC<IReviewWorkButtonProps> = ({
  contractId,
  milestoneId,
  className,
  onApprove,
  onIsApprovingChange,
}) => {
  const { reload: reloadContractDetails } = useContractDetails({ contractId });
  const { isLoading, data } = useContractMilestone({ milestoneId });

  const [value, setValue] = useState<Value>({
    attachments: [],
    content: "",
  });

  const { approve, isApproving } = useReviewContractMilestone({
    contractId,
    milestoneId,
  });

  const handleApprove = useCallback(() => {
    if (onIsApprovingChange) {
      onIsApprovingChange(true);
    }

    // const p = Promise.resolve();
    const p = approve().then(() => {
      reloadContractDetails();
    });

    p.finally(() => {
      if (onIsApprovingChange) {
        onIsApprovingChange(false);
      }
    });

    if (onApprove) {
      onApprove(p);
    }
  }, [approve, reloadContractDetails, onApprove, onIsApprovingChange]);

  useEffect(() => {
    const work = lodashGet(data, "work[0]", null);

    if (!work) {
      return;
    }

    const content = lodashGet(work, "description", "");
    const files = lodashGet(work, "files", []);
    const attachments = files.map(
      (file) => ({ mediaFile: file } satisfies FileObject)
    );

    setValue({ content, attachments });
  }, [data]);

  if (isLoading) {
    return null;
  }

  return (
    <StyledContainer className={className}>
      <StyledInput readOnly value={value} onChange={setValue} />

      <StyledBottomSection>
        <StyledContent>
          Once you approve this work payment will be released. This action
          cannot be reversed.
        </StyledContent>

        <StyledButtonsContainer className="mt-3">
          <ContractChatButton
            onlyFirstName
            variant="secondary"
            colorVariant="gray"
            defaultText="Chat"
            contractId={contractId}
            prependContent={
              <Icon
                isSrcRelative
                src="chat.svg"
                size="xs"
                colorVariant="black"
              />
            }
          />

          <Button onClick={handleApprove} disabled={isApproving}>
            {isApproving ? "Approving" : "Approve"}
          </Button>
        </StyledButtonsContainer>
      </StyledBottomSection>
    </StyledContainer>
  );
};

export default ReviewSubmission;
