import {
  ContractCreatorType,
  ContractMilestoneStatus,
} from "@/services/ContractsService";

export const MILESTONE_STATUS_CONFIG: Partial<
  Record<
    ContractMilestoneStatus,
    {
      content: {
        [ContractCreatorType.Payee]?: {
          status?: {
            content: React.ReactNode;
          };
        };
        [ContractCreatorType.Payer]?: {
          status?: {
            content: React.ReactNode;
          };
        };
        common?: {
          status?: {
            content: React.ReactNode;
          };
        };
      };
    }
  >
> = {
  [ContractMilestoneStatus.Published]: {
    content: {
      common: {
        status: {
          content: "Active",
        },
      },
    },
  },
  [ContractMilestoneStatus.Accepted]: {
    content: {
      common: {
        status: {
          content: "Pending Funding",
        },
      },
      contractor: {
        status: {
          content: "Active",
        },
      },
    },
  },
  [ContractMilestoneStatus.Funded]: {
    content: {
      common: {
        status: {
          content: "In Progress",
        },
      },
    },
  },
  [ContractMilestoneStatus.ReadyForWork]: {
    content: {
      common: {
        status: {
          content: "In Progress",
        },
      },
    },
  },
  [ContractMilestoneStatus.Review]: {
    content: {
      common: {
        status: {
          content: "In Progress",
        },
      },
      client: {
        status: {
          content: "Fund Requested",
        },
      },
    },
  },
  [ContractMilestoneStatus.FundRequested]: {
    content: {
      common: {
        status: {
          content: "In Progress",
        },
      },
      client: {
        status: {
          content: "Fund Requested",
        },
      },
    },
  },
  [ContractMilestoneStatus.Completed]: {
    content: {
      common: {
        status: {
          content: "Completed",
        },
      },
    },
  },
};
