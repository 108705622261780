import React, { useMemo } from "react";

import { User } from "@/features/chat/ChatRoom";
import { getParticipantFullName } from "@/services/ContractsService";
import { useAuth } from "@/services/Authentication";
import { ChatRoom as ChatRoomNew } from "@/services/ChatServiceNew";
import { useResponsive } from "@/styles";

import { useChatRoomPage } from "../ChatRoomPage.context";
import ChatMenuButton from "./ChatMenuButton";
import StatusSection from "./ContractStatus";
import NewChat from "./NewChat";

const ChatRoom: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { contractDetails, showNewChatFlowState } = useChatRoomPage();
  const { id: contractId } = contractDetails;

  const { loggedInUserId } = useAuth();

  const roomUsers = useMemo(
    () =>
      contractDetails.participants.map(
        (p) =>
        ({
          id: p.user_id,
          name: getParticipantFullName(p),
          image: p?.user?.picture || "",
          email: p.user.email,
        } satisfies User)
      ),
    [contractDetails.participants]
  );


  return (
    <ChatRoomNew
      showBackButton={isScreenSmallerThanTablet}
      contractId={contractId}
      currentUserId={`${loggedInUserId}`}
      users={roomUsers.map((u) => ({ ...u, id: `${u.id}` }))}
      header={{
        appendContent: <ChatMenuButton />,
        appendBelowContent: <StatusSection contractDetails={contractDetails} />,
      }}
      hideInputs={showNewChatFlowState.state}
      footer={{
        appendContent: showNewChatFlowState.state ? <NewChat /> : null,
      }}
    />
  );
};

export default ChatRoom;
