import { useEffect } from "react";
import useSWR from "swr";

import { useAuth } from "@/services/Authentication";

import { useChatService } from "../ChatService.context";

function useUnreadCount() {
  const { streamClient } = useChatService();
  const { loggedInUserId } = useAuth();

  const {
    data: { unreadCount } = { unreadCount: 0 },
    isLoading,
    mutate,
  } = useSWR(
    !!streamClient ? `/chats/unread_count` : null,
    async () => {
      if (!streamClient) {
        return {
          unreadCount: 0,
        };
      }

      const data = await streamClient.getUnreadCount(`${loggedInUserId}`);
      const { total_unread_count: unreadCount } = data;

      return {
        unreadCount,
      };
    },
    {
      errorRetryInterval: 10000,
      errorRetryCount: 5,
    }
  );

  useEffect(() => {
    let event: null | {
      unsubscribe: () => void;
    } = null;

    if (streamClient) {
      event = streamClient.on("notification.mark_read", (data) => {
        mutate({ unreadCount: data.total_unread_count || 0 });
      });
    }

    return () => {
      if (event) {
        event.unsubscribe();
      }
    };
  }, [streamClient, mutate]);

  useEffect(() => {
    let event: null | {
      unsubscribe: () => void;
    } = null;

    if (streamClient) {
      event = streamClient.on("notification.message_new", (data) => {
        mutate({ unreadCount: data.total_unread_count || 0 });
      });
    }

    return () => {
      if (event) {
        event.unsubscribe();
      }
    };
  }, [streamClient, mutate]);

  useEffect(() => {
    let event: null | {
      unsubscribe: () => void;
    } = null;

    if (streamClient) {
      event = streamClient.on("notification.mark_unread", (data) => {
        mutate({ unreadCount: data.total_unread_count || 0 });
      });
    }

    return () => {
      if (event) {
        event.unsubscribe();
      }
    };
  }, [streamClient, mutate]);

  return {
    unreadCount,
    isLoading,
  };
}

export default useUnreadCount;
