import { useCallback, useState } from "react";
import isObject from "lodash/isObject";
import { useSearchParams } from "react-router-dom";

/**
 * A custom hook that syncs state with a URL search parameter.
 * Supports string, number, boolean, and object values.
 * @param key The search parameter key to sync with.
 * @param defaultValue The default value for the state.
 * @returns A stateful value, and a function to update it.
 */
function useParamState<T extends string | number | boolean | object>(
  key: string,
  defaultValue?: T,
  config: { encode?: boolean; parseJson?: boolean } = {}
): [T | undefined, (newValue?: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);
  const { encode = isObject(defaultValue), parseJson = true } = config;

  const [state, setState] = useState(() => {
    if (paramValue === null) {
      return defaultValue;
    }
    try {
      let value = paramValue;
      if (encode) {
        value = atob(value);
      }

      if (parseJson) {
        return JSON.parse(value) as T;
      }

      return value as T;
    } catch {
      return paramValue as T;
    }
  });

  const setParamState = useCallback(
    (newValue?: T) => {
      try {
        setState(newValue as T);
        const newSearchParams = new URLSearchParams(searchParams);

        if (newValue === undefined) {
          newSearchParams.delete(key);
        } else {
          let urlValue = parseJson
            ? JSON.stringify(newValue)
            : (newValue as string);

          if (encode) {
            urlValue = btoa(urlValue);
          }

          newSearchParams.set(key, urlValue);
        }

        setSearchParams(newSearchParams);
      } catch {
        const newSearchParams = new URLSearchParams(searchParams);
        if (newValue === undefined) {
          newSearchParams.delete(key);
        } else {
          newSearchParams.set(key, JSON.stringify(newValue));
        }
      }
    },
    [key, encode, searchParams, setSearchParams, parseJson]
  );

  return [state, setParamState];
}

export default useParamState;
