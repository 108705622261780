import React from "react";
import { usePress } from "react-aria";
import isString from "lodash/isString";

import { Label } from "@/components/Typography";

import {
  StyledContainer,
  StyledContent,
  StyledCta,
  StyledIcon,
  StyledIconContainer,
  StyledLink,
  StyledRightSection,
} from "./ActionCard.styles";
import { IActionCardProps } from "./ActionCard.types";

const ActionCard: React.FC<IActionCardProps> = ({
  icon,
  content,
  ctaProps,
  className,
  color,
  link,
}) => {
  const { pressProps, isPressed } = usePress({});

  return (
    <StyledContainer
      className={className}
      {...(pressProps as any)}
      style={{
        background: isPressed
          ? "var(--clr-background-ukwn-1, #FAFAFA)"
          : "var(--clr-background-white, #fff)",
      }}
      to={link}
    >
      {!!icon &&
        (isString(icon) ? (
          <StyledIconContainer style={{ backgroundColor: color }}>
            <StyledIcon isSrcRelative src={icon} customSize="1.3125rem" />
          </StyledIconContainer>
        ) : (
          icon
        ))}

      <StyledRightSection>
        <StyledContent size="md">{content}</StyledContent>
        {!!ctaProps && (
          <StyledCta {...ctaProps} className="mt-2">
            <Label size="md">{ctaProps.children}</Label>
          </StyledCta>
        )}
      </StyledRightSection>

      {!!link && <StyledLink to={link} />}
    </StyledContainer>
  );
};

export default ActionCard;
