import { joinClassNames } from "@/utils/classNames";
import { FC, useRef } from "react";
import { useListBox, useOption } from "react-aria";

const Option: FC<any> = ({ item, state }) => {
  let ref = useRef(null);
  let { optionProps, isSelected, isFocused } = useOption(
    { key: item.key },
    state,
    ref
  );

  return (
    <li
      {...optionProps}
      ref={ref}
      className={joinClassNames("option", isSelected && "selected")}
      style={{
        border:
          !isSelected && isFocused
            ? "1px solid var(--clr-primary-100, #5f57ff)"
            : "1px solid transparent",
      }}
    >
      {item.rendered}
    </li>
  );
};

const Listbox: FC<any> = (props) => {
  let ref = useRef(null);
  let { listBoxRef = ref, state } = props;
  let { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <ul {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
};

export default Listbox;
