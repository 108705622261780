"use client";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Card from "@/components/data/Card";
import { screenLargerThan, screenSmallerThan } from "@/styles";
import ContractTable from "@/features/contracts/ContractTable";

export const StyledPage = styled.div`
  --gap: 1.75rem;
  padding: 0.25rem 1rem 2rem 1rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap, 1.75rem);
`;

export const StyledContractTable = styled(ContractTable)`
  flex: 1;
`;

export const TopContainer = styled.div`
  gap: 1.25rem;
  min-height: 15rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));

  & > * {
    flex: 1;
  }
`;

export const StyledValue = styled.div`
  color: var(--clr-text-900, #0d0d12);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
`;

export const StyledPrimaryHeading = styled.div`
  display: flex;
  align-items: center;
  color: var(--clr-text-900, #0d0d12);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
`;

export const StyledLink = styled(Link)`
  color: var(--clr-text-500, #666d80);
  font-size: 0.875rem;
  line-height: 20px;
  text-transform: capitalize;
`;

export const StyledSectionHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSecondaryHeading = styled.div<{ $bold?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: ${({ $bold }) =>
    $bold
      ? "var(--clr-neutral-solid-900, #0d0d12)"
      : "var(--clr-neutral-solid-500, #525866)"};
  font-size: ${({ $bold }) => ($bold ? "1rem" : " 0.875rem")};
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: capitalize;

  & + * {
    margin-top: 0.5rem;
  }
`;

//------------| DESKTOP

export const StyledCardsContainer = styled.div`
  ${screenSmallerThan.desktop} {
    & > *:last-child {
      grid-column: span 2;
    }
  }

  ${screenSmallerThan.custom(1370)} {
    & > *:not(:first-child) {
      margin-top: 2rem;
    }
  }

  ${screenLargerThan.custom(1370)} {
    display: grid;
    gap: var(--gap, 1.5rem);
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledBalanceActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > *:first-child {
    flex: 1;
    display: flex;
    align-items: stretch;
    gap: var(--gap, 1.75rem);
    height: 100%;

    & > * {
      flex: 1;
      min-height: 16.5rem;
    }
  }
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  & > *:last-child {
    flex: 1;
  }
`;
