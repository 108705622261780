import { PredictedPlace } from "./Location.types";

function shuffle<T>(array: T[]): T[] {
  array = [...array];

  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

const PREDICTIONS: PredictedPlace[] = [
  {
    description: "Indore, Madhya Pradesh, India",
    matched_substrings: [
      {
        length: 6,
        offset: 0,
      },
    ],
    place_id: "ChIJ2w1BG638YjkR9EBiNdrEbgk",
    reference: "ChIJ2w1BG638YjkR9EBiNdrEbgk",
    structured_formatting: {
      main_text: "Indore",
      main_text_matched_substrings: [
        {
          length: 6,
          offset: 0,
        },
      ],
      secondary_text: "Madhya Pradesh, India",
    },
    terms: [
      {
        offset: 0,
        value: "Indore",
      },
      {
        offset: 8,
        value: "Madhya Pradesh",
      },
      {
        offset: 24,
        value: "India",
      },
    ],
    types: ["geocode", "political", "locality"],
  },
  {
    description: "Indore, WV, USA",
    matched_substrings: [
      {
        length: 6,
        offset: 0,
      },
    ],
    place_id: "ChIJfZvELoxKSYgRlCwRKEebfAg",
    reference: "ChIJfZvELoxKSYgRlCwRKEebfAg",
    structured_formatting: {
      main_text: "Indore",
      main_text_matched_substrings: [
        {
          length: 6,
          offset: 0,
        },
      ],
      secondary_text: "WV, USA",
    },
    terms: [
      {
        offset: 0,
        value: "Indore",
      },
      {
        offset: 8,
        value: "WV",
      },
      {
        offset: 12,
        value: "USA",
      },
    ],
    types: ["political", "locality", "geocode"],
  },
  {
    description: "Indore, Maharashtra, India",
    matched_substrings: [
      {
        length: 6,
        offset: 0,
      },
    ],
    place_id: "ChIJ-1CeSa-d3TsRg-M5oTWpBWs",
    reference: "ChIJ-1CeSa-d3TsRg-M5oTWpBWs",
    structured_formatting: {
      main_text: "Indore",
      main_text_matched_substrings: [
        {
          length: 6,
          offset: 0,
        },
      ],
      secondary_text: "Maharashtra, India",
    },
    terms: [
      {
        offset: 0,
        value: "Indore",
      },
      {
        offset: 8,
        value: "Maharashtra",
      },
      {
        offset: 21,
        value: "India",
      },
    ],
    types: ["geocode", "political", "locality"],
  },
  {
    description: "Indore, Gujarat, India",
    matched_substrings: [
      {
        length: 6,
        offset: 0,
      },
    ],
    place_id: "ChIJSWrJSk_wXzkR_ijNveHFxKw",
    reference: "ChIJSWrJSk_wXzkR_ijNveHFxKw",
    structured_formatting: {
      main_text: "Indore",
      main_text_matched_substrings: [
        {
          length: 6,
          offset: 0,
        },
      ],
      secondary_text: "Gujarat, India",
    },
    terms: [
      {
        offset: 0,
        value: "Indore",
      },
      {
        offset: 8,
        value: "Gujarat",
      },
      {
        offset: 17,
        value: "India",
      },
    ],
    types: ["political", "geocode", "locality"],
  },
  {
    description: "Indore, Uttar Pradesh, India",
    matched_substrings: [
      {
        length: 6,
        offset: 0,
      },
    ],
    place_id: "ChIJb0PJuBtikjkRH-1WMNP9S8g",
    reference: "ChIJb0PJuBtikjkRH-1WMNP9S8g",
    structured_formatting: {
      main_text: "Indore",
      main_text_matched_substrings: [
        {
          length: 6,
          offset: 0,
        },
      ],
      secondary_text: "Uttar Pradesh, India",
    },
    terms: [
      {
        offset: 0,
        value: "Indore",
      },
      {
        offset: 8,
        value: "Uttar Pradesh",
      },
      {
        offset: 23,
        value: "India",
      },
    ],
    types: ["locality", "political", "geocode"],
  },
];

export function getMockPredictions() {
  return Promise.resolve({
    predictions: shuffle(PREDICTIONS),
    status: "OK",
  }).then((res) => {
    return res.predictions;
  });
}
