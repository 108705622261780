import React from "react";

import { Package } from "@/services/UserService";
import Slider from "@/features/PaypipeId/Slider";
import PackageCard from "@/features/PaypipeId/PackageCard";

const Packages: React.FC<{
  packages: Package[];
  className?: string;
}> = ({ packages, className }) => {
  if (!packages.length) {
    return null;
  }

  return (
    <Slider className={className}>
      {packages.map((p, i) => (
        <PackageCard key={i} package={p} className="mb-3" />
      ))}
    </Slider>
  );
};

export default Packages;
