import React from "react";
import styled from "@emotion/styled";

import ShimmerLoader from "@/components/misc/ShimmerLoader";
import { squareSizing } from "@/utils/css";
import { screenLargerThan } from "@/styles";
import Avatar from "@/components/misc/Avatar";

//-----------------------------

const StyledContainer = styled.div`
  ${squareSizing("5.5rem")};
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10rem;
    object-fit: cover;
  }

  .tag {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: -0.25rem;
    border-radius: 0.3125rem;
    padding: 0.25rem 0.75rem;
    z-index: 1;
    min-height: 1.375rem;

    span {
      ${squareSizing("0.3125rem")};
      border-radius: 10rem;
    }
  }

  .status-tag {
    --color: #24723c;
    color: var(--color);
    font-size: 0.75rem;
    font-weight: 600;
    background: #d0f0d7;
    text-transform: uppercase;

    span {
      background: var(--color);
      z-index: 1;
    }
  }

  .loader-tag {
    min-width: 5rem;

    span {
      background: rgba(0, 0, 0, 0.1);
      margin-left: auto;
    }
  }

  ${screenLargerThan.widescreen} {
    --size: 12.5rem;
  }
`;

export const StyledAvatar = styled(Avatar)`
  border: 3px solid var(--clr-border-ukwn-12, #f3f3f3);
`;

//-----------------------------

type IUserProfileImageProps = {
  src?: string;
  status?: "not-available" | "available";
  className?: string;
  loading?: boolean;
  givenName?: string;
  familiyName?: string;
};

//-----------------------------

const UserProfileImage: React.FC<IUserProfileImageProps> = ({
  src,
  className,
  loading,
  status,
  familiyName,
  givenName,
}) => {
  if (loading) {
    return (
      <StyledContainer className={className}>
        <ShimmerLoader.Circular className="image" />

        <ShimmerLoader.Default className="tag loader-tag">
          <span />
        </ShimmerLoader.Default>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer className={className}>
      <StyledAvatar
        className="image"
        img={src || ""}
        firstName={givenName || ""}
        lastName={familiyName || ""}
      />

      {!!status && (
        <div className="tag status-tag">
          Available <span />
        </div>
      )}
    </StyledContainer>
  );
};

export default UserProfileImage;
