import { useCallback, useState } from "react";
import lodashGet from "lodash/get";

import { contractService } from "@/config/services";
import { useToast } from "@/components/misc/Toast";

import useContractDetails from "./useContractDetails";

function useReviewContractMilestone(params: {
  contractId: string;
  milestoneId: number;
}) {
  const { contractId } = params;
  const { createToast } = useToast();
  const { reload: reloadContractDetails } = useContractDetails({ contractId });

  const [isApproving, setIsApproving] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const approve = useCallback(() => {
    if (isApproving) {
      return Promise.reject();
    }

    setIsApproving(true);
    setIsApproved(false);

    return contractService
      .approveContractMilestone({ ...params })
      .then(() => {
        setIsApproved(true);
        createToast({
          title: "Payment released successfully.",
          variant: "success",
          timeoutInMilliseconds: 5000,
        });
      })
      .catch((err) => {
        setIsApproved(false);
        const message = lodashGet(err, "response.data.message");
        createToast({
          title: "Payment not released",
          description: message,
          variant: "error",
        });
      })
      .finally(() => {
        setIsApproving(false);
        reloadContractDetails();
      });
  }, [params, isApproving, reloadContractDetails, createToast]);

  return {
    approve,
    isApproved,
    isApproving,
  };
}

export default useReviewContractMilestone;
