import React from "react";
import styled from "@emotion/styled";

import Icon from "@/components/misc/Icon";
import { Specialization } from "@/services/UserService";
import { getExperienceDurationFormatted } from "@/services/UserService/UserService.utils";
import { squareSizing } from "@/utils/css";

//-----------------------------

const StyledContainer = styled.div`
  background: #fff;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .icon-container {
    ${squareSizing("3.375rem")};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    background: rgba(95, 87, 255, 0.03);
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
    word-break: break-word;
  }

  .duration {
    margin-top: auto;
    display: inline-block;
    font-size: 1.125rem;
    font-weight: 400;
    background: rgba(95, 87, 255, 0.03);
    border-radius: 10rem;
    padding: 0.75rem 1.5rem;
  }
`;

//-----------------------------

type IExperienceCardProps = {
  experience: Specialization;
  className?: string;
};

//-----------------------------

const ExperienceCard: React.FC<IExperienceCardProps> = ({
  experience,
  className,
}) => {
  const { name } = experience;
  const icon = "location.svg";

  return (
    <StyledContainer className={className}>
      <div className="icon-container">
        <Icon
          isSrcRelative
          customSize="2rem"
          colorVariant="primary"
          src={icon}
        />
      </div>

      <div className="title mt-3 mb-3">{name}</div>

      <div className="duration">
        {getExperienceDurationFormatted(experience)}
      </div>
    </StyledContainer>
  );
};

export default ExperienceCard;
