"use client";

import React from "react";

import { useContractList, useContractUrlId } from "@/services/ContractsService";
import MobilePrimaryLayout from "@/features/layouts/MobilePrimaryLayout";
import ChatRoomPage from "@/features/pages/chat/ChatRoomPage";
import { appFeatures } from "@/config/services";

import { StyledContainer } from "./ChatsPage.styles";
import NoChats from "./components/NoChats";
import ChatList from "./components/ChatList";
import SearchInput from "./components/SearchInput";

const ChatsPage: React.FC = () => {
  const { data: contractList } = useContractList();
  const { idFromUrl: contractIdFromUrl } = useContractUrlId();

  const hasNoData = !contractList.length;
  const chatListJsx = <ChatList />;
  const noChatJsx = <NoChats />;

  if (contractIdFromUrl) {
    return <ChatRoomPage />;
  }

  if (appFeatures.isSupported("CHAT.USE_STREAMIO")) {
    return (
      <MobilePrimaryLayout>
        <StyledContainer>{chatListJsx}</StyledContainer>
      </MobilePrimaryLayout>
    );
  }

  return (
    <MobilePrimaryLayout>
      <StyledContainer>
        <SearchInput />
        {hasNoData ? noChatJsx : chatListJsx}
      </StyledContainer>
    </MobilePrimaryLayout>
  );
};

export default ChatsPage;
