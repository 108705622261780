import React, { useMemo } from "react";

import Icon from "@/components/misc/Icon";
import { IButtonProps } from "@/components/input/Button";
import { usePathname } from "@/services/Routing";

import {
  StyledButton,
  StyledIconContainer,
  StyledLink,
  StyledNav,
  StyledTitle,
  StyledUl,
} from "./NavMenu.styles";
import { INavMenuProps } from "./NavMenu.types";

const NavMenu: React.FC<INavMenuProps> = ({
  items: itemsFromProps,
  className,
  varaint = "a",
  sidebarCompact,
}) => {
  const { pathname } = usePathname();

  const isVariantA = varaint === "a";
  const isVariantB = varaint === "b";

  const items = useMemo(() => {
    const items = [...itemsFromProps].map((item) => ({
      ...item,
      active: pathname === item.link,
    }));

    return items;
  }, [pathname, itemsFromProps]);

  return (
    <StyledNav className={className}>
      <StyledUl $varaint={varaint} $sidebarCompact={sidebarCompact}>
        {items.map((item) => {
          const {
            id,
            icon,
            title,
            link,
            onClick,
            iconProps = {},
            titleProps,
            active,
            render,
          } = item;

          const Component = link ? StyledLink : StyledButton;
          const props: any = link
            ? { to: link }
            : ({
                onClick,
                variant: "ghost",
                className: "px-0",
              } satisfies IButtonProps);

          const titleStyles = titleProps?.style || {};

          const iconSize = isVariantA
            ? undefined
            : isVariantB
            ? "0.625rem"
            : undefined;
          const iconJsx_ = (
            <Icon
              src="/assets/images/icons/chevron_right.svg"
              size="xxs"
              customSize={iconSize}
            />
          );

          const appendedIcon = isVariantA
            ? iconJsx_
            : isVariantB
            ? active && iconJsx_
            : null;

          const iconJsx = (
            <StyledIconContainer>
              <Icon
                size="sm"
                colorVariant={active ? "primary" : "gray"}
                src={icon}
                style={{
                  width: "1.125rem",
                }}
                {...iconProps}
              />
            </StyledIconContainer>
          );

          const titleJsx = (
            <StyledTitle style={titleStyles} $sidebarCompact={sidebarCompact}>
              {title}
            </StyledTitle>
          );

          const renderItemComponent = ({
            children = null,
          }: {
            children?: React.ReactNode;
          }) => (
            <Component
              {...props}
              $active={active}
              $varaint={varaint}
              $sidebarCompact={sidebarCompact}
            >
              {children}
            </Component>
          );

          return (
            <li key={id}>
              {render ? (
                render({
                  item,
                  iconJsx,
                  itemComponent: Component,
                  renderItemComponent,
                  titleJsx,
                  appendedIconJsx: appendedIcon,
                })
              ) : (
                <Component
                  {...props}
                  $active={active}
                  $varaint={varaint}
                  $sidebarCompact={sidebarCompact}
                >
                  {iconJsx}

                  {titleJsx}

                  {appendedIcon}
                </Component>
              )}
            </li>
          );
        })}
      </StyledUl>
    </StyledNav>
  );
};

export default NavMenu;
