"use client";

import React from "react";

import NoContent from "@/components/data/ImageTitleContent";

const NoChats: React.FC = () => {
  return (
    <NoContent
      image="/assets/images/pages/chats/no_messages.svg"
      title={<>You don&apos;t have any message</>}
      content="Start a chat with any of your contacts or others to have a conversation."
    />
  );
};

export default NoChats;
