import React, { useCallback, useEffect, useRef } from "react";

import { useAuth } from "@/services/Authentication";
import Button from "@/components/input/Button";
import { usePwa } from "@/services/Pwa";
import { SITE_PATHS } from "@/config/routing";
import { openLink } from "@/utils/files";
import { useSearchParams } from "@/services/Routing";

import {
  AuthType,
  IAuthenticationButtonProps,
} from "./AuthenticationButton.types";

const AuthenticationButton: React.FC<IAuthenticationButtonProps> = ({
  authType,
  children,
  ...restProps
}) => {
  const { login, register } = useAuth();
  const { isPwaInstalled } = usePwa();
  const { searchParams, setSearchParams } = useSearchParams();
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;
  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;

  const content =
    children ||
    (authType === "login"
      ? "Log in"
      : authType === "register"
      ? "Register"
      : "");

  const focusEventHandler = useCallback(() => {
    const triggeredPwaAuth = searchParamsRef.current.get("auth") === "pwa";
    const isPwaAuthRedirect =
      searchParamsRef.current.get("auth") === "pwa_redirect";

    if (document.hidden) {
      return;
    }

    if (triggeredPwaAuth) {
      searchParamsRef.current.set("auth", "pwa_redirect");
      setSearchParamsRef.current(searchParamsRef.current);
      return;
    }

    if (isPwaAuthRedirect) {
      searchParamsRef.current.delete("auth");
      setSearchParamsRef.current(searchParamsRef.current);
      window.location.reload();
    }
  }, []);

  const handleClick = useCallback(() => {
    function headlessAuthenticate(authType: AuthType) {
      const authLink =
        authType === "login"
          ? SITE_PATHS.HEADLESS_LOGIN_PAGE
          : authType === "register"
          ? SITE_PATHS.HEADLESS_REGISTER_PAGE
          : "";

      if (!authLink) {
        return;
      }

      const redirectLink = window.location.href;

      searchParams.set("auth", "pwa");
      setSearchParams(searchParams);
      window.blur();
      openLink(`${authLink}?redirect=${redirectLink}`);
    }

    if (isPwaInstalled) {
      headlessAuthenticate(authType);
    } else if (authType === "login") {
      login();
    } else if (authType === "register") {
      register();
    }
  }, [
    authType,
    login,
    register,
    isPwaInstalled,
    searchParams,
    setSearchParams,
  ]);

  useEffect(() => {
    window.addEventListener("visibilitychange", focusEventHandler);

    return () => {
      window.removeEventListener("visibilitychange", focusEventHandler);
    };
  }, [focusEventHandler]);

  return (
    <Button {...restProps} onClick={handleClick}>
      {content}
    </Button>
  );
};

export default AuthenticationButton;
