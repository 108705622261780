import { Column } from "@/components/data/Table";
import {
  ContractCompleteDetails,
  getContractCompletionDate,
  getContractPageUrl,
  getNextActiveMilestone,
} from "@/services/ContractsService";
import ContractStatus from "@/features/contracts/ContractStatus";
import { formatDate } from "@/utils/date";
import { roundTo } from "@/utils/number";

import { StyledCellLink, StyledName } from "./ContractTable.styles";
import ContractTypeDisplay from "./components/ContractType";
import ActionCell from "./components/ActionCell";

export const TABLE_COLUMNS: Column<ContractCompleteDetails>[] = [
  {
    id: "Name",
    header: "Name",
    accessor: "title",
    size: 320,
    cell: ({ renderValue, row }) => (
      <StyledName>
        <StyledCellLink to={getContractPageUrl(row.original.id)} />
        <b>{renderValue()}</b>
      </StyledName>
    ),
    verticalAlignment: "middle",
    enableSorting: true,
  },
  {
    id: "action",
    header: "Actions",
    accessor: "action",
    verticalAlignment: "middle",
    alignment: "center",
    cell: ({ row }) => <ActionCell contractDetails={row.original} />,
    enableSorting: true,
    minSize: 180,
  },
  {
    id: "status",
    header: "Status",
    accessor: "status",
    cell: ({ row }) => {
      const { milestone: nextActiveMilestone } = getNextActiveMilestone(row.original);
      return <ContractStatus status={row.original.status} milestoneStatus={nextActiveMilestone?.status} />
    },
    verticalAlignment: "middle",
    enableSorting: true,
    size: 170,
  },
  {
    id: "contract_type",
    header: "Job Type",
    accessor: "contract_type",
    verticalAlignment: "middle",
    cell: ({ row }) => (
      <ContractTypeDisplay contract_type={row.original.contract_type} />
    ),
    enableSorting: true,
  },
  {
    id: "total_value",
    header: "Amount",
    accessor: "total_value",
    verticalAlignment: "middle",
    cell: ({ getValue }) => <b>{`$${roundTo(+getValue(), 2)}`}</b>,
    enableSorting: true,
  },
  {
    id: "created_at",
    header: "Created",
    accessor: "created_at",
    cell: ({ getValue }) => formatDate(getValue(), "DD MMMM YY"),
    verticalAlignment: "middle",
    enableSorting: true,
  },
  {
    id: "submission_at",
    header: "Due/Completed",
    verticalAlignment: "middle",
    cell: ({ row }) => {
      const completionDate = getContractCompletionDate(row.original) || "";
      return completionDate ? formatDate(completionDate, "DD MMMM YY") : "";
    },
  },
];
