import React from "react";

import MobilePrimaryLayout from "@/features/layouts/MobilePrimaryLayout";

import { StyledPage, TopContainer } from "./HomePage.styles";
import PrimaryActionsSection from "./components/PrimaryActionsSection";
import BalanceSection from "./components/BalanceSection";
import ContractsOverviewCard from "./components/ContractsOverviewCard";
import RequiredActionsSection from "./components/RequiredActionsSection";
import RecentSection from "./components/RecentSection";

const HomePage: React.FC = () => {
  return (
    <MobilePrimaryLayout>
      <StyledPage className="no-scrollbar">
        <TopContainer>
          <BalanceSection />
          <ContractsOverviewCard />
        </TopContainer>

        <PrimaryActionsSection className="mt-3" />

        <RequiredActionsSection className="mt-3" />

        <RecentSection className="mt-3" />
      </StyledPage>
    </MobilePrimaryLayout>
  );
};

export default HomePage;
