import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useParamState } from "@/services/Routing";
import { AuthMedium, useAuth } from "@/services/Authentication";
import { ContractId, useContractDetails } from "@/services/ContractsService";
import { analytics, contractService } from "@/config/services";

type AuthData = {
  firstName: string;
  lastName: string;
  email: string;
  authMedium?: AuthMedium;
};

export function parseOnboardState(value: string) {
  try {
    const parsedState: AuthData = JSON.parse(atob(value));
    return parsedState;
  } catch {
    return null;
  }
}

export function checkIsSSOAuthMedium(authMedium: AuthMedium) {
  return (["google", "fb", "linkedin"] as AuthMedium[]).includes(authMedium);
}

function useAcceptOffer({ contractId }: { contractId: ContractId }) {
  const [onboardStateFromUrl] = useParamState("onboard_state", "");
  const [isAuthFlowFromUrl] = useParamState("auth_flow", false);
  const [loginFromUrl] = useParamState("login", false);
  const { isAuthenticated, register, userData, login } = useAuth();
  const onboardState = useMemo(() => {
    if (!onboardStateFromUrl) {
      return null;
    }

    return parseOnboardState(onboardStateFromUrl);
  }, [onboardStateFromUrl]);

  const onboardStateRef = useRef(onboardState);
  const isSSOAuthMedium = checkIsSSOAuthMedium(
    onboardStateRef.current?.authMedium as AuthMedium
  );
  const loggedInUserEmail = userData?.email || "";
  const isPostOnboardFlow =
    isAuthenticated &&
    isAuthFlowFromUrl &&
    (loginFromUrl ||
      isSSOAuthMedium ||
      onboardStateRef.current?.email === loggedInUserEmail);

  const [isLoadingPaymentUrl, setIsLoadingPaymentUrl] = useState(false);

  const { contractDetails, isLoading: isLoadingContractDetails } =
    useContractDetails({
      useAuthToken: false,
      contractId,
    });

  const triggeredAutoAcceptRef = useRef(false);

  //----------------------------------

  const handleAccept = useCallback(() => {
    setIsLoadingPaymentUrl(true);

    if (isLoadingPaymentUrl) {
      return true;
    }

    if (contractDetails) {
      analytics.triggerAccpetOfferEvent({
        contractId: contractDetails.id,
      });

      contractService
        .acceptContract(contractDetails, { email: loggedInUserEmail })
        .then(() => {
          setTimeout(() => {
            setIsLoadingPaymentUrl(false);
          }, 5000);
        })
        .catch(() => {
          setIsLoadingPaymentUrl(false);
        });
    }
  }, [contractDetails, isLoadingPaymentUrl, loggedInUserEmail]);

  const accept = useCallback(
    (
      data: {
        values?: AuthData;
        registerProps?: { medium: AuthMedium };
        login?: boolean;
      } = {}
    ) => {
      const { values, registerProps, login: useLogin } = data;

      if (isAuthenticated) {
        handleAccept();
        return;
      }

      const authMedium = registerProps?.medium;
      const isSSOAuthMedium = authMedium
        ? checkIsSSOAuthMedium(authMedium)
        : false;
      if (!values && !isSSOAuthMedium && !login) {
        return;
      }

      let email: string | undefined;
      if (values) {
        email = values?.email;
      }

      const encodedValues = btoa(JSON.stringify({ ...values, authMedium }));
      const url = new URL(window.location.href);
      url.searchParams.set("onboard_state", encodedValues);
      url.searchParams.set("auth_flow", "true");
      if (useLogin) {
        url.searchParams.set("login", "true");
      }

      const redirectUrl = url.href;
      if (useLogin) {
        login({ redirectUrl });
      } else {
        register({ email, redirectUrl, medium: registerProps?.medium });
      }
    },
    [register, isAuthenticated, handleAccept, login]
  );

  //----------------------------------

  useEffect(() => {
    if (triggeredAutoAcceptRef.current) {
      return;
    }

    if (isPostOnboardFlow) {
      handleAccept();
      triggeredAutoAcceptRef.current = true;
    }
  }, [isPostOnboardFlow, handleAccept]);

  //----------------------------------

  return {
    isPostOnboardFlow,
    accept,
    isLoading:
      isPostOnboardFlow || isLoadingContractDetails || isLoadingPaymentUrl,
    isLoadingPaymentUrl,
    isLoadingContractDetails,
  };
}

export default useAcceptOffer;
