import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StreamChat } from "stream-chat";
import lodashGet from "lodash/get";
import { ChannelList, Chat } from "stream-chat-react";

import { useAuth } from "@/services/Authentication";
import { getUserFullName, UserDetails } from "@/services/UserService";
import { userService } from "@/config/services";
import ChatCard, { ChatCardLoading } from "@/features/chat/ChatCard";

import {
  ChatServiceContext as ChatServiceContextType,
  StreamClient,
} from "./ChatService.types";
import { STREAM_API_KEY } from "./ChatService.config";

const ChatServiceContext = createContext({} as ChatServiceContextType);

export const useChatService = () => useContext(ChatServiceContext);

const loadingChatListJsx = (
  <>
    <ChatCardLoading />
    <hr />
    <ChatCardLoading />
    <hr />
    <ChatCardLoading />
  </>
);

export const ChatServiceProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isAuthenticated, loggedInUserId } = useAuth();
  const [streamClient, setStreamClient] = useState<StreamClient>(null);
  const idFromUrl = "";

  //-----------------------

  const channelListJsx = useMemo(() => {
    return true ? null : streamClient ? (
      <ChannelList
        key="test"
        showChannelSearch
        allowNewMessagesFromUnfilteredChannels
        recoveryThrottleIntervalMs={100000}
        sendChannelsToList
        filters={{ members: { $in: [`${loggedInUserId}`] } }}
        sort={{ last_message_at: -1 }}
        options={{ limit: 2 }}
        Preview={(item) => {
          const { displayTitle = "", lastMessage, channel, unread } = item;
          const contractId = (channel?.cid || "").split("messaging:CHAT_")[1];
          const lastMessageDate = lastMessage?.updated_at
            ? new Date(lastMessage.updated_at)
            : undefined;

          if (!contractId || !displayTitle) {
            return null;
          }

          const isActive = contractId === idFromUrl;

          return (
            <Fragment>
              <ChatCard
                active={isActive}
                title={displayTitle}
                content={lastMessage?.text || ""}
                contractId={contractId}
                lastMessageDate={lastMessageDate}
                unreadMessageCount={unread || 0}
                username=""
                avatarImage=""
              />
              <hr />
            </Fragment>
          );
        }}
        LoadingIndicator={() => loadingChatListJsx}
      />
    ) : (
      loadingChatListJsx
    );
  }, [loggedInUserId, idFromUrl, streamClient]);

  //-----------------------

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    let client: StreamClient = null;

    (async function () {
      const userRes = await userService
        .fetchUserInfo()
        ?.then((res) => lodashGet(res, "data.data"));
      const userData = lodashGet(userRes, "user") as unknown as UserDetails;
      const chatToken = lodashGet(userRes, "messaging.token", "");

      if (chatToken) {
        client = StreamChat.getInstance(STREAM_API_KEY);
        setStreamClient(client);

        await client.connectUser(
          {
            id: `${userData.id}`,
            name: getUserFullName(userData),
            image: userData.owner_profile,
          },
          chatToken
        );
      }
    })();
  }, [isAuthenticated]);

  //-----------------------

  if (!isAuthenticated) {
    return children;
  }

  return (
    <ChatServiceContext.Provider
      value={{
        isActive: !streamClient,
        streamClient: streamClient || null,
        channelListJsx,
      }}
    >
      {streamClient ? (
        <Chat client={streamClient} theme={`messaging`}>
          {children}
        </Chat>
      ) : (
        children
      )}
    </ChatServiceContext.Provider>
  );
};
