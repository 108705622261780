import React, { useMemo } from "react";

import { useAuth } from "@/services/Authentication";
import { OnboardStripeApiParams } from "@/services/UserService";

import CountryInput from "./components/CountryInput";
import {
  StyledContainer,
  StyledPrimaryContent,
  StyledSecondaryContent,
  StyledSmallContent,
} from "./PayoutOnboarding.styles";

const PayoutOnboarding: React.FC<{
  stripeOnboardParams?: Partial<OnboardStripeApiParams>;
}> = ({ stripeOnboardParams }) => {
  const { userData } = useAuth();

  const userName = useMemo(() => {
    if (!userData) return "";

    const userName = [userData?.given_name, userData?.family_name]
      .filter(Boolean)
      .join(" ");

    return userName;
  }, [userData]);

  return (
    <StyledContainer>
      <StyledPrimaryContent>
        Hi {userName}, we're excited for you to create your first offer!
      </StyledPrimaryContent>

      <StyledSecondaryContent size="lg" className="mt-4">
        To continue, we need get your payment info set up so we know where to
        send your earnings.
      </StyledSecondaryContent>

      <StyledSecondaryContent size="lg" className="mt-4">
        We'll move through this step quickly so you can start earning more
        today.
      </StyledSecondaryContent>

      <StyledSmallContent className="mt-5">
        Select your country from the list below to get started.
      </StyledSmallContent>

      <StyledSmallContent className="mt-3 color-primary-100">
        Note, if your country is on the list, it means we support payouts in
        your country and you can continue to onboard.
      </StyledSmallContent>

      <CountryInput
        className="mt-3"
        stripeOnboardParams={stripeOnboardParams}
      />
    </StyledContainer>
  );
};

export default PayoutOnboarding;
