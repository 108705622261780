import * as yup from "yup";

import { FormValues } from "./PackageForm.types";

export const MIN_ESTIMATE_VALUE = 1;
export const MAX_ESTIMATE_VALUE = 100;

export const FORM_ERRORS = {
  MAX_ESTIMATE_VALUE: "MAX_ESTIMATE_VALUE",
  MIN_ESTIMATE_VALUE: "MIN_ESTIMATE_VALUE",
};

export const DEFAULT_FORM_VALUES = {
  name: "",
  description: "",
  assets: [],
  estimated_duration: 0,
  estimated_duration_unit: "Days",
  price: 0,
  attachments: [],
} satisfies FormValues;

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  estimated_duration: yup
    .number()
    .min(MIN_ESTIMATE_VALUE, FORM_ERRORS.MIN_ESTIMATE_VALUE)
    .max(MAX_ESTIMATE_VALUE, FORM_ERRORS.MAX_ESTIMATE_VALUE)
    .required(),
  estimated_duration_unit: yup.string().required(),
  price: yup.number().min(1).required(),
  attachments: yup.array(),
});
