import Icon from "@/components/misc/Icon";
import { usePromptForSubscription } from "@/services/UserService";
import { Body } from "@/components/Typography";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { appFeatures } from "@/config/services";

import {
  StyledCard,
  StyledCloseButton,
  StyledContent,
  StyledButton,
  StyledTitle,
} from "./GetPremiumCard.styles";

const GetPremiumCard: React.FC<{ className?: string }> = ({ className }) => {
  const {
    discountContent,
    shouldShow,
    hidePrompt,
    loading,
    subscribe,
    isSubscribing,
  } = usePromptForSubscription();

  if (!appFeatures.isSupported("SUBSCRIPTION")) {
    return null;
  }

  if (!shouldShow || loading) {
    return null;
  }

  if (isSubscribing) {
    return <SplashScreen />;
  }

  return (
    <StyledCard
      className={className}
      onClick={() => {
        subscribe();
      }}
    >
      <StyledTitle size="md">
        <Icon src="/assets/images/branding/logo_round_gradient.svg" />

        <span className="capitalize">Upgrade to Premium</span>
      </StyledTitle>

      <StyledCloseButton
        variant="ghost"
        onClick={(e) => {
          e.stopPropagation();
          hidePrompt();
        }}
      >
        <Icon
          isSrcRelative
          src="cross.svg"
          customSize="0.65rem"
          colorVariant="white"
        />
      </StyledCloseButton>

      <Body size="sm" className="mt-3">
        Enjoy more features and less fees.
      </Body>

      {!!discountContent && (
        <StyledContent size="md" className="mt-3">
          {discountContent}
        </StyledContent>
      )}

      <StyledButton onClick={subscribe}></StyledButton>
    </StyledCard>
  );
};

export default GetPremiumCard;
