import React from "react";
import lodashGet from "lodash/get";

import { Label } from "@/components/Typography";

import { StyledContainer } from "./ContractStatus.styles";
import { IContractStatusProps } from "./ContractStatus.types";
import {
  STATUS_CONFIG,
  MILESTONE_STATUS_CONFIG,
} from "./ContractStatus.config";

const ContractStatus: React.FC<IContractStatusProps> = ({
  status,
  milestoneStatus,
  className,
}) => {
  const statusName: any =
    lodashGet(MILESTONE_STATUS_CONFIG, `[${milestoneStatus}].label`, "") ||
    lodashGet(STATUS_CONFIG, `[${status}].label`, "");

  return (
    <StyledContainer
      $status={status}
      $milestoneStatus={milestoneStatus}
      className={className}
    >
      <Label size="sm">{statusName}</Label>
    </StyledContainer>
  );
};

export default ContractStatus;
