"use client";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { KindeProvider, useKindeAuth } from "@kinde-oss/kinde-auth-react";

import OnboardingPage from "@/features/pages/app/OnboardingPage";
import {
  useNavigate,
  useParamState,
  usePathname,
  useSearchParams,
} from "@/services/Routing";
import {
  api,
  appFeatures,
  contractService,
  pushNotifications,
  userService,
} from "@/config/services";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { selectUserData, useAppSelector } from "@/services/Store";
import { SITE_PATHS } from "@/config/routing";
import { useContractUrlId } from "@/services/ContractsService";
import Modal, { useModalState } from "@/components/misc/Modal";
import LogoutConfirmation from "@/features/app/LogoutConfirmation";
import { useToast } from "@/components/misc/Toast";
import Button from "@/components/input/Button";
import { usePwa } from "@/services/Pwa";
import { useResponsive } from "@/styles";

import {
  AuthAppState,
  IAuthContext,
  IAuthProviderProps,
} from "./Authentication.types";
import { CONFIG, PAYPIPE_ID_SEARCH_PARAM_NAME } from "./Authentication.config";
import { usePushNotificationPermission } from "../PushNotifications";
// import { AUTH_TOKEN } from "./Authentication.temp";

const {
  KINDE_CALLBACK_URL,
  KINDE_CLIENT_ID,
  KINDE_CONNECTION_ID,
  KINDE_DOMAIN,
  KINDE_LOGOUT_URL,
} = CONFIG;

const AuthContext = createContext({} as IAuthContext);

export const useAuth = () => useContext(AuthContext);

const AuthProviderInner: React.FC<IAuthProviderProps> = ({ children }) => {
  const { pathname } = usePathname();
  const { createToast, closeToast } = useToast();
  const { canInstall: canInstallPwa, triggerInstall: triggerPwaInstall } =
    usePwa();
  const {
    isEnabled: isNotificationsEnabled,
    prompt: promptEnableNotification,
  } = usePushNotificationPermission();
  const userData = useAppSelector(selectUserData);
  const {
    isAuthenticated: isAuthenticatedFromKinde,
    login,
    register,
    logout,
    getToken,
    isLoading,
    user,
  } = useKindeAuth();
  const { isScreenSmallerThanTablet } = useResponsive();
  const { searchParams } = useSearchParams();

  const logoutConfirmationModalState = useModalState();

  const updatedTokenRef = useRef(false);
  const [token, setToken] = useState("");
  const isAuthenticated = !!token;
  const isAuthenticating = isLoading || (isAuthenticatedFromKinde && !userData);

  const [paypipeIdFromUrl, setPaypipeIdFromUrl] = useParamState<string>(
    PAYPIPE_ID_SEARCH_PARAM_NAME,
    "",
    {
      parseJson: false,
    }
  );

  const { idFromUrl: contractIdFromUrl } = useContractUrlId();
  const isOnNonAuthRoute = useMemo(
    () =>
      ([
        // SITE_PATHS.CONTRACTS_PAGE,
        SITE_PATHS.CONTRACTS_ONBOARD_PAGE,
        SITE_PATHS.OFFERS_PAGE,
        SITE_PATHS.CONTRACT_REJECT_PAGE,
        SITE_PATHS.CLAIM_ID_PAGE,
      ].includes(pathname) &&
        !!contractIdFromUrl) ||
      [
        SITE_PATHS.HEADLESS_REGISTER_PAGE,
        SITE_PATHS.HEADLESS_LOGIN_PAGE,
        SITE_PATHS.TERMS_CONDITIONS_PAGE,
        SITE_PATHS.POLICY_PAGE,
        SITE_PATHS.CLAIM_ID_PAGE,
      ].includes(pathname) ||
      [SITE_PATHS.PAYPIPE_ID_PAGE].some((path) => pathname.includes(path)),
    [pathname, contractIdFromUrl]
  );

  const isOnHomePage = pathname === SITE_PATHS.HOME_PAGE;

  const hasAuthenticatedBefore = useMemo(() => {
    try {
      return JSON.parse(
        localStorage.getItem("PAYPIPE:HAS_AUTHENTICATED") || "false"
      );
    } catch {
      return false;
    }
  }, []);

  //-------------------------

  const getAuthAppState = useCallback(
    ({ redirectUrl, email }: Partial<AuthAppState> = {}) => {
      if (!redirectUrl) {
        redirectUrl = `${window.location.pathname}${window.location.search}`;
      }
      const state = { redirectUrl, email } satisfies AuthAppState;
      return state;
    },
    []
  );

  const handleLogin = useCallback(
    (params: Partial<AuthAppState> = {}) => {
      const args: any = {
        app_state: getAuthAppState(params),
      };

      if (params?.email) {
        args.authUrlParams = {
          connection_id: KINDE_CONNECTION_ID,
          login_hint: params?.email || "",
        };
      }

      return login(args);
    },
    [login, getAuthAppState]
  );

  const handleRegister = useCallback(
    (params: Partial<AuthAppState> = {}) => {
      const args: any = {
        app_state: getAuthAppState(params),
      };

      if (params?.email) {
        args.authUrlParams = {
          connection_id: KINDE_CONNECTION_ID,
          login_hint: params?.email || "",
        };
      }

      if (!!params?.medium) {
        if (!args.authUrlParams) {
          args.authUrlParams = {};
        }

        if (params.medium === "google") {
          args.authUrlParams.connection_id = CONFIG.KINDE_GOOGLE_CONNECTION_ID;
        } else if (params.medium === "fb") {
          args.authUrlParams.connection_id = CONFIG.KINDE_FB_CONNECTION_ID;
        } else if (params.medium === "linkedin") {
          args.authUrlParams.connection_id =
            CONFIG.KINDE_LINKEDIN_CONNECTION_ID;
        }
      }

      return register(args);
    },
    [register, getAuthAppState]
  );

  const handleLogout = useCallback(() => {
    userService.cleanup();
    contractService.cleanup();

    return logout();
  }, [logout]);

  const onBoardUserFromStripeSessionId = useCallback(
    (sessionId: string) => {
      contractService.getStripeSessionFromSessionId(sessionId).then((res) => {
        const email = res.customer_details?.email || "";

        register({
          app_state: getAuthAppState(),
          authUrlParams: {
            connection_id: KINDE_CONNECTION_ID,
            login_hint: email,
          },
        });
      });
    },
    [register, getAuthAppState]
  );

  //-------------------------

  const onboardStateFromUrl = searchParams.get("onboard_state");
  const onboardState = useMemo(() => {
    if (!onboardStateFromUrl) {
      return null;
    }

    try {
      const parsedState: {
        firstName: string;
        lastName: string;
        email: string;
      } = JSON.parse(atob(onboardStateFromUrl));
      return parsedState;
    } catch {
      return null;
    }
  }, [onboardStateFromUrl]);

  useEffect(() => {
    let cancel = false;

    if (isAuthenticatedFromKinde) {
      if (updatedTokenRef.current || !!token) return;

      if (!cancel) {
        setToken("");
      }

      updatedTokenRef.current = true;

      getToken().then(async (token) => {
        if (token && user) {
          const regsiterData: any = {
            ...user,
            email: user.email || "",
            given_name: user.given_name || "",
            family_name: user.family_name || "",
            owner_id: user.id || "",
            paypipe_id: paypipeIdFromUrl || "",
          };

          if (onboardState) {
            if (onboardState.firstName) {
              regsiterData.given_name = onboardState.firstName;
            }

            if (onboardState.lastName) {
              regsiterData.family_name = onboardState.lastName;
            }
          }

          delete regsiterData.id;

          api.setAuthToken(token);
          if (!cancel) {
            setToken(token);
          }

          await userService
            .checkWhetherUserExists({ email: user.email || "" })
            .then((res) => {
              const exist = res.data.data.exist;

              if (exist) {
                userService
                  .fetchUserInfo()
                  ?.then(() => {
                    if (
                      appFeatures.isSupported("NOTIFICATION.PUSH_NOTIFICATIONS")
                    ) {
                      pushNotifications.init();
                    }
                  })
                  .finally(() => {
                    setPaypipeIdFromUrl();
                  });
              } else {
                userService
                  .registerUser(regsiterData)
                  .then(() => {
                    userService.fetchUserInfo()?.then(() => {
                      if (
                        appFeatures.isSupported(
                          "NOTIFICATION.PUSH_NOTIFICATIONS"
                        )
                      ) {
                        pushNotifications.init();
                      }
                    });
                  })
                  .finally(() => {
                    setPaypipeIdFromUrl();
                  });
              }
            });
        }
      });
    } else {
      updatedTokenRef.current = false;
    }

    return () => {
      cancel = true;
    };
  }, [
    isAuthenticatedFromKinde,
    getToken,
    isLoading,
    user,
    token,
    onboardState,
    paypipeIdFromUrl,
    setPaypipeIdFromUrl,
  ]);

  const toastsCreated = useRef(false);
  useEffect(() => {
    if (isAuthenticated) {
      toastsCreated.current = true;

      if (isOnHomePage && !toastsCreated.current) {
        let createdInstallToast = false;

        if (canInstallPwa) {
          const toastId = createToast({
            title: "Install app",
            timeoutInMilliseconds: 6000,
            description: (
              <>
                <div>
                  Get the most out of Paypipe and stay on top of your jobs on
                  the go.
                </div>

                <Button
                  className="mt-3 w-100"
                  onClick={() => {
                    triggerPwaInstall();
                    closeToast(toastId);
                  }}
                >
                  Install
                </Button>
              </>
            ),
          });
          createdInstallToast = true;
        }

        (async function () {
          const isSupported = await pushNotifications.isSupported();

          if (
            appFeatures.isSupported("NOTIFICATION.PUSH_NOTIFICATIONS") &&
            isSupported &&
            (createdInstallToast ? !isScreenSmallerThanTablet : true)
          ) {
            if (!isNotificationsEnabled) {
              const toastId = createToast({
                title: "Enable notifications",
                timeoutInMilliseconds: 8000,
                description: (
                  <>
                    <div>
                      Get the most out of Paypipe and stay on top of your jobs
                      on the go.
                    </div>

                    <Button
                      className="mt-3 w-100"
                      onClick={() => {
                        promptEnableNotification();
                        closeToast(toastId);
                      }}
                    >
                      Enable
                    </Button>
                  </>
                ),
              });
            }
          }
        })();
      }
    }
  }, [
    isAuthenticated,
    createToast,
    canInstallPwa,
    triggerPwaInstall,
    closeToast,
    isNotificationsEnabled,
    promptEnableNotification,
    isOnHomePage,
    isScreenSmallerThanTablet,
  ]);

  useEffect(() => {
    if (!!token) {
      localStorage.setItem("PAYPIPE:HAS_AUTHENTICATED", JSON.stringify(true));
    }
  }, [token]);

  //-------------------------

  return (
    <AuthContext.Provider
      value={{
        login: handleLogin,
        register: handleRegister,
        logout: handleLogout,
        isAuthenticated,
        userData,
        isAuthenticating,
        onBoardUserFromStripeSessionId,
        loggedInUserId: userData?.id === undefined ? -1 : userData?.id,
        logoutWithConfirmation: logoutConfirmationModalState.open,
        token,
        hasAuthenticatedBefore,
      }}
    >
      <Modal state={logoutConfirmationModalState} width="336px">
        <LogoutConfirmation
          onCancel={logoutConfirmationModalState.close}
          onLogout={() => {
            handleLogout();
            logoutConfirmationModalState.close();
          }}
        />
      </Modal>

      {isAuthenticating ? (
        <SplashScreen />
      ) : isOnNonAuthRoute || isAuthenticated ? (
        children
      ) : (
        <OnboardingPage />
      )}
    </AuthContext.Provider>
  );
};

export const AuthProvider: React.FC<IAuthProviderProps> = (props) => {
  const { navigate } = useNavigate();

  const redirectCallback = useCallback(
    (_: any, state?: Object) => {
      if (!state) return;

      const { redirectUrl } = state as AuthAppState;

      if (redirectUrl) {
        navigate(redirectUrl, true);
      } else {
        navigate(SITE_PATHS.HOME_PAGE, true);
      }
    },
    [navigate]
  );

  return (
    <KindeProvider
      isDangerouslyUseLocalStorage
      clientId={KINDE_CLIENT_ID}
      domain={KINDE_DOMAIN}
      logoutUri={KINDE_LOGOUT_URL}
      redirectUri={KINDE_CALLBACK_URL}
      scope="offline openid profile email"
      onRedirectCallback={redirectCallback}
    >
      <AuthProviderInner {...props} />
    </KindeProvider>
  );
};
