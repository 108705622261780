"use client";
import React, { useMemo } from "react";

import { useUserActions } from "@/services/UserService";
import { ActionCardLoading } from "@/components/misc/ActionCard";
import {
  filterOutBrokenContracts,
  useContractList,
} from "@/services/ContractsService";
import ContractCard from "@/features/contracts/ContractCard";
import ActionCard from "@/components/misc/ActionCard";

import { StyledCardsContainer } from "./RequiredActionsList.styles";

const RequiredActionsList: React.FC = () => {
  const { actions: actionsFromHook, isLoading: isLoadingActions } = useUserActions();
  const { data: contractListFromHook, isLoading: isLoadingContractList } =
    useContractList({
      onlyJobs: true,
      onlyActiveJobs: true,
      onlyWithActions: true,
    });

  const contractlist = useMemo(
    () => filterOutBrokenContracts(contractListFromHook).slice(0, 3),
    [contractListFromHook]
  );

  const actions = useMemo(
    () =>
      contractlist.length >= 3
        ? []
        : actionsFromHook.splice(0, 3 - contractlist.length),
    [actionsFromHook, contractlist.length]
  );

  if (isLoadingActions || isLoadingContractList) {
    return (
      <>
        <ActionCardLoading className="mt-3" />
        <ActionCardLoading className="mt-3" />
      </>
    );
  }

  return (
    <StyledCardsContainer>
      {contractlist.map((contract) => (
        <ContractCard
          showCta
          key={contract.id}
          contract={contract}
          className="mt-3"
        />
      ))}

      {actions.map((item, i) => (
        <ActionCard key={i} {...item} className="mt-3" />
      ))}
    </StyledCardsContainer>
  );
};

export default RequiredActionsList;
