import { api } from "@/config/services";

import { GOOGLE_API_KEY, GOOGLE_API_URL } from "./Location.config";
import { PlaceDetailsResponse, SuggessionsApiResponse } from "./Location.types";
import { getMockPredictions } from "./mock";

class Location {
  getSuggessions = (query: string) => {
    if (true) {
      return getMockPredictions();
    }

    return api
      .anonGet<SuggessionsApiResponse>(
        `/place/autocomplete/json?input=${query}&key=${GOOGLE_API_KEY}&types=geocode`,
        {
          baseURL: GOOGLE_API_URL,
        }
      )
      .then((res) => {
        return res.data.predictions;
      });
  };

  getPlaceDetailsByID = (query: string) => {
    return api.anonGet<PlaceDetailsResponse>(
      `/place/details/json?place_id=${query}&key=${GOOGLE_API_KEY}`,
      {
        baseURL: GOOGLE_API_URL,
      }
    );
  };
}

export default Location;
