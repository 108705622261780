import React from "react";
import styled from "@emotion/styled";

import Icon from "@/components/misc/Icon";
import { SkillDetails } from "@/services/UserService";
import { squareSizing } from "@/utils/css";

//-----------------------------

const StyledContainer = styled.div`
  background: #fff;
  border-radius: 0.625rem;
  padding: 1rem;

  .icon-container {
    ${squareSizing("3.375rem")};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    background: #000;
  }

  .content {
    display: flex;
    align-items: baseline;
    gap: 1rem;
  }

  .title {
    position: relative;
    top: -0.25rem;
    display: inline-block;
    flex-grow: 1;
    font-size: 2rem;
    font-weight: 400;
    word-break: break-word;
  }

  .rating-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--clr-primary-100, #5f57ff);
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1em;
  }

  .rating {
    --progress: 0;
    position: relative;
    width: 100%;
    border: none;
    background: #eeeeee;
    border-radius: 10rem;
    overflow: hidden;
    height: 0.625rem;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: var(--progress);
      height: 100%;
      background-color: var(--clr-primary-100, #5f57ff);
    }
  }
`;

//-----------------------------

type ISkllCardProps = {
  skill: SkillDetails;
  className?: string;
};

//-----------------------------

const SkllCard: React.FC<ISkllCardProps> = ({ skill, className }) => {
  const { name, rating } = skill;
  const icon = "location.svg";

  return (
    <StyledContainer className={className}>
      <div className="content">
        <div className="icon-container">
          <Icon
            isSrcRelative
            customSize="2rem"
            colorVariant="white"
            src={icon}
          />
        </div>
        <div className="title">{name}</div>
      </div>

      <div className="rating-container mt-3">
        <div
          className="rating"
          style={{ "--progress": `${rating}%` } as React.CSSProperties}
        />
        <span>{rating}%</span>
      </div>
    </StyledContainer>
  );
};

export default SkllCard;
