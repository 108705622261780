"use client";

import { overflowEllipsis } from "@/styles";
import { squareSizing } from "@/utils/css";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledUsername = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  color: var(--clr-text-ukwn-3, #1e75ff);

  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  color: var(--clr-text-ukwn-4, #242b42);
  margin-top: 0.15rem;
`;

export const StyledContent = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  color: var(--clr-icon-text-200, #818898);
  ${overflowEllipsis()};
`;

export const StyledTopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const StyledBottomContainer = styled(StyledTopContainer)`
  margin-top: 0.5rem;
  align-items: center;
  overflow: hidden;
`;

export const StyledLeftSection = styled.div`
  min-width: 32px;
  display: inline-flex;
  justify-content: center;
`;

export const StyledRightSection = styled.div`
  flex: 1;
  position: relative;
  padding-right: 2rem;
  overflow: hidden;
`;

export const StyledMessageCount = styled.div`
  ${squareSizing("1.35rem")}
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--clr-primary-100, #5f57ff);
  color:  var(--clr-background-primary, #fff)  !important;
  font-size: 0.825rem;
  font-weight: 600;
  border-radius: 10rem;
`;

export const StyledDate = styled.div`
  position: absolute;
  right: 0;
  top: -0.15rem;
  font-size: 12px;
  font-weight: 400;
  color: var(--clr-text-ukwn-5, #7e8ca0);
`;

export const StyledCard = styled.div<{ $active: boolean }>`
  position: relative;
  padding: 1rem;
  border-radius: 1.25rem;

  ${({ $active }) =>
    $active
      ? `
        background: var(--clr-primary-100, #5F57FF);

        div , p {
            color: var(--clr-text-white, #fff);
        }

        .avatar {
            border: 1px solid #fff;
        }

        .icon {
          filter: brightness(0) invert(1);
        }
    `
      : `
        &:hover {
          background: var(--clr-background-25, #f6f8fa);
        }
      `}
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: 1;
`;
