import React, { useCallback, useState } from "react";
import lodashGet from "lodash/get";
import lodashSet from "lodash/set";

import Button from "@/components/input/Button";
import {
  useSubscriptionDetails,
  useUpdateProfile,
} from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import DetailsTable from "@/components/data/DetailsTable";
import { formatDate } from "@/utils/date";

const Subscription: React.FC = () => {
  const { subscriptionDetails, loadingSubscriptionDetails, refresh } =
    useSubscriptionDetails();

  const { profileData, isProfileDataLoading, update } = useUpdateProfile();

  const [refreshing, setRefreshing] = useState(false);
  const availedSignupSubscriptionDiscount = lodashGet(
    profileData,
    "misc.subscriptions.availedSignupSubscriptionBonus",
    false
  );

  //----------------------------------------------

  const resetCouponState = useCallback(() => {
    if (refreshing || isProfileDataLoading) {
      return;
    }

    const updatedProfileData = JSON.parse(JSON.stringify(profileData));
    lodashSet(
      updatedProfileData,
      "misc.subscriptions.availedSignupSubscriptionBonus",
      false
    );

    setRefreshing(true);
    return update(updatedProfileData, { notify: false }).finally(() => {
      setRefreshing(false);
    });
  }, [profileData, update, refreshing, isProfileDataLoading]);

  //----------------------------------------------

  if (loadingSubscriptionDetails || refreshing) {
    return <SplashScreen />;
  }

  if (!subscriptionDetails) {
    return (
      <div>
        <h1>Found no subscription</h1>
        <Button className="w-100 mt-5" onClick={refresh}>
          Refresh details
        </Button>
      </div>
    );
  }

  const { subscriptionPlanDuration, subscriptionEndDate, isTrial, status } =
    subscriptionDetails;

  return (
    <div className="pt-5 container">
      <h1>Has premium</h1>
      <DetailsTable
        className="mt-3"
        items={[
          {
            label: "Duration plan type",
            value: subscriptionPlanDuration,
          },
          {
            label: "Start date",
            value: (
              <>
                {subscriptionEndDate
                  ? formatDate(subscriptionEndDate, "DD MMM YYYY, hh:mm")
                  : "-"}
              </>
            ),
          },
          {
            label: "status",
            value: status,
          },
          {
            label: "Is trial?",
            value: isTrial ? "Yes" : "No",
          },
          {
            label: "Availed first subscription purchase coupon?",
            value: (
              <>
                {availedSignupSubscriptionDiscount ? "Yes" : "No"}{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={resetCouponState}>
                  Reset ?
                </Button>
              </>
            ),
          },
        ]}
      />

      <Button className="w-100 mt-5" onClick={refresh}>
        Refresh details
      </Button>
    </div>
  );
};

export default Subscription;
