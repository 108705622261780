"use client";

import styled from "@emotion/styled";

export const StyledCardsContainer = styled.div`
  & > * {
    border-radius: 0 !important;
    border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9) !important;
    padding-inline: 0 !important;
  }
`;
