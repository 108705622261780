import React, { useCallback, useMemo } from "react";

import { IButtonProps } from "@/components/input/Button";
import { joinClassNames } from "@/utils/classNames";

import { StyledButton } from "../../ContractForm.styles";
import { useContractForm } from "../../ContractForm.context";
import { STEPS_CONFIG } from "../../ContractForm.config";

const StepContinueButton: React.FC<IButtonProps> = (props) => {
  const {
    currentStep,
    checkIsStepCompleted,
    nextStep,
    isLastStep,
    submitForm,
    creatingContract,
    setFieldTouched,
    values,
  } = useContractForm();

  const isCurrentStepCompleted = useMemo(
    () => checkIsStepCompleted(currentStep),
    [currentStep, checkIsStepCompleted]
  );

  const handleClick = useCallback(() => {
    const validateStep = STEPS_CONFIG[currentStep]?.validateStep;

    if (validateStep) {
      validateStep({ setFieldTouched, values });
    }

    if (!isCurrentStepCompleted) {
      return;
    }

    if (isLastStep) {
      submitForm();
    } else {
      nextStep();
    }
  }, [
    nextStep,
    isLastStep,
    submitForm,
    isCurrentStepCompleted,
    setFieldTouched,
    values,
    currentStep,
  ]);

  return (
    <StyledButton
      {...props}
      className={joinClassNames("w-100", props.className)}
      onClick={handleClick}
      colorVariant={isCurrentStepCompleted ? "primary" : "disabled"}
      disabled={creatingContract}
    >
      {creatingContract ? "Creating" : props.children || "Continue"}
    </StyledButton>
  );
};

export default StepContinueButton;
