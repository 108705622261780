import * as yup from "yup";
import isString from "lodash/isString";

import Icon from "@/components/misc/Icon";
import {
  ContractCreatorType,
  ContractCurrency,
  ContractTransactionType,
  ContractType,
  ContractMilestone,
  MIN_MILESTONE_VALUE,
  MAX_MILESTONE_VALUE,
} from "@/services/ContractsService";
import { FormErrors } from "@/components/input/Form";

import {
  ClientInputType,
  ContractFormValues,
  Step,
} from "./ContractForm.types";
import ContractCreatorTypeStep from "./components/steps/ContractCreatorTypeStep";
import ContractTypeStep from "./components/steps/ContractTypeStep";
import ContractDetailsStep from "./components/steps/ContractDetailsStep";
import ReviewStep from "./components/steps/ReviewStep";
import PayoutOnboardingStep from "./components/steps/PayoutOnboardingStep";

const USE_DUMMY_INITIAL_VALUES = false;
export const DATES_OPTIONAL = true;
export const CLIENT_NAME_OPTIONAL = true;

export const STEP_URL_PARAM_NAME = "step";

export const CLIENT_NAME_CHAR_LIMIT = 100;

export const ERRORS = {
  MIN_PRICE_ERROR: "MIN_PRICE_ERROR",
  MAX_PRICE_ERROR: "MAX_PRICE_ERROR",
};

export const VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  transaction_type: yup
    .string()
    .required()
    .oneOf(Object.values(ContractTransactionType)),
  contract_type: yup.string().required().oneOf(Object.values(ContractType)),
  created_as: yup.string().required().oneOf(Object.values(ContractCreatorType)),
  // client_email_number: yup.string().required(),
  client_name: CLIENT_NAME_OPTIONAL ? yup.string() : yup.string().required(),

  milestones: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        description: yup.string().required(),
        // currency: yup.string().required(),
        value: yup
          .number()
          .required()
          .min(MIN_MILESTONE_VALUE, ERRORS.MIN_PRICE_ERROR)
          .max(MAX_MILESTONE_VALUE, ERRORS.MAX_PRICE_ERROR),

        ...(DATES_OPTIONAL
          ? {}
          : {
              start_date: yup.string().required(),
              end_date: yup.string().required(),
            }),
      })
    )
    .min(1),
});

export const DUMMY_INITIAL_VALUES: ContractFormValues = {
  title: "Enlarz - Design system",
  description:
    "Deserunt aute qui elit ullamco labore irure. Proident ullamco veniam Lorem consequat aliquip. Deserunt nulla reprehenderit consectetur eu exercitation sunt magna eu. Consectetur nostrud enim nisi consequat consectetur nostrud. Non ut dolor fugiat exercitation aliqua enim excepteur veniam exercitation reprehenderit.",
  start_date: null,
  end_date: null,
  // contract_type: "" as ContractType.Milestone,
  // transaction_type: "" as ContractTransactionType.FullPayment,
  created_as: ContractCreatorType.Payee,
  contract_type: ContractType.Milestone,
  transaction_type: ContractTransactionType.FullPayment,
  milestones: [
    {
      name: "Component library",
      description:
        "Aliqua aliquip cupidatat pariatur non. Cillum veniam officia consequat dolor eiusmod nostrud exercitation magna. Aute velit aliquip reprehenderit proident deserunt aliquip ea. Veniam Lorem amet Lorem pariatur deserunt sit minim consectetur amet magna consectetur. Fugiat laboris ullamco anim sint Lorem duis.",
      start_date: new Date(2024, 0, 19).toISOString(),
      end_date: new Date(2024, 0, 24).toISOString(),
      currency: ContractCurrency.USD,
      value: 800,
    } as ContractMilestone,
    {
      name: "Atomic library",
      description:
        "Irure consequat veniam id aliqua id anim nostrud enim non sint. Excepteur cillum aliqua aliqua dolore dolor reprehenderit. Consequat eu voluptate laboris minim nostrud ipsum ipsum voluptate ex eu quis. Proident nisi anim amet id adipisicing cillum. Aliquip eiusmod minim ad reprehenderit ut labore irure aliqua nisi.",
      start_date: new Date(2024, 0, 19).toISOString(),
      end_date: new Date(2024, 0, 24).toISOString(),
      currency: ContractCurrency.USD,
      value: 400,
    } satisfies ContractMilestone,
  ],
  attachments: [],
  client_email_number: "dewashishbhawsar99@gmail.com",
  client_name: "Dev b",
  files: [],
} as unknown as ContractFormValues;

export const INITIAL_VALUES: ContractFormValues = USE_DUMMY_INITIAL_VALUES
  ? DUMMY_INITIAL_VALUES
  : {
      description: "",
      title: "",
      start_date: "",
      end_date: "",
      transaction_type: ContractTransactionType.FullPayment,
      contract_type: "" as ContractType.OneTime,
      created_as: "" as ContractCreatorType.Payee,
      milestones: [
        // {
        //   name: "",
        //   description: "",
        //   start_date: null,
        //   end_date: null,
        //   currency: ContractCurrency.USD,
        //   value: 0,
        // } as ContractMilestone,
      ],
      attachments: [],
      client_email_number: "",
      client_name: "",
      files: [],
    };

export const CONTRACT_TYPE_OPTIONS = [
  {
    value: ContractType.OneTime,
    children: (
      <>
        <Icon src="/assets/images/icons/money.svg" size="xs" /> One time
      </>
    ),
  },
  {
    value: ContractType.Milestone,
    children: (
      <>
        <Icon src="/assets/images/icons/milestone.svg" size="sm" />
        Milestone
      </>
    ),
  },
];

export const CLIENT_INPUT_OPTIONS = [
  {
    value: ClientInputType.PaypipeId,
    children: "Paypipe ID",
  },
  {
    value: ClientInputType.Email,
    children: "Email",
  },
];

export const STEPS_CONFIG: Partial<
  Record<
    Step,
    {
      urlId: string;
      order: number;
      component: any;
      title: string;
      isStepCompleted?: (data: {
        values: ContractFormValues;
        errors: FormErrors<ContractFormValues>;
      }) => boolean;
      validateStep?: (data: {
        values: ContractFormValues;
        setFieldTouched: (
          field: string,
          touched?: boolean | undefined
        ) => Promise<any>;
      }) => void;
    }
  >
> = {
  [Step.ContractCreator]: {
    urlId: "created-as",
    order: 1,
    component: <ContractCreatorTypeStep />,
    title: "",
    isStepCompleted: ({ values }) => {
      return !!values.created_as;
    },
  },
  [Step.PayoutOnboarding]: {
    urlId: "onboarding",
    order: 2,
    component: <PayoutOnboardingStep />,
    title: "Create an offer",
  },
  [Step.ContractType]: {
    urlId: "type",
    order: 3,
    component: <ContractTypeStep />,
    title: "",
    isStepCompleted: ({ values }) => {
      return !!values.contract_type;
    },
  },
  [Step.ContractDetails]: {
    urlId: "details",
    order: 4,
    component: <ContractDetailsStep />,
    title: "Create an offer",
    isStepCompleted: ({ errors, values }) => {
      let milestonesErrors = (errors.milestones ||
        []) as FormErrors<ContractMilestone>[];
      if (values.contract_type === ContractType.OneTime) {
        milestonesErrors = [milestonesErrors[0]];
      }

      if (isString(milestonesErrors)) {
        return false;
      }

      const milestonesHasError = milestonesErrors.some((milestoneErrors, i) => {
        const { name, description } = values.milestones[i] || {};
        let { value } = values.milestones[i] || {};
        const nameHasError = !name;
        const descriptionHasError = !description;

        value = value || 0;
        const valueHasError =
          value < MIN_MILESTONE_VALUE || value > MAX_MILESTONE_VALUE;

        const datesHasError = DATES_OPTIONAL
          ? false
          : !!milestoneErrors?.start_date || !!milestoneErrors?.end_date;

        if (!milestoneErrors && !valueHasError && !datesHasError) {
          return false;
        }

        return (
          !!milestoneErrors?.currency ||
          nameHasError ||
          descriptionHasError ||
          valueHasError ||
          datesHasError
        );
      });

      const { title, description } = values;
      const nameHasError = !title;
      const descriptionHasError = !description;

      return (
        !nameHasError &&
        !descriptionHasError &&
        !errors.client_name &&
        !milestonesHasError
      );
    },
    validateStep: ({ values, setFieldTouched }) => {
      setFieldTouched("title");
      setFieldTouched("client_name");
      setFieldTouched("description");
      setFieldTouched("milestones");
      setFieldTouched("milestones[0].value");

      if (!DATES_OPTIONAL) {
        setFieldTouched("milestones[0].start_date");
        setFieldTouched("milestones[0].end_date");
      }

      const milestoneCount = values.milestones.length;
      Array.from(Array(milestoneCount)).forEach((_, i) => {
        if (values.contract_type === ContractType.OneTime && i > 1) {
          return;
        }

        const milestoneKey = `milestones[${i}]`;
        setFieldTouched(`${milestoneKey}.name`);
        setFieldTouched(`${milestoneKey}.description`);
        setFieldTouched(`${milestoneKey}.value`);

        if (!DATES_OPTIONAL) {
          setFieldTouched(`${milestoneKey}.start_date`);
          setFieldTouched(`${milestoneKey}.end_date`);
        }
      });
    },
  },
  [Step.Review]: {
    urlId: "review",
    order: 5,
    component: <ReviewStep />,
    title: "Review your offer",
  },
};

export const STEPS_LIST = Object.entries(STEPS_CONFIG)
  .map(([id, config]) => ({ id, ...config }))
  .sort((a, b) => a.order - b.order);
