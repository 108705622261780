import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import {
  getContractCompletionDate,
  getContractPageUrl,
  isContractCompleted,
  useContractDetails,
} from "@/services/ContractsService";
import Modal, { useModalState } from "@/components/misc/Modal";
import { useFileList } from "@/services/MediaService";
import useToggle from "@/hooks/useToggle";
import { ChatRoomLoading } from "@/features/chat/ChatRoom";
import { useResponsive } from "@/styles";
import ContractManagementPage from "@/features/pages/contract/ContractManagementPage";
import { useHash, useNavigate } from "@/services/Routing";

import { IChatRoomPageContext } from "./ChatRoomPage.types";
import ChatDetails from "./components/ChatDetails";
import { StyledDesktopManagementContainer } from "./ChatRoomPage.styles";
import {
  CHAT_DETAILS_HASH,
  CONTRACT_DETAILS_HASH,
  LOCK_CHAT_THRESHOLD,
} from "./ChatRoomPage.config";

const ChatRoomPageContext = createContext({} as IChatRoomPageContext);

export const useChatRoomPage = () => useContext(ChatRoomPageContext);

export const ChatRoomPageProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { hash, removehash } = useHash();
  const showNewChatFlowState = useToggle();
  const chatDetailsModalState = useModalState({
    isOpen: hash === CHAT_DETAILS_HASH,
  });
  const contractDetailsModalState = useModalState({
    isOpen: hash === CONTRACT_DETAILS_HASH,
  });
  const { navigate } = useNavigate();

  const { isLoading, contractDetails, contractId } = useContractDetails({
    useContractIdFromUrl: true,
    redirectIfNotFound: true,
  });

  useFileList({ contract_ids: contractDetails ? [contractDetails.id] : [] });

  const viewJobDetails = useCallback(() => {
    if (!contractId) {
      return;
    }

    if (isScreenSmallerThanTablet) {
      navigate(getContractPageUrl(contractId));
    } else {
      contractDetailsModalState.open();
    }
  }, [
    navigate,
    contractId,
    contractDetailsModalState,
    isScreenSmallerThanTablet,
  ]);

  const isCompleted = contractDetails
    ? isContractCompleted(contractDetails)
    : false;

  const completionDatePassedThreshold = useMemo(() => {
    if (!contractDetails) {
      return false;
    }

    const completionDate = getContractCompletionDate(contractDetails);

    if (!completionDate) {
      return false;
    }

    const now = new Date();
    const dif = now.getTime() - new Date(completionDate).getTime();
    const Seconds_from_T1_to_T2 = dif / 1000;
    const diffInSeconds = Math.abs(Seconds_from_T1_to_T2);

    return diffInSeconds > LOCK_CHAT_THRESHOLD;
  }, [contractDetails]);

  useEffect(() => {
    if (isCompleted && completionDatePassedThreshold) {
      showNewChatFlowState.enable();
      return;
    }

    showNewChatFlowState.disable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted, completionDatePassedThreshold]);

  if (isLoading || !contractDetails) {
    return <ChatRoomLoading />;
  }

  return (
    <ChatRoomPageContext.Provider
      value={{
        contractDetails,
        chatDetailsModalState,
        showNewChatFlowState,
        contractDetailsModalState,
        viewJobDetails,
      }}
    >
      {chatDetailsModalState.isOpen &&
        (isScreenSmallerThanTablet ? (
          <Modal fullscreen className="p-0" state={chatDetailsModalState}>
            <ChatDetails />
          </Modal>
        ) : (
          <ChatDetails />
        ))}

      {contractDetailsModalState.isOpen &&
        // TO-CHECK menu button is a link , so won't open the mobile modal
        (isScreenSmallerThanTablet ? (
          <Modal fullscreen className="p-0" state={contractDetailsModalState}>
            <ContractManagementPage
              layoutProps={{
                headerProps: {
                  backButtonProps: {
                    onClick: () => removehash(),
                  },
                },
              }}
            />
          </Modal>
        ) : (
          <StyledDesktopManagementContainer>
            <ContractManagementPage
              layoutProps={{
                headerProps: {
                  backButtonProps: { show: false },
                },
              }}
            />
          </StyledDesktopManagementContainer>
        ))}

      {isScreenSmallerThanTablet
        ? children
        : chatDetailsModalState.isOpen || contractDetailsModalState.isOpen
        ? null
        : children}
    </ChatRoomPageContext.Provider>
  );
};
