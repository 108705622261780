import React, { useEffect } from "react";

import { getUserFullName, useProfileData } from "@/services/UserService";
import { IS_STAGING_ENV } from "@/config/app";
import UserProfileImage from "@/features/PaypipeId/UserProfileImage";
import Location from "@/features/PaypipeId/Location";
import SocialLinks from "@/features/PaypipeId/SocialLinks";
import { ANIMATION_CLASSNAMES, useResponsive } from "@/styles";
import Section from "@/features/PaypipeId/Section";
import SplashScreen from "@/features/pages/app/SplashScreen";
import PageHeading from "@/features/PaypipeId/PageHeading";
import AboutMe from "@/features/PaypipeId/AboutMe";
import ProfileStats from "@/features/PaypipeId/ProfileStats";
import Experiences from "@/features/PaypipeId/Experiences";
import Skills from "@/features/PaypipeId/Skills";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";

import EditHeading from "./components/input/EditHeading";
import EditAboutMe from "./components/input/EditAboutMe";
import EditExperience from "./components/input/EditExperience";
import EditSkills from "./components/input/EditSkills";
import {
  StyledBoldText,
  StyledContainer,
  StyledContentSection,
  StyledDashedDisplay,
  StyledEditMainDetails,
  StyledId,
  StyledIdContainer,
  StyledInnerContainer,
  StyledPage,
  StyledPrimaryInfoSection,
  StyledUsername,
} from "./EditPaypipeIdPage.styles";
import PortfolioSection from "./components/input/PortfolioSectionNew";
import PackagesSection from "./components/input/PackagesSectionNew";
import { HEADING_PLACEHOLDER } from "./EditPaypipeIdPage.config";
import Header from "./components/Header";
import EditMainDetails from "./components/input/EditMainDetails";

const PaypipeIdPage: React.FC = () => {
  const canEdit = true;
  const { isScreenSmallerThanTablet } = useResponsive();
  const { profileData, isProfileDataLoading, accountData } = useProfileData();

  useEffect(() => {
    if (IS_STAGING_ENV) {
      console.log("DEBUG", {
        accountData,
        profileData,
      });
    }
  }, [accountData, profileData]);

  if (isProfileDataLoading || !accountData) {
    return <SplashScreen />;
  }

  const userName = getUserFullName(accountData);
  const { paypipe_id } = accountData;
  const {
    location,
    heading,
    about_me: aboutMe,
    specializations: experiences = [],
    skills_new: skills = [],
    packages = [],
    links: socialLinks = [],
  } = profileData;

  const pageJsx = (
    <StyledPage className={ANIMATION_CLASSNAMES.FADE_IN}>
      <StyledContainer >
        <Header className="mb-4" paypipeId={paypipe_id || ""} />

        <StyledInnerContainer>
          <StyledPrimaryInfoSection>
            <div className="outer-container">
              <UserProfileImage
                className="profile-image"
                src={accountData.picture || ""}
                givenName={accountData.given_name || ""}
                familiyName={accountData.family_name || ""}
              />

              <div>
                <StyledUsername>{userName}</StyledUsername>
                {(!!paypipe_id || !!location) && (
                  <StyledIdContainer className="mt-2">
                    {!!paypipe_id && <StyledId>@{paypipe_id}</StyledId>}
                    {!!location && <Location location={location} />}
                  </StyledIdContainer>
                )}
              </div>
            </div>

            {socialLinks.length ? (
              <SocialLinks className="mt-4" links={socialLinks} />
            ) : canEdit ? (
              <EditMainDetails
                className="mt-4"
                dashedVariantProps={{ children: "Add Social Links" }}
              />
            ) : null}

            <StyledEditMainDetails />
          </StyledPrimaryInfoSection>

          <StyledContentSection>
            <PageHeading>
              {heading || HEADING_PLACEHOLDER}&nbsp;
              <EditHeading />
            </PageHeading>

            <Section
              className="mt-4"
              title={<>About me {!!aboutMe && <EditAboutMe />}</>}
            >
              {aboutMe ? (
                <AboutMe>{aboutMe}</AboutMe>
              ) : (
                <EditAboutMe
                  dashedVariantProps={{
                    size: "lg",
                    children:
                      "Briefly describe your background, key skills, and what drives your work.",
                  }}
                />
              )}
            </Section>

            <ProfileStats className="mt-4" />

            <Section
              className="mt-5"
              icon="assets/pages/paypipe-id/box.svg"
              title={
                <>My Experience {!!experiences.length && <EditExperience />}</>
              }
            >
              {!!experiences.length ? (
                <Experiences experiences={experiences} />
              ) : (
                <EditExperience
                  dashedVariantProps={{
                    size: "lg",
                    children:
                      "Summarize past roles, key responsibilities, and achievements in bullet points.",
                  }}
                />
              )}
            </Section>

            <StyledBoldText className="mt-5">Why hire me?</StyledBoldText>
            <Section
              className="mt-4"
              icon="assets/pages/paypipe-id/tools.svg"
              title={<>My Skills & Tools {!!skills.length && <EditSkills />}</>}
            >
              {!!skills.length ? (
                <Skills skills={skills} />
              ) : (
                <EditSkills
                  dashedVariantProps={{
                    size: "lg",
                    children:
                      "List relevant tools and software, grouped by category if applicable.",
                  }}
                />
              )}
            </Section>

            <Section
              className="mt-5"
              icon="assets/pages/paypipe-id/work.svg"
              title="My Recent Work"
            >
              <PortfolioSection
                portfolios={profileData.portfolios}
                canEdit={canEdit}
              />
            </Section>

            <Section
              className="mt-5"
              icon="assets/pages/paypipe-id/reviews.svg"
              title="Hear from others"
            >
              <StyledBoldText>
                Here is what my clients have to say:
              </StyledBoldText>
              <StyledDashedDisplay $large className="mt-4">
                Your Reviews Will Start Appearing Here Once You Have Any
              </StyledDashedDisplay>
            </Section>

            <Section
              className="mt-5"
              icon="assets/pages/paypipe-id/package.svg"
              title="My Packages"
            >
              <StyledBoldText>Popular jobs in one package.</StyledBoldText>

              <PackagesSection
                className="mt-4"
                canEdit={canEdit}
                packages={packages}
              />
            </Section>
          </StyledContentSection>
        </StyledInnerContainer>
      </StyledContainer>
    </StyledPage>
  );

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        headerProps={{ titleProps: { children: "Edit profile" } }}
      >
        {pageJsx}
      </PageWithHeaderLayout>
    );
  }

  return (
    <DesktopPrimaryLayout
      sidebarCompact
      headerProps={{ logo: { component: "Edit profile" } }}
    >
      {pageJsx}
    </DesktopPrimaryLayout>
  );
};

export default PaypipeIdPage;
