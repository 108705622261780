import React from "react";
import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { squareSizing } from "@/utils/css";
import Icon from "@/components/misc/Icon";
import { screenSmallerThan } from "@/styles";

const StyledContainer = styled.div`
  background: var(--clr-primary-100, #5f57ff);
  border-radius: 2.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem 4rem 1rem;

  .highlighted-content {
    display: flex;
    align-items: center;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    padding: 0.25rem;
    padding-inline-end: 0.5rem;
    border-radius: 10rem;

    .icon {
      ${squareSizing("2rem")};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--clr-primary-100, #5f57ff);
      padding: 0.25rem;
      border-radius: 10rem;
      margin-right: 0.5rem;

      ${screenSmallerThan.tablet} {
        display: none;
      }
    }

    ${screenSmallerThan.tablet} {
      padding-inline: 1rem;
      font-size: 0.875rem;
    }
  }

  .title {
    font-size: 4.25rem;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }

  ${screenSmallerThan.tablet} {
    padding-inline: 1.5rem;
  }
`;

export const StyledContactButton = styled(Button)`
  padding: 1rem;
  border-radius: 10rem !important;
  font-size: 1.125rem;
  font-weight: 500;
`;

const CtaSection: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledContainer className={className}>
      <div className="highlighted-content">
        <span className="icon">
          <Icon
            isSrcRelative
            src="location.svg"
            colorVariant="white"
            size="xs"
          />
        </span>
        Creating a free and secure account with Paypipe makes it easy for us to
        connect
      </div>

      <div className="title mt-3">Let's work together</div>

      <StyledContactButton className="mt-4" colorVariant="white-primary">
        <Icon
          src="assets/pages/paypipe-id/contact.svg"
          colorVariant="primary"
        />
        Get In Touch
      </StyledContactButton>
    </StyledContainer>
  );
};

export default CtaSection;
