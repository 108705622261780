import React, { useState } from "react";

import Tabs from "@/components/navigation/Tabs";
import AppVersion from "@/features/dev/AppVersion";

import Calendar from "./components/Calendar";
import Wysisyg from "./components/Wysisyg";
import HeightTest from "./components/HeightTest";
import TagInput from "./components/TagInput";
import Toast from "./components/Toast";
import Routing from "./components/Routing";
import Pwa from "./components/Pwa";
import Analytics from "./components/Analytics";
import PushNotification from "./components/PushNotification";
import Share from "./components/Share";
import StreamChat from "./components/StreamChat";
import Timeline from "./components/Timeline";
import Auth from "./components/Auth";
import Profile from "./components/Profile";
import Survey from "./components/Survey";
import Subscription from "./components/Subscription";

const TestPage: React.FC = () => {
  const [activeTabId, setActiveTabId] = useState("Subscription");

  return (
    <div className="p-3">
      <Tabs
        activeTabId={activeTabId}
        onChange={({ id }) => setActiveTabId(id)}
        tabs={[
          {
            id: "Subscription",
            title: "Subscription",
          },
          {
            id: "Survey",
            title: "Survey",
          },
          {
            id: "Profile",
            title: "Profile",
          },
          {
            id: "height-test",
            title: "Height test",
          },
          {
            id: "Auth",
            title: "Auth",
          },
          {
            id: "StreamChat",
            title: "Stream Chat",
          },
          {
            id: "Timeline",
            title: "Timeline",
          },
          {
            id: "Share",
            title: "Share",
          },
          {
            id: "PushNotification",
            title: "Push Notification",
          },
          {
            id: "PWA",
            title: "PWA",
          },
          {
            id: "Analytics",
            title: "Analytics",
          },
          {
            id: "routing",
            title: "Routing",
          },
          {
            id: "tag",
            title: "Tag",
          },
          {
            id: "calendar",
            title: "calendar",
          },
          {
            id: "wysisyg",
            title: "WYSIWYG",
          },
          {
            id: "toast",
            title: "Toast",
          },
        ]}
      />

      <div className="mt-2">
        {activeTabId === "Auth" && <Auth />}
        {activeTabId === "StreamChat" && <StreamChat />}
        {activeTabId === "Timeline" && <Timeline />}
        {activeTabId === "Share" && <Share />}
        {activeTabId === "PushNotification" && <PushNotification />}
        {activeTabId === "Analytics" && <Analytics />}
        {activeTabId === "PWA" && <Pwa />}
        {activeTabId === "routing" && <Routing />}
        {activeTabId === "tag" && <TagInput />}
        {activeTabId === "calendar" && <Calendar />}
        {activeTabId === "wysisyg" && <Wysisyg />}
        {activeTabId === "height-test" && <HeightTest />}
        {activeTabId === "toast" && <Toast />}
        {activeTabId === "Profile" && <Profile />}
        {activeTabId === "Survey" && <Survey />}
        {activeTabId === "Subscription" && <Subscription />}
      </div>

      <AppVersion />
    </div>
  );
};

export default TestPage;
