"use client";

import styled from "@emotion/styled";

import {
  getViewPortHeightCssString,
  screenLargerThan,
  screenSmallerThan,
  BREAKPOINT_NAMES,
} from "@/styles";
import { Body, Display, Label } from "@/components/Typography";
import Button from "@/components/input/Button";

const SWITCH_BREAKPOINT: BREAKPOINT_NAMES = "smallMonitor";

export const StyledPage = styled.div`
  display: flex;
  width: 100vw;
  height: ${getViewPortHeightCssString()};
  flex-direction: row-reverse;

  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    flex-direction: column;
  }
`;

export const StyledIllustration = styled.img``;

export const StyledTopSectioncontainer = styled.div`
  flex: 4;
  background-color: #f6f6fa;
`;

export const StyledTopSection = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 780px;
  margin-inline: auto;
  background-repeat: no-repeat;
  background-position: center;

  .swatch-1,
  .swatch-2 {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 1.9px 1.7px rgba(0, 0, 0, 0.02),
      0px 4.5px 4.1px rgba(0, 0, 0, 0.028), 0px 8.5px 7.8px rgba(0, 0, 0, 0.035),
      0px 15.2px 13.8px rgba(0, 0, 0, 0.042),
      0px 28.4px 25.9px rgba(0, 0, 0, 0.05), 0px 68px 62px rgba(0, 0, 0, 0.07);
  }

  .swatch-1 {
    position: absolute;
    top: 70%;
    left: 5%;
  }

  .swatch-2 {
    position: absolute;
    top: 50%;
    right: 5%;
  }

  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    background-size: auto 70%;
    background-position: bottom center;
    background-image: url("/assets/images/pages/onboarding/phone_desktop.png");

    .swatch-1,
    .swatch-2 {
      width: 28%;
      height: auto;
    }
  }

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    background-size: auto 60%;
    background-position: bottom center;
    background-image: url("/assets/images/pages/onboarding/phone_desktop.png");
  }
`;

export const StyledBottomSection = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    align-items: center;
    justify-content: center;
    padding: 1rem 3rem;
  }
`;

export const StyledBottomSectionContent = styled.div`
  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 30rem;
    max-height: 36rem;
    height: 100%;
  }
`;

export const StyledContentSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 1.5rem;
  margin-inline: auto;

  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    max-width: 36ch;
    text-align: center;
    margin-bottom: 1.5rem;
  }
`;

export const StyledHeading = styled(Display)`
  color: var(--clr-text-900, #0d0d12);
  text-align: center;

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    font-size: 2.25rem !important;
    line-height: 2.75rem !important;
  }
`;

export const StyledContent = styled(Label)`
  color: var(--clr-ukwn-3, #a4acb9);
  text-align: center;

  ${screenLargerThan[SWITCH_BREAKPOINT]} {
    font-size: 1rem !important;
    line-height: 2rem !important;
    margin-top: 1.5rem;
  }

  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    margin-top: 1rem;
  }
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  width: 100%;

  ${screenSmallerThan[SWITCH_BREAKPOINT]} {
    margin-top: auto;
    padding: 0 0.5rem;
  }
`;

export const StyledError = styled(Body)`
  color: #f04438;
`;

export const StyledButton = styled(Button)`
  padding-block: 1rem;
`;

// http://localhost:3001/claim?paypipe_id=DewashishBhawsar