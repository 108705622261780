"use client";

import styled from "@emotion/styled";

import { screenLargerThan, screenSmallerThan } from "@/styles";

import ChatRoomPage from "../ChatRoomPage";

export const StyledCardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: hidden;

  ${screenLargerThan.tablet} {
    min-width: 22.125rem;
  }

  ${screenSmallerThan.tablet} {
    padding: 1rem;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: hidden;

  ${screenLargerThan.tablet} {
    flex-direction: row;
    padding: 1rem;
  }
`;

export const NoChatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledChatList = styled.div`
  flex: 1;
  overflow: auto;

  hr {
    opacity: 0.5;
    margin-block: 0.5rem;
  }

  .str-chat__channel-list-messenger__main {
    padding-inline: 0.75rem;
  }

  .str-chat-channel-list {
    border: none;
  }

  ${screenLargerThan.tablet} {
    max-width: 21.5rem;
  }

  ${screenLargerThan.tablet} {
    max-width: 21.5rem;
  }
`;

//------------- Desktop

export const StyledChatRoomSection = styled.div`
  flex: 1;
  border: 1px solid var(--clr-border-25, #f6f8fa);
`;

export const StyledChatRoomPage = styled(ChatRoomPage)`
  height: 100%;
`;
