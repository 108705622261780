import styled from "@emotion/styled";

import { screenLargerThan, screenSmallerThan } from "@/styles";
import Button from "@/components/input/Button";

export const StyledUsername = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  word-break: break-word;

  ${screenLargerThan.widescreen} {
    font-size: 2.1875rem;
    margin-top: 1.5rem;
    text-align: center;
  }
`;

export const StyledId = styled.div`
  font-size: 0.875rem;
  font-weight: 400;

  ${screenLargerThan.widescreen} {
    font-size: 1.125rem;
  }
`;

export const StyledIdContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;

  ${screenLargerThan.widescreen} {
    justify-content: center;
    font-size: 2.1875rem;
    gap: 1rem;
  }
`;

export const StyledBoldText = styled.div`
  font-size: 2rem;
  font-weight: 600;

  ${screenSmallerThan.widescreen} {
    text-align: center;
  }
`;

export const StyledPortfolioCardsContainer = styled.div`
  ${screenLargerThan.widescreen} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  ${screenSmallerThan.widescreen} {
    & > * {
      margin-bottom: 1.5rem;
    }
  }
`;

//----------------------------

export const StyledPage = styled.div`
  position: relative;
  background: #f8f8f8;
  padding-block: 1rem 8rem;

  ${screenLargerThan.widescreen} {
    padding-block: 3rem 12rem;
    min-height: 100vh;
  }
`;

export const StyledContainer = styled.div`
  max-width: 1432px !important;
  padding-inline: 1rem;
  margin: 0 auto;
`;

export const StyledInnerContainer = styled.div`
  ${screenLargerThan.widescreen} {
    display: flex;
    align-items: flex-start;
    gap: 3rem;
  }
`;

export const StyledPrimaryInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  padding: 2rem;
  background: #fff;

  .outer-container {
    overflow: hidden;
    width: 100%;

    ${screenLargerThan.widescreen} {
      & > .profile-image {
        margin-inline: auto;
      }
    }
  }

  ${screenLargerThan.widescreen} {
    position: sticky;
    top: 3rem;
    min-width: 420px;
    padding-bottom: 4rem;
  }

  ${screenSmallerThan.widescreen} {
    position: relative;

    .outer-container {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const StyledContentSection = styled.div`
  flex-grow: 1;
  overflow: hidden;

  ${screenSmallerThan.widescreen} {
    margin-top: 1.5rem;
  }
`;

export const StyledDashedButton = styled(Button)<{ $large?: boolean }>`
  --bg-color: #f0f0f0 !important;
  border-style: dashed;
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.17);
  color: #737373;
  border-radius: 0.75rem !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25em;

  ${({ $large }) =>
    $large
      ? `
          padding-block: 1rem;
          min-height: 6.75rem;
        `
      : `
          padding-block: 1rem;
          min-height: 3.5rem;
        `}

  &:hover,
  &:active {
    --bg-color: #dbdaff !important;
  }

  &:active {
    box-shadow: 4px 4px 12px 0px #00000024 inset !important;
  }
`;

export const StyledContactButton = styled(Button)`
  padding: 1rem;
  border-radius: 10rem !important;
  font-size: 1.125rem;
  font-weight: 500;
`;

export const StyledMobileCtaSection = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.75rem;

  & > * {
    box-shadow: 0px 4px 24px 0px #0000000f;

    ${screenLargerThan.tablet} {
      padding: 1rem !important;
    }
  }
`;
