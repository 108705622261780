"use client";

import React from "react";

import { useResponsive } from "@/styles";

import ChatsPageMobile from "./ChatsPage.mobile";
import ChatsPageDesktop from "./ChatsPage.desktop";
import { ChatsPageProvider } from "./ChatsPage.context";

const ChatsPage: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();

  return (
    <ChatsPageProvider>
      {isScreenSmallerThanTablet ? <ChatsPageMobile /> : <ChatsPageDesktop />}
    </ChatsPageProvider>
  );
};

export default ChatsPage;
