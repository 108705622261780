"use client";

import React, { useCallback, useEffect, useRef } from "react";

import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import { useResponsive } from "@/styles";
import Header from "@/features/app/Header";
import AppPrimaryTabBar from "@/features/app/AppPrimaryTabBar";

import {
  StyledContainer,
  StyledContentContainer,
  StyledHeaderContainer,
  StyledMain,
  StyledTabBarContainer,
} from "./MobilePrimaryLayout.styles";
import { IMobilePrimaryLayoutProps } from "./MobilePrimaryLayout.types";

const MobilePrimaryLayout: React.FC<IMobilePrimaryLayoutProps> = (props) => {
  const { children, headerProps = {} } = props;

  const { isScreenSmallerThanTablet } = useResponsive();
  const headerContainerRef = useRef<HTMLDivElement | null>(null);
  const tabBarContainerRef = useRef<HTMLDivElement | null>(null);
  const lastY = useRef(0);

  //------------------------

  const scrollHander = useCallback((e: Event) => {
    const scrollY = window.scrollY;

    if (scrollY > lastY.current) {
      if (headerContainerRef.current) {
        headerContainerRef.current.classList.add("scroll-out");
      }

      if (tabBarContainerRef.current) {
        tabBarContainerRef.current.classList.add("scroll-out");
      }
    } else {
      if (headerContainerRef.current) {
        headerContainerRef.current.classList.remove("scroll-out");
      }

      if (tabBarContainerRef.current) {
        tabBarContainerRef.current.classList.remove("scroll-out");
      }
    }

    lastY.current = scrollY;
  }, []);

  //------------------------

  useEffect(() => {
    window.addEventListener("scroll", scrollHander);

    return () => {
      window.removeEventListener("scroll", scrollHander);
    };
  }, [scrollHander]);

  //------------------------

  if (!isScreenSmallerThanTablet) {
    return <DesktopPrimaryLayout {...props} />;
  }

  return (
    <StyledContainer>
      <StyledContentContainer>
        <StyledHeaderContainer ref={headerContainerRef}>
          <Header {...headerProps} />
        </StyledHeaderContainer>

        <StyledMain className="fade-in">{children}</StyledMain>

        <StyledTabBarContainer ref={tabBarContainerRef}>
          <AppPrimaryTabBar />
        </StyledTabBarContainer>
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default MobilePrimaryLayout;
