import React, { useCallback } from "react";

import { useNavigate, useParamState } from "@/services/Routing";
import {
  PAYPIPE_ID_SEARCH_PARAM_NAME,
  useAuth,
} from "@/services/Authentication";
import {
  useIsValidPaypipeId,
  useUpdatePaypipeId,
} from "@/services/UserService";
import Redirect from "@/components/navigation/Redirect";
import { SITE_PATHS } from "@/config/routing";
import RingLoader from "@/components/misc/RingLoader";
import TextField from "@/components/input/TextField";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useToast } from "@/components/misc/Toast";

import {
  StyledBottomSection,
  StyledBottomSectionContent,
  StyledButton,
  StyledContent,
  StyledContentSection,
  StyledError,
  StyledHeading,
  StyledInputContainer,
  StyledPage,
  StyledTopSection,
  StyledTopSectioncontainer,
} from "./ClaimIdPageNew.styles";

const PREFIX = "paypipe.me/";

const OnboardingPage: React.FC = () => {
  const [id, setId] = useParamState<string>(PAYPIPE_ID_SEARCH_PARAM_NAME, "", {
    parseJson: false,
  });
  const {
    data: { isValid, errors },
    isValidating,
  } = useIsValidPaypipeId({ id: id || "" });
  const { isAuthenticated, userData } = useAuth();
  const { isUpdating, update } = useUpdatePaypipeId();
  const { navigate } = useNavigate();
  const { createToast } = useToast();

  const handleUpdate = useCallback(() => {
    update(id || "")
      .then(() => {
        navigate(SITE_PATHS.HOME_PAGE, true);
        createToast({
          title: "ID created!",
          description: `Your Paypipe ID is "${id}"`,
          timeoutInMilliseconds: 10000,
        });
      })
      .catch(() => {
        createToast({
          title: `Something went wrong , Paypie ID is not set.`,
          variant: "error",
        });
      });
  }, [update, id, navigate, createToast]);

  if (!isAuthenticated) {
    return (
      <Redirect
        path={`${SITE_PATHS.HEADLESS_REGISTER_PAGE}?redirect=/claim?${PAYPIPE_ID_SEARCH_PARAM_NAME}=${id}`}
      />
    );
  }

  if (isUpdating) {
    return <SplashScreen />;
  }

  if (userData?.last_updated_paypipe_id) {
    return <Redirect replace path={SITE_PATHS.HOME_PAGE} />;
  }

  return (
    <StyledPage>
      <StyledTopSectioncontainer>
        <StyledTopSection>
          <img
            className="swatch-1"
            src="/assets/images/pages/onboarding/offer_accepted.svg"
            alt=""
          />
          <img
            className="swatch-2"
            src="/assets/images/pages/onboarding/payment_released.svg"
            alt=""
          />
        </StyledTopSection>
      </StyledTopSectioncontainer>

      <StyledBottomSection>
        <StyledBottomSectionContent>
          <StyledContentSection>
            <div>
              <StyledHeading size="md">Welcome to Paypipe!</StyledHeading>
              <StyledContent size="md">
                Choose your Paypipe ID. You can always change it later.
              </StyledContent>
            </div>
          </StyledContentSection>

          <StyledInputContainer>
            <TextField
              className="w-100"
              useFloatingLabel={false}
              variant="background"
              placeholder={`${PREFIX}yourname`}
              value={id ? `${PREFIX}${id}` : ""}
              onChange={(value) => {
                if (value.length > 1 && value.includes(PREFIX)) {
                  setId(value.split(PREFIX)[1]);
                } else {
                  setId(value);
                }
              }}
              appendContent={isValidating && <RingLoader />}
            />
            {!isValid &&
              errors.map((error) => (
                <StyledError size="md" key={error} className="mt-2 px-2">
                  - {error}
                </StyledError>
              ))}

            <StyledButton
              className="w-100 mt-4"
              disabled={isValidating || !isValid}
              onClick={handleUpdate}
            >
              Continue
            </StyledButton>

            <StyledButton
              variant="ghost"
              colorVariant="gray"
              className="w-100"
              link={SITE_PATHS.HOME_PAGE}
            >
              Skip
            </StyledButton>
          </StyledInputContainer>
        </StyledBottomSectionContent>
      </StyledBottomSection>
    </StyledPage>
  );
};

export default OnboardingPage;
