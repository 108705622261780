"use client";

import styled from "@emotion/styled";

import { Body, Label } from "@/components/Typography";
import Button from "@/components/input/Button";

export const StyledCard = styled.div`
  position: relative;
  color: #fff;
  background: linear-gradient(100.99deg, #5f57ff 16.93%, #01d8ff 100%);
  padding: 1rem;
  border-radius: 1rem;
`;

export const StyledTitle = styled(Label)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-right: 2rem;

  & > span {
    flex-grow: 1;
  }
`;

export const StyledContent = styled(Body)`
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-weight: 700;
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  top: 0.9rem;
  right: 0;
  z-index: 2;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
`;
