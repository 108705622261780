"use client";

import React, { useCallback, useState } from "react";
import * as yup from "yup";

import { useUpdateProfile } from "@/services/UserService";
import Wysiwyg from "@/components/input/LexicalWysiwyg/Wysiwyg";
import { useModalState } from "@/components/misc/Modal";

import EditFormButton from "./EditFormButtonNew";
import { IAddButtonProps } from "./AddButton";

//-----------------------------------

const VALIDATION_SCHEMA = yup.object().shape({
  about_me: yup.string().required().min(1),
});

//-----------------------------------

type FormValues = {
  about_me: string;
};

const EditAboutMe: React.FC<{
  className?: string;
  dashedVariantProps?: IAddButtonProps;
}> = ({ className, dashedVariantProps }) => {
  const { profileData, updateField } = useUpdateProfile();
  const [isUpdating, setIsUpdating] = useState(false);

  const modalState = useModalState({
    onOpenChange: () => {
      setIsUpdating(false);
    },
  });

  const apply = useCallback(
    ({ about_me }: FormValues) => {
      setIsUpdating(true);

      return updateField("about_me", about_me).finally(() => {
        setIsUpdating(false);
      });
    },
    [updateField]
  );

  return (
    <EditFormButton<FormValues>
      heading="Edit About Me"
      modalState={modalState}
      editButtonProps={{ className }}
      dashedVariantProps={dashedVariantProps}
      saveButtonProps={{
        disabled: isUpdating,
      }}
      cancelButtonProps={{
        disabled: isUpdating,
      }}
      modalProps={{
        width: "968px",
      }}
      formProps={{
        initialValues: { about_me: profileData.about_me },
        yupValidationSchema: VALIDATION_SCHEMA,
        onSubmit: apply,
      }}
      content={({
        context: {
          values: { about_me },
          setFieldValue,
        },
      }) => (
        <Wysiwyg
          focusOnMount
          style={{
            minHeight: "60vh",
            border: "1px solid rgba(0,0,0,0.04)",
            borderRadius: "4px",
          }}
          placeholder="Type here..."
          value={{ markdown: about_me }}
          onChange={({ markdown = "" }) => {
            setFieldValue("about_me", markdown);
          }}
        />
      )}
    />
  );
};

export default EditAboutMe;
