"use client";
import React, { useCallback, useMemo, useState } from "react";

import Form from "@/components/input/Form";
import { analytics, contractService, mediaService, errorReporting } from "@/config/services";
import { dateToEpoch } from "@/utils/date";
import {
  ContractCompleteDetails,
  ContractCreatorType,
  ContractCurrency,
  ContractType,
  sanitizeContractCompleteDetails,
} from "@/services/ContractsService";
import { useToast } from "@/components/misc/Toast";

import { INITIAL_VALUES, VALIDATION_SCHEMA } from "./ContractForm.config";
import { ContractFormValues, IContractFormProps } from "./ContractForm.types";
import { ContractFormProvider } from "./ContractForm.context";

const ContractForm: React.FC<IContractFormProps> = (props) => {
  const { initialValues: initialValuesFromProps = INITIAL_VALUES, onChange } =
    props;

  const { createToast } = useToast();

  const [creatingContract, setCreatingContract] = useState(false);
  const [contractCreated, setContractCreated] = useState(false);
  const [createdContractDetails, setCreatedContractDetails] =
    useState<null | ContractCompleteDetails>(null);

  //----------------------------

  const initialValues = useMemo(() => {
    return initialValuesFromProps;
  }, [initialValuesFromProps]);

  //----------------------------

  const handleSubmit = useCallback(
    (values: ContractFormValues) => {
      if (creatingContract) return;

      setCreatingContract(true);

      (async function () {
        try {
          const { files } = values;
          const payload: any = { ...values };

          const attatchmentsRes = await Promise.all(
            files
              .filter(({ file }) => !!file)
              .map(({ file }) => mediaService.uploadFile(file as File))
          );

          const attatchmentIds = attatchmentsRes.map((res) => res.data.data.id);
          payload.attachments = attatchmentIds;

          payload.start_date = payload.start_date
            ? dateToEpoch(payload.start_date)
            : payload.start_date;
          payload.end_date = payload.end_date
            ? dateToEpoch(payload.end_date)
            : payload.end_date;

          payload.milestones = values.milestones.map((m) => ({
            ...m,
            start_date: m.start_date
              ? dateToEpoch(new Date(m.start_date))
              : m.start_date,
            end_date: m.end_date
              ? dateToEpoch(new Date(m.end_date))
              : m.end_date,
            currency: ContractCurrency.USD,
          }));

          if (values.contract_type === ContractType.OneTime) {
            payload.milestones = [payload.milestones[0]];
          }

          // TO-CHECK
          payload.created_as = ContractCreatorType.Payee;

          const contractDetailsRes = await contractService.createContract(
            payload
          );
          const contractDetails = sanitizeContractCompleteDetails(
            contractDetailsRes.data.data
          );
          setCreatedContractDetails(contractDetails);

          setContractCreated(true);

          analytics.triggerOfferFormEvent("create_offer_success");
        } catch (err) {
          errorReporting.report(err);
          analytics.triggerOfferFormEvent("create_offer_error");
          setContractCreated(false);
          createToast({
            title: "Offer not created!",
            variant: "error",
            timeoutInMilliseconds: 15000,
          });
        } finally {
          setCreatingContract(false);
        }
      })();
    },
    [creatingContract, createToast]
  );

  //----------------------------

  return (
    <>
      <Form<ContractFormValues>
        onSubmit={handleSubmit}
        yupValidationSchema={VALIDATION_SCHEMA}
        initialValues={initialValues}
        onChange={onChange}
      >
        <ContractFormProvider
          {...props}
          creatingContract={creatingContract}
          contractCreated={contractCreated}
          createdContractDetails={createdContractDetails}
        />
      </Form>
    </>
  );
};

export default ContractForm;
