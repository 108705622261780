import { SITE_PATHS } from "@/config/routing";
import Share, { ShareContent } from "@/services/Share";

export function getChatRoomPageUrl(contractId: string) {
  return `${SITE_PATHS.CHAT_PAGE}?id=${contractId}`;
}

//--------------------

export function getOfferShareContent(): ShareContent {
  return {
    title: "Come chat with me on Paypipe.",
    url: Share.getShareUrl(""),
    text: `Chat with me on Paypipe to discuss this job and track my progress.\n\n Paypipe provides secure and safe transactions globally between freelancers and their clients`,
  };
}
