import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { MediaFile, Portfolio } from "@/services/UserService";
import { useFileSignedUrl } from "@/services/ContractsService";
import ShimmerLoader from "@/components/misc/ShimmerLoader";
import Slider from "@/components/misc/Slider";
import { screenLargerThan } from "@/styles";
import Wysiwyg from "@/components/input/LexicalWysiwyg";
import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";

//-----------------------------

const StyledContainer = styled.div`
  position: relative;

  .actions-section {
    position: relative;
    z-index: 2;
    pointer-events: none !important;

    & > * {
      pointer-events: all;
    }
  }

  .image {
    position: relative;
    height: 28rem;
    max-height: 45vh;
    background-size: cover;
    background-position: center;
    border-radius: 0.625rem;
    background-color: #f1f1f1;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    word-break: break-word;

    ${screenLargerThan.tablet} {
      font-size: 2rem;
    }
  }

  .description {
    font-size: 1.125rem;
    font-weight: 400;
  }

  .section-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.125rem;
    color: #7d7d7d;
  }

  .tech {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .tag {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 1.125rem;
    font-weight: 400;
    color: #0f172a;
    background: #f8f8f8;
    backdrop-filter: blur(19.5px);
    padding: 0.75rem;
    border-radius: 0.5rem;
    min-width: fit-content;

    .icon-container {
      ${squareSizing("2rem")};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.375rem;
      background: #0a0a0a;
    }
  }
`;

const StyledImage = styled.div`
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.44));
  background-size: cover;
  background-position: center;
  height: 28rem;
  max-height: 45vh;
`;

const StyledImages = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 28rem;
  max-height: 45vh;
  border-radius: inherit;
  z-index: 1;

  & > * {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
  }
`;

const StyledLink = styled(Link)`
  ${squareSizing("2.5rem")};
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

//-----------------------------

const Image: React.FC<{ image: MediaFile }> = ({ image }) => {
  const { url, isLoading } = useFileSignedUrl(image.url || "");

  if (isLoading) {
    return (
      <ShimmerLoader.Default
        style={{ height: "24rem", borderRadius: "0.625rem" }}
      />
    );
  }

  return (
    <StyledImage
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.44)), url(${url})`,
      }}
    />
  );
};

const Images: React.FC<{ images: MediaFile[] }> = ({ images }) => {
  if (images.length === 1) {
    const firstImage = images[0];
    return (
      <StyledImages>
        <Image key={firstImage.id} image={firstImage} />
      </StyledImages>
    );
  }

  return (
    <StyledImages>
      <Slider>
        {images.map((image) => (
          <Image key={image.id} image={image} />
        ))}
      </Slider>
    </StyledImages>
  );
};

const PortfolioPreview: React.FC<{
  portfolio: Portfolio;
}> = ({ portfolio }) => {
  const { name, attachments = [], link, tech, description } = portfolio;

  return (
    <StyledContainer>
      <div className="title">{name}</div>

      <div className="image mt-3">
        <Images images={attachments} />

        {!!link && (
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            to={link}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon isSrcRelative src="arrow_link.svg" />
          </StyledLink>
        )}
      </div>

      <Wysiwyg
        readOnly
        unstyled
        showFullContent
        className="mt-4 description"
        value={{ markdown: description }}
        initialValue={{ markdown: description }}
      />

      {!!tech.length && (
        <div className="mt-4">
          <div className="section-title">
            <Icon src="assets/pages/paypipe-id/tools.svg" />{" "}
            <div>Tools used</div>
          </div>

          <div className="tech mt-3">
            {tech.map((t, i) => (
              <span key={i} className="tag">
                <div className="icon-container">
                  <Icon
                    isSrcRelative
                    size="xs"
                    colorVariant="white"
                    src="location.svg"
                  />
                </div>
                {t}
              </span>
            ))}
          </div>
        </div>
      )}
    </StyledContainer>
  );
};

export default PortfolioPreview;
