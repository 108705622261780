import { useCallback, useState } from "react";

import { userService } from "@/config/services";

function useUpdatePaypipeId() {
  const [isUpdating, setIsUpdating] = useState(false);

  const update = useCallback((id: string) => {
    setIsUpdating(true);

    return userService.updatePaypipeId(id).finally(() => {
      setIsUpdating(false);
    });
  }, []);

  return {
    update,
    isUpdating,
  };
}

export default useUpdatePaypipeId;
