export const HTML_CONTENT = `
      <style>
        [data-custom-class="body"],
        [data-custom-class="body"] * {
          background: transparent !important;
        }
        [data-custom-class="title"],
        [data-custom-class="title"] * {
          font-family: Arial !important;
          font-size: 26px !important;
          color: #000000 !important;
        }
        [data-custom-class="subtitle"],
        [data-custom-class="subtitle"] * {
          font-family: Arial !important;
          font-size: 14px !important;
        }
        [data-custom-class="heading_1"],
        [data-custom-class="heading_1"] * {
          font-family: Arial !important;
          font-size: 19px !important;
          color: #000000 !important;
        }
        [data-custom-class="heading_2"],
        [data-custom-class="heading_2"] * {
          font-family: Arial !important;
          font-size: 17px !important;
          color: #000000 !important;
        }
        [data-custom-class="body_text"],
        [data-custom-class="body_text"] * {
          font-size: 14px !important;
          font-family: Arial !important;
        }
        [data-custom-class="link"],
        [data-custom-class="link"] * {
          color: #3030f1 !important;
          font-size: 14px !important;
          font-family: Arial !important;
          word-break: break-word !important;
        }
      </style>

      <style>
        ol {
          list-style: lower-alpha;
        }

        ol ul,
        ol ol {
          list-style: lower-roman;
        }

        ol ul ol,
        ol ul ul,
        ol ol ol {
          list-style: upper-alpha !important;
        }

        li {
          margin-top: 0.5rem;
        }

        a {
          color: #1f70fc !important;
        }
      </style>

      <div data-custom-class="body">
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="title"
            style="line-height: 1.5"
          >
            <bdt class="block-component"
              ><span style="font-size: 19px"></span></bdt
            ><bdt class="question"><strong>TERMS OF SERVICE</strong></bdt
            ><bdt class="statement-end-if-in-editor"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="subtitle"
            style="line-height: 1.5"
          >
            <strong>Last updated</strong>
            <bdt class="question"><strong>July 19, 2024</strong></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.1"><br /></div>
          <div class="MsoNormal" style="line-height: 115%"><br /></div>
          <div class="MsoNormal" style="line-height: 115%"><br /></div>
          <div style="line-height: 1.5">
            <strong
              ><span data-custom-class="heading_1"
                >AGREEMENT TO OUR LEGAL TERMS</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left">
          <div class="MsoNormal" id="agreement" style="line-height: 1.5">
            <a name="_6aa3gkhykvst"></a>
          </div>
        </div>
        <div align="center" style="line-height: 1"><br /></div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >We are
              <bdt
                class="block-container question question-in-editor"
                data-id="9d459c4e-c548-e5cb-7729-a118548965d2"
                data-type="question"
                >PAYPIPE PTY LTD</bdt
              ><bdt class="block-component"></bdt> (<bdt
                class="block-component"
              ></bdt
              >"<strong>Company</strong>," "<strong>we</strong>,"
              "<strong>us</strong>," "<strong>our</strong>"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >)<span
                style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
                ><span
                  style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
                  ><span
                    style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
                    ><bdt class="question"
                      ><bdt class="block-component"></bdt></bdt
                    ><span
                      style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
                      ><bdt class="block-component"></bdt></span
                    >, a company registered in<bdt class="block-component"></bdt
                    ><bdt class="block-component"></bdt>
                    <span
                      style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
                      ><span
                        style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
                        ><bdt class="question">Australia</bdt
                        ><bdt class="statement-end-if-in-editor"
                          ><span
                            style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
                            ><span
                              style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
                              ><bdt
                                class="statement-end-if-in-editor"
                              ></bdt></span></span></bdt></span
                    ></span>
                    at <bdt class="question">81-83 Campbell Street</bdt
                    ><bdt class="block-component"></bdt></span></span
                >, <bdt class="question">Surry Hills</bdt></span
              ></span
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"
                    ><span style="color: rgb(89, 89, 89)"
                      ><bdt class="block-component"></bdt>,
                      <bdt class="question">New South Wales</bdt
                      ><bdt class="block-component"></bdt
                      ><bdt class="block-component"></bdt>
                      <bdt class="question">2010</bdt
                      ><bdt
                        class="statement-end-if-in-editor"
                      ></bdt></span></span></span></span></span
            ><span
              style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
              ><span
                style="font-size:11.0pt;line-height:115%;
    Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
    mso-themetint:166;"
                ><bdt class="else-block"></bdt></span></span
            ><bdt class="statement-end-if-in-editor">.</bdt
            ><bdt class="block-component"></bdt>
          </div>
        </div>
        <div align="center" style="line-height: 1"><br /></div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >We operate <bdt class="block-component"></bdt>the website
              <span style="color: rgb(0, 58, 250)"
                ><bdt class="question"
                  ><a
                    href="https://paypipe.io"
                    target="_blank"
                    data-custom-class="link"
                    >https://paypipe.io</a
                  >
                  and other affiliated wesbsites, including but not limited to
                  (<a
                    href="https://paypipe.me"
                    target="_blank"
                    data-custom-class="link"
                    >https://paypipe.me</a
                  >)</bdt
                ></span
              >
              (the
              <bdt class="block-component"></bdt>"<strong>Site</strong>"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >)<bdt class="block-component"></bdt>,
              <bdt class="statement-end-if-in-editor"></bdt
              ><bdt class="block-component"></bdt>the mobile application
              <bdt class="question">Paypipe</bdt> (the
              <bdt class="block-component"></bdt>"<strong>App</strong>"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >)<bdt class="statement-end-if-in-editor"></bdt>, as well as any
              other related products and services that refer or link to these
              legal terms (the <bdt class="block-component"></bdt>"<strong
                >Legal Terms</strong
              >"<bdt class="statement-end-if-in-editor"></bdt>) (collectively,
              the
              <bdt class="block-component"></bdt>"<strong>Services</strong>"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >).<bdt class="block-component"></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <bdt class="question"
              >Welcome to Paypipe - Paypipe provides an online platform designed
              to streamline payment transactions between independent contractors
              and their clients. Our service enables users to securely transfer
              funds, manage transactions, and facilitate seamless financial
              interactions within the freelance and independent contracting
              space. These Terms and Conditions apply to both PAYPIPE PTY LTD
              (the "Parent Company") and PAYPIPE US, INC. (the "Subsidiary").
              The following provisions govern your use of the services provided
              by both legal entities (collectively referred to as "Paypipe",
              "we", "our", or "us"). By using our services, you agree to these
              Terms and Conditions. 1. Definitions 1.1. "Parent Company" refers
              to PAYPIPE PTY LTD, an entity incorporated and operating under the
              laws of Australia. 1.2. "Subsidiary" refers to PAYPIPE US, INC.,
              an entity incorporated and operating under the laws of the United
              States. 1.3. "Paypipe" collectively refers to both PAYPIPE PTY LTD
              and PAYPIPE US, INC. 2. Scope These Terms and Conditions apply to
              all users of Paypipe’s services, regardless of the geographical
              location or the specific legal entity through which services are
              accessed. 3. Services Paypipe offers a range of services, which
              may be provided by either the Parent Company or the Subsidiary,
              depending on the user’s location and the nature of the service
              requested. 4. Jurisdiction and Governing Law 4.1. For users
              accessing services through the Parent Company (PAYPIPE PTY LTD),
              these Terms and Conditions are governed by the laws of Australia.
              Any disputes will be resolved in the courts of Australia. 4.2. For
              users accessing services through the Subsidiary (PAYPIPE US,
              INC.), these Terms and Conditions are governed by the laws of the
              United States. Any disputes will be resolved in the courts of the
              United States. 5. Liability Both PAYPIPE PTY LTD and PAYPIPE US,
              INC. will not be liable for any damages arising out of or in
              connection with the use of our services. This limitation of
              liability applies to all kinds of damages, including but not
              limited to, direct, indirect, incidental, punitive, and
              consequential damages. 6. Amendments Paypipe reserves the right to
              amend these Terms and Conditions at any time. Any changes will be
              effective immediately upon posting on our website. Continued use
              of our services following any amendment constitutes acceptance of
              the new terms. 7. Contact Information For any queries or concerns
              regarding these Terms and Conditions, you can contact us at:
              legal@paypipe.io By accessing and using our services, you
              acknowledge that you have read, understood, and agree to be bound
              by these Terms and Conditions applicable to both PAYPIPE PTY LTD
              and PAYPIPE US, INC. We encourage you to review these terms
              carefully before engaging with our services.</bdt
            >
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><bdt class="statement-end-if-in-editor"></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >You can contact us by
              <bdt class="block-component"
                >email at <bdt class="question">hello@paypipe.io</bdt
                ><bdt class="block-component"></bdt> or by mail to
                <bdt class="question">81-83 Campbell Street</bdt
                ><bdt class="block-component"></bdt>,
                <bdt class="question">Surry Hills</bdt
                ><bdt class="block-component"></bdt>,
                <bdt class="question">New South Wales</bdt
                ><bdt class="block-component"></bdt
                ><bdt class="block-component"></bdt>
                <bdt class="question">2010</bdt
                ><bdt class="statement-end-if-in-editor"></bdt
                ><bdt class="block-component"
                  ><bdt class="block-component">,&nbsp;</bdt
                  ><bdt class="question">Australia</bdt
                  ><bdt class="statement-end-if-in-editor"></bdt></bdt
                >.</bdt
              ></span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >These Legal Terms constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (<bdt
                class="block-component"
              ></bdt
              >"<strong>you</strong>"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >), and <bdt class="question">PAYPIPE PTY LTD</bdt>, concerning
              your access to and use of the Services. You agree that by
              accessing the Services, you have read, understood, and agreed to
              be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL
              OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
              THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.<bdt
                class="block-component"
              ></bdt
              ><bdt class="block-component"></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >We will provide you with prior notice of any scheduled changes to
              the Services you are using. The modified Legal Terms will become
              effective upon posting or notifying you by
              <bdt class="question">info@paypipe.io</bdt>, as stated in the
              email message. By continuing to use the Services after the
              effective date of any changes, you agree to be bound by the
              modified terms.<bdt class="block-component"></bdt
              ><bdt class="statement-end-if-in-editor"></bdt
            ></span>
          </div>
        </div>
        <div align="center" style="line-height: 1"><br /></div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <bdt
              class="block-container if"
              data-type="if"
              id="a2595956-7028-dbe5-123e-d3d3a93ed076"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    ><bdt class="block-component"></bdt>The Services are
                    intended for users who are at least 18 years old. Persons
                    under the age of 18 are not permitted to use or register for
                    the Services.</span
                  ></bdt
                ></bdt
              ><bdt data-type="conditional-block"
                ><bdt class="block-component"></bdt></bdt
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            We recommend that you print a copy of these Legal Terms for your
            records.
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            style="line-height: 1.5"
          >
            <strong>TABLE OF CONTENTS</strong>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a href="#services"
              ><span data-custom-class="link"
                ><span style="color: rgb(0, 58, 250); font-size: 15px"
                  ><span data-custom-class="body_text"
                    >1. OUR SERVICES</span
                  ></span
                ></span
              ></a
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#ip"
              ><span style="color: rgb(0, 58, 250)"
                ><span data-custom-class="body_text"
                  >2. INTELLECTUAL PROPERTY RIGHTS</span
                ></span
              ></a
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#userreps"></a
            ><a data-custom-class="link" href="#userreps"
              ><span style="color: rbg(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >3. USER REPRESENTATIONS</span
                ></span
              ></a
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><bdt class="block-component"></bdt></span></span
            ><a data-custom-class="link" href="#userreg"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >4. USER REGISTRATION</span
                ></span
              ></a
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><bdt class="statement-end-if-in-editor"></bdt></span
            ></span>
            <a data-custom-class="link" href="#products"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#products"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#purchases"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#purchases"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt>5. PURCHASES AND
                  PAYMENT<bdt
                    class="statement-end-if-in-editor"
                  ></bdt></span></span
            ></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"
              ><span style="font-size: 15px"></span></bdt
            ><a data-custom-class="link" href="#subscriptions"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >6. SUBSCRIPTIONS</span
                ></span
              ></a
            ><bdt class="statement-end-if-in-editor"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><bdt class="block-component"></bdt></span
            ></span>
            <a data-custom-class="link" href="#software"></a>
            <a data-custom-class="link" href="#software"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#software"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#prohibited"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#prohibited"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >7. PROHIBITED ACTIVITIES</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#ugc"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#ugc"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >8. USER GENERATED CONTRIBUTIONS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#license"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#license"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >9. CONTRIBUTION
                  <bdt class="block-component"></bdt>LICENSE<bdt
                    class="statement-end-if-in-editor"
                  ></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#reviews"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#reviews"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt>10. GUIDELINES FOR
                  REVIEWS<bdt
                    class="statement-end-if-in-editor"
                  ></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#mobile"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#mobile"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt>11. MOBILE APPLICATION
                  <bdt class="block-component"></bdt>LICENSE<bdt
                    class="statement-end-if-in-editor"
                  ></bdt
                  ><bdt class="statement-end-if-in-editor"></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#socialmedia"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#socialmedia"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt>12. SOCIAL MEDIA<bdt
                    class="statement-end-if-in-editor"
                  ></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#thirdparty"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#thirdparty"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt>13. THIRD-PARTY WEBSITES
                  AND CONTENT<bdt
                    class="statement-end-if-in-editor"
                  ></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#advertisers"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#advertisers"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#sitemanage"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#sitemanage"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >14. SERVICES MANAGEMENT</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#ppyes"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#ppyes"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#ppno"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#ppno"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt>15. PRIVACY POLICY<bdt
                    class="statement-end-if-in-editor"
                  ></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#dmca"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#dmca"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt
                  ><bdt class="block-component"></bdt
                  ><bdt class="statement-end-if-in-editor"></bdt></span></span
            ></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><bdt class="block-component"></bdt
                ><bdt class="block-component"></bdt></span></span
            ><a data-custom-class="link" href="#copyrightyes"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >16. COPYRIGHT INFRINGEMENTS</span
                ></span
              ></a
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><bdt class="statement-end-if-in-editor"></bdt
                ><bdt class="block-component"></bdt></span
            ></span>
            <a data-custom-class="link" href="#terms"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#terms"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >17. TERM AND TERMINATION</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#modifications"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#modifications"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >18. MODIFICATIONS AND INTERRUPTIONS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#law"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#law"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >19. GOVERNING LAW</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#disputes"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#disputes"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >20. DISPUTE RESOLUTION</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#corrections"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#corrections"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >21. CORRECTIONS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#disclaimer"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#disclaimer"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text">22. DISCLAIMER</span></span
              ></a
            >
            <a data-custom-class="link" href="#liability"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#liability"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >23. LIMITATIONS OF LIABILITY</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#indemnification"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#indemnification"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >24. INDEMNIFICATION</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#userdata"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#userdata"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text">25. USER DATA</span></span
              ></a
            >
            <a data-custom-class="link" href="#electronic"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#electronic"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                  SIGNATURES</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#california"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="color: rgb(0, 58, 250); font-size: 15px"
              ><a data-custom-class="link" href="#sms"
                ><span data-custom-class="body_text"
                  >27. SMS TEXT MESSAGING</span
                ></a
              ></span
            ><bdt class="statement-end-if-in-editor"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#california"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt>28. CALIFORNIA USERS AND
                  RESIDENTS<bdt
                    class="statement-end-if-in-editor"
                  ></bdt></span></span
            ></a>
            <a data-custom-class="link" href="#misc"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#misc"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >29. MISCELLANEOUS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
          
            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#paypipes-role-and-obligations"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >30. PAYPIPE'S ROLE AND OBLIGATIONS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        

            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#user-obligations"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >31. USER OBLIGATIONS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        

            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#community-guidelines"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >32. COMMUNITY GUIDELINES</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        

            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#fees-and-payment-facility"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >33. FEES AND PAYMENT FACILITY</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        

            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#release-conditions"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >34. RELEASE CONDITIONS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        

            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#refunds-and-cancellations"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >35. REFUNDS AND CANCELLATIONS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        

            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#payment-terms"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >36. PAYMENT TERMS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        

            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#non-payment-or-default"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >37. NON-PAYMENT OR DEFAULT</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        

            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#no-return-of-funds-and-no-chargebacks"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >38. NO RETURN OF FUNDS AND NO CHARGEBACKS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        

            <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#dispute-resolution-process"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >39. DISPUTE RESOLUTION PROCESS</span
                ></span
              ></a
            >
            <a data-custom-class="link" href="#contact"></a>
          </div>
        
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <a data-custom-class="link" href="#contact"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >40. CONTACT
                  US</span
                ></span
              ></a
            >
          </div>
        </div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            style="line-height: 1.5"
          >
            <a name="_b6y29mp52qvx"></a>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="services"
            style="line-height: 1.5"
          >
            <strong
              ><span style="font-size: 19px">1. OUR SERVICES</span></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              >The information provided when using the Services is not intended
              for distribution to or use by any person or entity in any
              jurisdiction or country where such distribution or use would be
              contrary to law or regulation or which would subject us to any
              registration requirement within such jurisdiction or country.
              Accordingly, those persons who choose to access the Services from
              other locations do so on their own initiative and are solely
              responsible for compliance with local laws, if and to the extent
              local laws are applicable.<bdt
                class="block-component"
              ></bdt></span
            ><bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              >The Services are not tailored to comply with industry-specific
              regulations (Health Insurance Portability and Accountability Act
              (HIPAA), Federal Information Security Management Act (FISMA),
              etc.), so if your interactions would be subjected to such laws,
              you may not use the Services. You may not use the Services in a
              way that would violate the Gramm-Leach-Bliley Act (GLBA).<bdt
                class="block-component"
              ></bdt
              ><bdt class="statement-end-if-in-editor"></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
        </div>
        <div
          align="center"
          data-custom-class="heading_1"
          style="text-align: left; line-height: 1.5"
        >
          <strong
            ><span id="ip" style="font-size: 19px"
              >2. INTELLECTUAL PROPERTY RIGHTS</span
            ></strong
          >
        </div>
        <div align="center" style="line-height: 1.5"><br /></div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <strong>Our intellectual property</strong>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >We are the owner or the licensee of all intellectual property
              rights in our Services, including all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics in the Services (collectively, the
              <bdt class="block-component"></bdt>"Content"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >), as well as the trademarks, service marks, and logos contained
              therein (the <bdt class="block-component"></bdt>"Marks"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >).</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >Our Content and Marks are protected by copyright and trademark
              laws (and various other intellectual property rights and unfair
              competition laws) and treaties in the United States and around the
              world.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >The Content and Marks are provided in or through the Services
              <bdt class="block-component"></bdt>"AS IS"<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              for your <bdt class="block-component"></bdt>personal,
              non-commercial use or internal business purpose<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              only.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <strong>Your use of our Services</strong>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              >Subject to your compliance with these Legal Terms, including the
              <bdt class="block-component"></bdt>"<bdt
                class="statement-end-if-in-editor"
              ></bdt></span
            ><a data-custom-class="link" href="#prohibited"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                >PROHIBITED ACTIVITIES</span
              ></a
            ><span style="font-size: 15px"
              ><bdt class="block-component"></bdt>"<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              section below, we grant you a non-exclusive, non-transferable,
              revocable <bdt class="block-component"></bdt>license<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              to:</span
            >
          </div>
          <ul>
            <li
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span style="font-size: 15px">access the Services; and</span>
            </li>
            <li
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span style="font-size: 15px"
                >download or print a copy of any portion of the Content to which
                you have properly gained access.</span
              >
            </li>
          </ul>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >solely for your <bdt class="block-component"></bdt>personal,
              non-commercial use or internal business purpose<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >Except as set out in this section or elsewhere in our Legal
              Terms, no part of the Services and no Content or Marks may be
              copied, reproduced, aggregated, republished, uploaded, posted,
              publicly displayed, encoded, translated, transmitted, distributed,
              sold, licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >If you wish to make any use of the Services, Content, or Marks
              other than as set out in this section or elsewhere in our Legal
              Terms, please address your request to:
              <bdt class="question">hello@paypipe.io</bdt>. If we ever grant you
              the permission to post, reproduce, or publicly display any part of
              our Services or Content, you must identify us as the owners or
              licensors of the Services, Content, or Marks and ensure that any
              copyright or proprietary notice appears or is visible on posting,
              reproducing, or displaying our Content.</span
            >
          </div>
        </div>
        <div align="center" style="line-height: 1.5"><br /></div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >We reserve all rights not expressly granted to you in and to the
              Services, Content, and Marks.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >Any breach of these Intellectual Property Rights will constitute
              a material breach of our Legal Terms and your right to use our
              Services will terminate immediately.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><strong
                >Your submissions<bdt class="block-component"></bdt> and
                contributions</strong
              ><bdt class="statement-end-if-in-editor"><strong></strong></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              >Please review this section and the
              <bdt class="block-component"></bdt>"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              ><a data-custom-class="link" href="#prohibited"
                ><span style="color: rgb(0, 58, 250)"
                  >PROHIBITED ACTIVITIES</span
                ></a
              ><bdt class="block-component"></bdt>"<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              section carefully prior to using our Services to understand the
              (a) rights you give us and (b) obligations you have when you post
              or upload any content through the Services.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              ><strong>Submissions:</strong> By directly sending us any
              question, comment, suggestion, idea, feedback, or other
              information about the Services (<bdt class="block-component"></bdt
              >"Submissions"<bdt class="statement-end-if-in-editor"></bdt>), you
              agree to assign to us all intellectual property rights in such
              Submission. You agree that we shall own this Submission and be
              entitled to its unrestricted use and dissemination for any lawful
              purpose, commercial or otherwise, without acknowledgment or
              compensation to you.<bdt class="block-component"></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              ><strong>Contributions:</strong> The Services may invite you to
              chat, contribute to, or participate in blogs, message boards,
              online forums, and other functionality during which you may
              create, submit, post, display, transmit, publish, distribute, or
              broadcast content and materials to us or through the Services,
              including but not limited to text, writings, video, audio,
              photographs, music, graphics, comments, reviews, rating
              suggestions, personal information, or other material (<bdt
                class="block-component"
              ></bdt
              >"Contributions"<bdt class="statement-end-if-in-editor"></bdt>).
              Any Submission that is publicly posted shall also be treated as a
              Contribution.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              >You understand that Contributions may be viewable by other users
              of the Services<bdt class="block-component"></bdt> and possibly
              through third-party websites<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              ><strong
                >When you post Contributions, you grant us a
                <bdt class="block-component"></bdt>license<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                (including use of your name, trademarks, and
                logos):&nbsp;</strong
              >By posting any Contributions, you grant us an unrestricted,
              unlimited, irrevocable, perpetual, non-exclusive, transferable,
              royalty-free, fully-paid, worldwide right, and
              <bdt class="block-component"></bdt>license<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              to: use, copy, reproduce, distribute, sell, resell, publish,
              broadcast, retitle, store, publicly perform, publicly display,
              reformat, translate, excerpt (in whole or in part), and exploit
              your Contributions (including, without limitation, your image,
              name, and voice) for any purpose, commercial, advertising, or
              otherwise, to prepare derivative works of, or incorporate into
              other works, your Contributions, and to
              <bdt class="block-component"></bdt>sublicense the licenses<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              granted in this section. Our use and distribution may occur in any
              media formats and through any media channels.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              >This <bdt class="block-component"></bdt>license<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              includes our use of your name, company name, and franchise name,
              as applicable, and any of the trademarks, service marks, trade
              names, logos, and personal and commercial images you provide.<bdt
                class="statement-end-if-in-editor"
              ></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"
              ><strong>You are responsible for what you post or upload:</strong>
              By sending us Submissions<bdt class="block-component"></bdt>
              and/or posting Contributions<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              through any part of the Services<bdt
                class="block-component"
              ></bdt>
              or making Contributions accessible through the Services by linking
              your account through the Services to any of your social networking
              accounts,<bdt class="statement-end-if-in-editor"></bdt> you:</span
            >
          </div>
          <ul>
            <li
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span style="font-size: 15px"
                >confirm that you have read and agree with our
                <bdt class="block-component"></bdt>"<bdt
                  class="statement-end-if-in-editor"
                ></bdt></span
              ><a data-custom-class="link" href="#prohibited"
                ><span style="color: rgb(0, 58, 250); font-size: 15px"
                  >PROHIBITED ACTIVITIES</span
                ></a
              ><span style="font-size: 15px"
                ><bdt class="block-component"></bdt>"<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                and will not post, send, publish, upload, or transmit through
                the Services any Submission<bdt class="block-component"></bdt>
                nor post any Contribution<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                that is illegal, harassing, hateful, harmful, defamatory,
                obscene, bullying, abusive, discriminatory, threatening to any
                person or group, sexually explicit, false, inaccurate,
                deceitful, or misleading;</span
              >
            </li>
            <li
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span style="font-size: 15px"
                >to the extent permissible by applicable law, waive any and all
                moral rights to any such Submission<bdt
                  class="block-component"
                ></bdt>
                and/or Contribution<bdt class="statement-end-if-in-editor"></bdt
                >;</span
              >
            </li>
            <li
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span style="font-size: 15px"
                >warrant that any such Submission<bdt
                  class="block-component"
                ></bdt>
                and/or Contributions<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                are original to you or that you have the necessary rights and
                <bdt class="block-component"></bdt>licenses<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                to submit such Submissions<bdt class="block-component"></bdt>
                and/or Contributions<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                and that you have full authority to grant us the above-mentioned
                rights in relation to your Submissions<bdt
                  class="block-component"
                ></bdt>
                and/or Contributions<bdt
                  class="statement-end-if-in-editor"
                ></bdt
                >; and</span
              >
            </li>
            <li
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span style="font-size: 15px"
                >warrant and represent that your Submissions<bdt
                  class="block-component"
                ></bdt>
                and/or Contributions<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                do not constitute confidential information.</span
              >
            </li>
          </ul>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            You are solely responsible for your Submissions<bdt
              class="block-component"
            ></bdt>
            and/or Contributions<bdt class="statement-end-if-in-editor"></bdt>
            and you expressly agree to reimburse us for any and all losses that
            we may suffer because of your breach of (a) this section, (b) any
            third party’s intellectual property rights, or (c) applicable
            law.<bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <strong>We may remove or edit your Content:</strong> Although we
            have no obligation to monitor any Contributions, we shall have the
            right to remove or edit any Contributions at any time without notice
            if in our reasonable opinion we consider such Contributions harmful
            or in breach of these Legal Terms. If we remove or edit any such
            Contributions, we may also suspend or disable your account and
            report you to the authorities.<bdt
              class="statement-end-if-in-editor"
            ></bdt
            ><bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <strong>Copyright infringement</strong>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Services
            infringes upon any copyright you own or control, please immediately
            refer to the <bdt class="block-component"></bdt>"<bdt
              class="statement-end-if-in-editor"
            ></bdt
            ><a data-custom-class="link" href="#dmca"
              ><span style="color: rgb(0, 58, 250)"
                ><bdt class="block-component"></bdt
                ><bdt class="block-component"></bdt></span
              ><bdt class="statement-end-if-in-editor"></bdt></a
            ><bdt class="block-component"></bdt
            ><a data-custom-class="link" href="#copyrightyes"
              ><span style="color: rgb(0, 58, 250)"
                ><bdt class="block-component"></bdt>COPYRIGHT INFRINGEMENTS<bdt
                  class="statement-end-if-in-editor"
                ></bdt></span></a
            ><bdt class="block-component"></bdt
            ><bdt class="block-component"></bdt>"<bdt
              class="statement-end-if-in-editor"
            ></bdt>
            section below.<bdt class="statement-end-if-in-editor"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
        </div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="userreps"
            style="line-height: 1.5"
          >
            <a name="_5hg7kgyv9l8z"></a
            ><strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >3.</span
                          ></strong
                        ></span
                      >&nbsp;</strong
                    ></span
                  ></strong
                >USER REPRESENTATIONS</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="line-height: 1.5"><br /></div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >By using the Services, you represent and warrant that:</span
            ><bdt
              class="block-container if"
              data-type="if"
              id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="user_account_option"
                  data-type="statement"
                ></bdt>
                <bdt data-type="body"
                  ><span style="color: rgb(89, 89, 89); font-size: 11pt">(</span
                  ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                    >1</span
                  ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                    >) all registration information you submit will be true,
                    accurate, current, and complete; (</span
                  ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                    >2</span
                  ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                    >) you will maintain the accuracy of such information and
                    promptly update such registration information as
                    necessary;</span
                  ></bdt
                ></bdt
              ><bdt class="statement-end-if-in-editor" data-type="close"></bdt
              >&nbsp;</bdt
            ><span style="color: rgb(89, 89, 89); font-size: 11pt">(</span
            ><span style="color: rgb(89, 89, 89); font-size: 14.6667px">3</span
            ><span style="color: rgb(89, 89, 89); font-size: 11pt"
              >) you have the legal capacity and you agree to comply with these
              Legal Terms;</span
            ><bdt
              class="block-container if"
              data-type="if"
              id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="user_u13_option"
                  data-type="statement"
                ></bdt
                >&nbsp;</bdt
              ><span style="color: rgb(89, 89, 89); font-size: 11pt">(</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >4</span
              ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                >) you are not a minor in the jurisdiction in which you
                reside<bdt
                  class="block-container if"
                  data-type="if"
                  id="76948fab-ec9e-266a-bb91-948929c050c9"
                  ><bdt data-type="conditional-block"
                    ><bdt
                      class="block-component"
                      data-record-question-key="user_o18_option"
                      data-type="statement"
                    ></bdt></bdt
                  >; (</bdt
                ></span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >5</span
              ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                >) you will not access the Services through automated or
                non-human means, whether through a bot, script or otherwise;
                (</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >6</span
              ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                >) you will not use the Services for any illegal or
                <bdt class="block-component"></bdt>unauthorized<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                purpose; and (</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >7</span
              ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                >) your use of the Services will not violate any applicable law
                or regulation.</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"></span
            ></bdt>
          </div>
        </div>
        <div align="center" style="line-height: 1.5"><br /></div>
        <div align="center" style="text-align: left">
          <div class="MsoNormal" style="text-align: justify; line-height: 115%">
            <div class="MsoNormal" style="line-height: 17.25px">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; text-align: left"
              >
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >If you provide any information that is untrue, inaccurate,
                  not current, or incomplete, we have the right to suspend or
                  terminate your account and refuse any and all current or
                  future use of the Services (or any portion thereof).</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.1; text-align: left">
                <bdt class="block-component"></bdt>
              </div>
              <div class="MsoNormal" style="line-height: 1.5; text-align: left">
                <br />
              </div>
            </div>
            <div class="MsoNormal" style="line-height: 1">
              <bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    data-custom-class="heading_1"
                    id="userreg"
                    style="line-height: 1.5; text-align: left"
                  >
                    <strong
                      ><span style="line-height: 24.5333px; font-size: 19px"
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            ><strong
                              ><span
                                style="
                                  line-height: 115%;
                                  font-family: Arial;
                                  font-size: 19px;
                                "
                                ><strong
                                  ><span
                                    style="
                                      line-height: 115%;
                                      font-family: Arial;
                                      font-size: 19px;
                                    "
                                    >4.</span
                                  ></strong
                                ></span
                              >&nbsp;</strong
                            ></span
                          ></strong
                        >USER REGISTRATION</span
                      ></strong
                    >
                  </div></bdt
                ></bdt
              >
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left">
              <br />
            </div>
            <div class="MsoNormal" style="line-height: 1">
              <bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="text-align: left; line-height: 1.5"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >You may be required to register to use the Services. You
                      agree to keep your password confidential and will be
                      responsible for all use of your account and password. We
                      reserve the right to remove, reclaim, or change a username
                      you select if we determine, in our sole discretion, that
                      such username is inappropriate, obscene, or otherwise
                      objectionable.<bdt
                        class="statement-end-if-in-editor"
                        data-type="close"
                      ></bdt
                    ></span></div></bdt
              ></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left">
              <bdt class="block-component"
                ><span style="font-size: 15px"></span
              ></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left">
              <bdt class="block-component"
                ><span style="font-size: 15px"></span
              ></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left">
              <br />
            </div>
          </div>
        </div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="purchases"
            style="line-height: 1.5"
          >
            <a name="_ynub0jdx8pob"></a
            ><strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >5.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >PURCHASES AND PAYMENT</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="line-height: 1.5"><br /></div>
        <div align="center" style="text-align: left">
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >We accept the following forms of payment:</span
            >
          </div>
          <div class="MsoNormal" style="text-align: justify; line-height: 115%">
            <div class="MsoNormal" style="text-align: left; line-height: 1">
              <br />
            </div>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; margin-left: 20px"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><bdt class="forloop-component"></bdt>- &nbsp;<bdt
                class="question"
                >Visa</bdt
              ></span
            >
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; margin-left: 20px"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><bdt class="forloop-component"></bdt>- &nbsp;<bdt
                class="question"
                >Mastercard</bdt
              ></span
            >
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; margin-left: 20px"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><bdt class="forloop-component"></bdt>- &nbsp;<bdt
                class="question"
                >American Express</bdt
              ></span
            >
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; margin-left: 20px"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><bdt class="forloop-component"></bdt>- &nbsp;<bdt
                class="question"
                >Bank transfer</bdt
              ></span
            >
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; margin-left: 20px"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><bdt class="forloop-component"></bdt>- &nbsp;<bdt
                class="question"
                >Discover</bdt
              ></span
            >
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; margin-left: 20px"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><bdt class="forloop-component"></bdt>- &nbsp;<bdt
                class="question"
                >Different methods are available depending on the user's
                location. Payments are processed by Stripe</bdt
              ></span
            >
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; margin-left: 20px"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><bdt class="forloop-component"></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1">
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><br
            /></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >You agree to provide current, complete, and accurate purchase and
              account information for all purchases made via the Services. You
              further agree to promptly update account and payment information,
              including email address, payment method, and payment card
              expiration date, so that we can complete your transactions and
              contact you as needed. Sales tax will be added to the price of
              purchases as deemed required by us. We may change prices at any
              time. All payments shall be&nbsp;</span
            ><span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: rgb(89, 89, 89);
              "
              >in <bdt class="question">US dollars</bdt>.</span
            >
          </div>
        </div>
        <div align="center" style="line-height: 1.5"><br /></div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >You agree to pay all charges at the prices then in effect for
              your purchases and any applicable shipping fees, and you
              <bdt class="block-component"></bdt>authorize<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              us to charge your chosen payment provider for any such amounts
              upon placing your order. We reserve the right to correct any
              errors or mistakes in pricing, even if we have already requested
              or received payment.</span
            >
          </div>
        </div>
        <div align="center" style="line-height: 1.5"><br /></div>
        <div align="center" style="text-align: left">
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >We reserve the right to refuse any order placed through the
              Services. We may, in our sole discretion, limit or cancel
              quantities purchased per person, per household, or per order.
              These restrictions may include orders placed by or under the same
              customer account, the same payment method, and/or orders that use
              the same billing or shipping address. We reserve the right to
              limit or prohibit orders that, in our sole
              <bdt class="block-component"></bdt>judgment<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >, appear to be placed by dealers, resellers, or
              distributors.</span
            ><span
              style="
                line-height: 115%;
                font-family: Arial;
                color: rgb(89, 89, 89);
              "
              ><bdt
                data-type="conditional-block"
                style="color: rgb(10, 54, 90); text-align: left"
                ><bdt
                  class="block-component"
                  data-record-question-key="return_option"
                  data-type="statement"
                  style="font-size: 15px"
                ></bdt></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="subscriptions"
            style="line-height: 1.5"
          >
            <strong
              ><span style="font-size: 19px">6. SUBSCRIPTIONS</span></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <strong
              ><span style="font-size: 15px">Billing and Renewal</span></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"><br /></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
          Your subscription will continue and automatically renew unless canceled. You consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable order. The length of your billing cycle will depend on the type of subscription plan you choose when you subscribed to the Services.</span>
          </div>

         
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"><br /></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"><strong>Free Trial</strong></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"><br /></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
          We offer a 30-day free trial to new users who register with the Services. The account will not be charged and the subscription will be suspended until upgraded to a paid version at the end of the free trial.</span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><bdt class="statement-end-if-in-editor"></bdt></span
            ><bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"><br /></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <span style="font-size: 15px"><strong>Cancellation</strong></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <bdt class="block-component"
              ><span style="font-size: 15px"></span></bdt
            >All purchases are non-refundable. You can cancel your subscription at any time by logging into your account. Your cancellation will take effect at the end of the current paid term. If you have any questions or are unsatisfied with our Services, please email us at hello@paypipe.io.
</span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <strong><span style="font-size: 15px">Fee Changes</span></strong>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"><br /></span>
          </div>
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              >We may, from time to time, make changes to the subscription fee
              and will communicate any price changes to you in accordance with
              applicable law.</span
            ></span
          >
          <div class="MsoNormal" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><bdt class="statement-end-if-in-editor"></bdt></span
            ><bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt class="block-component"></bdt>
          </div>
          <div class="MsoNormal" style="text-align: justify; line-height: 1.5">
            <span
              style="
                line-height: 115%;
                font-family: Arial;
                color: rgb(89, 89, 89);
              "
              ><bdt
                data-type="conditional-block"
                style="color: rgb(10, 54, 90); text-align: left"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    style="font-size: 15px; line-height: 1.5"
                  >
                    <br /></div></bdt></bdt
            ></span>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="prohibited"
              style="text-align: left; line-height: 1.5"
            >
              <strong
                ><span style="line-height: 24.5333px; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >7.</span
                            ></strong
                          ></span
                        ></strong
                      ></span
                    >&nbsp;</strong
                  >PROHIBITED ACTIVITIES</span
                ></strong
              >
            </div>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="text-align: justify; line-height: 1">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5; text-align: left"
            >
              <span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >You may not access or use the Services for any purpose other
                than that for which we make the Services available. The Services
                may not be used in connection with any commercial
                <bdt class="block-component"></bdt>endeavors<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                except those that are specifically endorsed or approved by
                us.</span
              >
            </div>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="text-align: justify; line-height: 1">
            <div class="MsoNormal" style="line-height: 17.25px">
              <div class="MsoNormal" style="line-height: 1.1">
                <div class="MsoNormal" style="line-height: 17.25px">
                  <div
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >As a user of the Services, you agree not to:</span
                    >
                  </div>
                </div>
                <ul>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        style="
                          font-family: sans-serif;
                          font-size: 15px;
                          font-style: normal;
                          font-variant-ligatures: normal;
                          font-variant-caps: normal;
                          font-weight: 400;
                          letter-spacing: normal;
                          orphans: 2;
                          text-align: justify;
                          text-indent: -29.4px;
                          text-transform: none;
                          white-space: normal;
                          widows: 2;
                          word-spacing: 0px;
                          -webkit-text-stroke-width: 0px;
                          background-color: rgb(255, 255, 255);
                          text-decoration-style: initial;
                          text-decoration-color: initial;
                          color: rgb(89, 89, 89);
                        "
                        >Systematically retrieve data or other content from the
                        Services to create or compile, directly or indirectly, a
                        collection, compilation, database, or directory without
                        written permission from us.</span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Trick, defraud, or mislead us and other users,
                              especially in any attempt to learn sensitive
                              account information such as user passwords.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Circumvent, disable, or otherwise interfere with
                              security-related features of the Services,
                              including features that prevent or restrict the
                              use or copying of any Content or enforce
                              limitations on the use of the Services and/or the
                              Content contained therein.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Disparage, tarnish, or otherwise harm, in our
                              opinion, us and/or the Services.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Use any information obtained from the Services in
                              order to harass, abuse, or harm another
                              person.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Make improper use of our support services or
                              submit false reports of abuse or misconduct.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Use the Services in a manner inconsistent with
                              any applicable laws or regulations.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Engage in
                              <bdt class="block-component"></bdt
                              >unauthorized<bdt
                                class="statement-end-if-in-editor"
                              ></bdt>
                              framing of or linking to the Services.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Upload or transmit (or attempt to upload or to
                              transmit) viruses, Trojan horses, or other
                              material, including excessive use of capital
                              letters and spamming (continuous posting of
                              repetitive text), that interferes with any party’s
                              uninterrupted use and enjoyment of the Services or
                              modifies, impairs, disrupts, alters, or interferes
                              with the use, features, functions, operation, or
                              maintenance of the Services.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Engage in any automated use of the system, such
                              as using scripts to send comments or messages, or
                              using any data mining, robots, or similar data
                              gathering and extraction tools.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Delete the copyright or other proprietary rights
                              notice from any Content.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Attempt to impersonate another user or person or
                              use the username of another user.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Upload or transmit (or attempt to upload or to
                              transmit) any material that acts as a passive or
                              active information collection or transmission
                              mechanism, including without limitation, clear
                              graphics interchange formats (<bdt
                                class="block-component"
                              ></bdt
                              >"gifs"<bdt
                                class="statement-end-if-in-editor"
                              ></bdt
                              >), 1×1 pixels, web bugs, cookies, or other
                              similar devices (sometimes referred to as
                              <bdt class="block-component"></bdt>"spyware" or
                              "passive collection mechanisms" or "pcms"<bdt
                                class="statement-end-if-in-editor"
                              ></bdt
                              >).</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Interfere with, disrupt, or create an undue
                              burden on the Services or the networks or services
                              connected to the Services.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Harass, annoy, intimidate, or threaten any of our
                              employees or agents engaged in providing any
                              portion of the Services to you.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Attempt to bypass any measures of the Services
                              designed to prevent or restrict access to the
                              Services, or any portion of the Services.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Copy or adapt the Services' software, including
                              but not limited to Flash, PHP, HTML, JavaScript,
                              or other code.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Except as permitted by applicable law, decipher,
                              decompile, disassemble, or reverse engineer any of
                              the software comprising or in any way making up a
                              part of the Services.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Except as may be the result of standard search
                              engine or Internet browser usage, use, launch,
                              develop, or distribute any automated system,
                              including without limitation, any spider, robot,
                              cheat utility, scraper, or offline reader that
                              accesses the Services, or use or launch any
                              <bdt class="block-component"></bdt
                              >unauthorized<bdt
                                class="statement-end-if-in-editor"
                              ></bdt>
                              script or other software.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Use a buying agent or purchasing agent to make
                              purchases on the Services.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Make any
                              <bdt class="block-component"></bdt
                              >unauthorized<bdt
                                class="statement-end-if-in-editor"
                              ></bdt>
                              use of the Services, including collecting
                              usernames and/or email addresses of users by
                              electronic or other means for the purpose of
                              sending unsolicited email, or creating user
                              accounts by automated means or under false
                              <bdt class="block-component"></bdt>pretenses<bdt
                                class="statement-end-if-in-editor"
                              ></bdt
                              >.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Use the Services as part of any effort to compete
                              with us or otherwise use the Services and/or the
                              Content for any revenue-generating
                              <bdt class="block-component"></bdt>endeavor<bdt
                                class="statement-end-if-in-editor"
                              ></bdt>
                              or commercial enterprise.</span
                            ><span
                              style="
                                font-size: 11pt;
                                line-height: 16.8667px;
                                color: rgb(89, 89, 89);
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              ><bdt
                                class="forloop-component"
                              ></bdt></span></span></span></span
                    ></span>
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      ><bdt class="question"
                        >Sell or otherwise transfer your profile.</bdt
                      ><bdt class="forloop-component"></bdt
                    ></span>
                  </li>
                </ul>
              </div>
              <div class="MsoNormal" style="line-height: 1.5; text-align: left">
                <br />
              </div>
            </div>
            <div class="MsoNormal" style="line-height: 17.25px">
              <div class="MsoNormal" style="line-height: 1">
                <bdt
                  class="block-container if"
                  data-type="if"
                  style="text-align: left"
                  ><bdt data-type="conditional-block"
                    ><bdt data-type="body"
                      ><div
                        class="MsoNormal"
                        data-custom-class="heading_1"
                        id="ugc"
                        style="line-height: 1.5"
                      >
                        <strong
                          ><span style="line-height: 24.5333px; font-size: 19px"
                            ><strong
                              ><span
                                style="line-height: 24.5333px; font-size: 19px"
                                ><strong
                                  ><span
                                    style="
                                      line-height: 115%;
                                      font-family: Arial;
                                      font-size: 19px;
                                    "
                                    ><strong
                                      ><span
                                        style="
                                          line-height: 115%;
                                          font-family: Arial;
                                          font-size: 19px;
                                        "
                                        >8.</span
                                      ></strong
                                    ></span
                                  ></strong
                                ></span
                              >&nbsp;</strong
                            >USER GENERATED CONTRIBUTIONS</span
                          ></strong
                        >
                      </div></bdt
                    ></bdt
                  ></bdt
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5; text-align: left">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1">
                <bdt
                  class="block-container if"
                  data-type="if"
                  style="text-align: left"
                  ><bdt data-type="conditional-block"
                    ><bdt data-type="body"
                      ><div
                        class="MsoNormal"
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span
                          style="
                            font-size: 11pt;
                            line-height: 16.8667px;
                            color: rgb(89, 89, 89);
                          "
                          ><bdt
                            class="block-container if"
                            data-type="if"
                            id="24327c5d-a34f-f7e7-88f1-65a2f788484f"
                            style="text-align: left"
                            ><bdt data-type="conditional-block"
                              ><bdt
                                class="block-component"
                                data-record-question-key="user_post_content_option"
                                data-type="statement"
                              ></bdt></bdt></bdt
                          >The Services may invite you to chat, contribute to,
                          or participate in blogs, message boards, online
                          forums, and other functionality, and may provide you
                          with the opportunity to create, submit, post, display,
                          transmit, perform, publish, distribute, or broadcast
                          content and materials to us or on the Services,
                          including but not limited to text, writings, video,
                          audio, photographs, graphics, comments, suggestions,
                          or personal information or other material
                          (collectively,
                          <bdt class="block-component"></bdt>"Contributions"<bdt
                            class="statement-end-if-in-editor"
                          ></bdt
                          >). Contributions may be viewable by other users of
                          the Services and through third-party websites. As
                          such, any Contributions you transmit may be treated as
                          non-confidential and non-proprietary. When you create
                          or make available any Contributions, you thereby
                          represent and warrant that:<bdt class="else-block"
                            ><bdt class="block-component"></bdt></bdt
                        ></span>
                      </div>
                      &nbsp;</bdt
                    >&nbsp;</bdt
                  >&nbsp;</bdt
                >
              </div>
              <ul style="font-size: medium; text-align: left">
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >The creation, distribution, transmission, public
                        display, or performance, and the accessing, downloading,
                        or copying of your Contributions do not and will not
                        infringe the proprietary rights, including but not
                        limited to the copyright, patent, trademark, trade
                        secret, or moral rights of any third party.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >You are the creator and owner of or have the necessary
                        <bdt class="block-component"></bdt>licenses<bdt
                          class="statement-end-if-in-editor"
                        ></bdt
                        >, rights, consents, releases, and permissions to use
                        and to <bdt class="block-component"></bdt>authorize<bdt
                          class="statement-end-if-in-editor"
                        ></bdt>
                        us, the Services, and other users of the Services to use
                        your Contributions in any manner contemplated by the
                        Services and these Legal Terms.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >You have the written consent, release, and/or
                        permission of each and every identifiable individual
                        person in your Contributions to use the name or likeness
                        of each and every such identifiable individual person to
                        enable inclusion and use of your Contributions in any
                        manner contemplated by the Services and these Legal
                        Terms.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions are not false, inaccurate, or
                        misleading.</span
                      ></span
                    >&nbsp;</span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions are not unsolicited or
                        <bdt class="block-component"></bdt>unauthorized<bdt
                          class="statement-end-if-in-editor"
                        ></bdt>
                        advertising, promotional materials, pyramid schemes,
                        chain letters, spam, mass mailings, or other forms of
                        solicitation.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions are not obscene, lewd, lascivious,
                        filthy, violent, harassing,
                        <bdt class="block-component"></bdt>libelous<bdt
                          class="statement-end-if-in-editor"
                        ></bdt
                        >, slanderous, or otherwise objectionable (as determined
                        by us).</span
                      ></span
                    >&nbsp;</span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions do not ridicule, mock, disparage,
                        intimidate, or abuse anyone.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions are not used to harass or threaten
                        (in the legal sense of those terms) any other person and
                        to promote violence against a specific person or class
                        of people.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions do not violate any applicable law,
                        regulation, or rule.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions do not violate the privacy or
                        publicity rights of any third party.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions do not violate any applicable law
                        concerning child pornography, or otherwise intended to
                        protect the health or well-being of minors.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions do not include any offensive
                        comments that are connected to race, national origin,
                        gender, sexual preference, or physical handicap.</span
                      ></span
                    ></span
                  >
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px"
                      ><span data-custom-class="body_text"
                        >Your Contributions do not otherwise violate, or link to
                        material that violates, any provision of these Legal
                        Terms, or any applicable law or regulation.</span
                      ></span
                    ></span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="MsoNormal" style="text-align: justify; line-height: 1.5">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >Any use of the Services in violation of the foregoing
                      violates these Legal Terms and may result in, among other
                      things, termination or suspension of your rights to use
                      the Services.</span
                    >
                  </div></bdt
                ></bdt
              ></bdt
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="text-align: justify; line-height: 1">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    data-custom-class="heading_1"
                    id="license"
                    style="line-height: 1.5"
                  >
                    <strong
                      ><span style="line-height: 24.5333px; font-size: 19px"
                        ><strong
                          ><span style="line-height: 24.5333px; font-size: 19px"
                            ><strong
                              ><span
                                style="
                                  line-height: 115%;
                                  font-family: Arial;
                                  font-size: 19px;
                                "
                                ><strong
                                  ><span
                                    style="
                                      line-height: 115%;
                                      font-family: Arial;
                                      font-size: 19px;
                                    "
                                    >9.</span
                                  ></strong
                                ></span
                              ></strong
                            ></span
                          >&nbsp;</strong
                        >CONTRIBUTION
                        <bdt class="block-component"></bdt>LICENSE<bdt
                          class="statement-end-if-in-editor"
                        ></bdt></span
                    ></strong></div></bdt></bdt
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1">
            <bdt
              class="block-container if"
              data-type="if"
              id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="user_post_content_option"
                  data-type="statement"
                ></bdt></bdt></bdt
            ><bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >By posting your Contributions to any part of the
                      Services<bdt
                        class="block-container if"
                        data-type="if"
                        id="19652acc-9a2a-5ffe-6189-9474402fa6cc"
                        ><bdt data-type="conditional-block"
                          ><bdt
                            class="block-component"
                            data-record-question-key="socialnetwork_link_option"
                            data-type="statement"
                          ></bdt
                          ><bdt data-type="body"
                            >&nbsp;or making Contributions accessible to the
                            Services by linking your account from the Services
                            to any of your social networking accounts</bdt
                          ></bdt
                        ><bdt
                          class="statement-end-if-in-editor"
                          data-type="close"
                        ></bdt></bdt
                      >, you automatically grant, and you represent and warrant
                      that you have the right to grant, to us an unrestricted,
                      unlimited, irrevocable, perpetual, non-exclusive,
                      transferable, royalty-free, fully-paid, worldwide right,
                      and <bdt class="block-component"></bdt>license<bdt
                        class="statement-end-if-in-editor"
                      ></bdt>
                      to host, use, copy, reproduce, disclose, sell, resell,
                      publish, broadcast, retitle, archive, store, cache,
                      publicly perform, publicly display, reformat, translate,
                      transmit, excerpt (in whole or in part), and distribute
                      such Contributions (including, without limitation, your
                      image and voice) for any purpose, commercial, advertising,
                      or otherwise, and to prepare derivative works of, or
                      incorporate into other works, such Contributions, and
                      grant and <bdt class="block-component"></bdt>authorize
                      sublicenses<bdt class="statement-end-if-in-editor"></bdt>
                      of the foregoing. The use and distribution may occur in
                      any media formats and through any media channels.</span
                    >
                  </div></bdt
                ></bdt
              ></bdt
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="text-align: justify; line-height: 1">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >This <bdt class="block-component"></bdt>license<bdt
                        class="statement-end-if-in-editor"
                      ></bdt>
                      will apply to any form, media, or technology now known or
                      hereafter developed, and includes our use of your name,
                      company name, and franchise name, as applicable, and any
                      of the trademarks, service marks, trade names, logos, and
                      personal and commercial images you provide. You waive all
                      moral rights in your Contributions, and you warrant that
                      moral rights have not otherwise been asserted in your
                      Contributions.</span
                    >
                  </div></bdt
                ></bdt
              ></bdt
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="text-align: justify; line-height: 1">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >We do not assert any ownership over your Contributions.
                      You retain full ownership of all of your Contributions and
                      any intellectual property rights or other proprietary
                      rights associated with your Contributions. We are not
                      liable for any statements or representations in your
                      Contributions provided by you in any area on the Services.
                      You are solely responsible for your Contributions to the
                      Services and you expressly agree to exonerate us from any
                      and all responsibility and to refrain from any legal
                      action against us regarding your Contributions.</span
                    >
                  </div></bdt
                ></bdt
              ></bdt
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="text-align: justify; line-height: 1">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >We have the right, in our sole and absolute discretion,
                      (1) to edit, redact, or otherwise change any
                      Contributions; (2) to
                      <bdt class="block-component"></bdt>re-categorize<bdt
                        class="statement-end-if-in-editor"
                      ></bdt>
                      any Contributions to place them in more appropriate
                      locations on the Services; and (3) to pre-screen or delete
                      any Contributions at any time and for any reason, without
                      notice. We have no obligation to monitor your
                      Contributions.</span
                    >
                  </div></bdt
                ></bdt
              ></bdt
            >
          </div>
        </div>
        <div align="center" style="text-align: left">
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    ><bdt class="else-block"></bdt></span></bdt></bdt
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="review_option"
                  data-type="statement"
                ></bdt></bdt
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="reviews"
            style="line-height: 1.5"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >10.</span
                      ></strong
                    ></span
                  ></strong
                >&nbsp;</span
              >GUIDELINES FOR REVIEWS</strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >We may provide you areas on the Services to leave reviews or
              ratings. When posting a review, you must comply with the following
              criteria: (1) you should have firsthand experience with the
              person/entity being reviewed; (2) your reviews should not contain
              offensive profanity, or abusive, racist, offensive, or hateful
              language; (3) your reviews should not contain discriminatory
              references based on religion, race, gender, national origin, age,
              marital status, sexual orientation, or disability; (4) your
              reviews should not contain references to illegal activity; (5) you
              should not be affiliated with competitors if posting negative
              reviews; (6) you should not make any conclusions as to the
              legality of conduct; (7) you may not post any false or misleading
              statements; and (8) you may not
              <bdt class="block-component"></bdt>organize<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              a campaign encouraging others to post reviews, whether positive or
              negative.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >We may accept, reject, or remove reviews in our sole
                discretion. We have absolutely no obligation to screen reviews
                or to delete reviews, even if anyone considers reviews
                objectionable or inaccurate. Reviews are not endorsed by us, and
                do not necessarily represent our opinions or the views of any of
                our affiliates or partners. We do not assume liability for any
                review or for any claims, liabilities, or losses resulting from
                any review. By posting a review, you hereby grant to us a
                perpetual, non-exclusive, worldwide, royalty-free, fully paid,
                assignable, and sublicensable right and
                <bdt class="block-component"></bdt>license<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                to reproduce, modify, translate, transmit by any means, display,
                perform, and/or distribute all content relating to review.</span
              ></span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  ><bdt
                    class="block-container if"
                    data-type="if"
                    style="text-align: left"
                    ><bdt
                      class="statement-end-if-in-editor"
                      data-type="close"
                    ></bdt></bdt></span></span
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="mobile_app_option"
                  data-type="statement"
                ></bdt></bdt
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="mobile"
            style="line-height: 1.5"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >11.</span
                      ></strong
                    ></span
                  ></strong
                >&nbsp;</span
              >MOBILE APPLICATION <bdt class="block-component"></bdt>LICENSE<bdt
                class="statement-end-if-in-editor"
              ></bdt
            ></strong>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <strong
              >Use <bdt class="block-component"></bdt>License<bdt
                class="statement-end-if-in-editor"
              ></bdt
            ></strong>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >If you access the Services via the App, then we grant you a
              revocable, non-exclusive, non-transferable, limited right to
              install and use the App on wireless electronic devices owned or
              controlled by you, and to access and use the App on such devices
              strictly in accordance with the terms and conditions of this
              mobile application <bdt class="block-component"></bdt>license<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              contained in these Legal Terms. You shall not: (1) except as
              permitted by applicable law, decompile, reverse engineer,
              disassemble, attempt to derive the source code of, or decrypt the
              App; (2) make any modification, adaptation, improvement,
              enhancement, translation, or derivative work from the App; (3)
              violate any applicable laws, rules, or regulations in connection
              with your access or use of the App; (4) remove, alter, or obscure
              any proprietary notice (including any notice of copyright or
              trademark) posted by us or the licensors of the App; (5) use the
              App for any revenue-generating
              <bdt class="block-component"></bdt>endeavor<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >, commercial enterprise, or other purpose for which it is not
              designed or intended; (6) make the App available over a network or
              other environment permitting access or use by multiple devices or
              users at the same time; (7) use the App for creating a product,
              service, or software that is, directly or indirectly, competitive
              with or in any way a substitute for the App; (8) use the App to
              send automated queries to any website or to send any unsolicited
              commercial email; or (9) use any proprietary information or any of
              our interfaces or our other intellectual property in the design,
              development, manufacture, licensing, or distribution of any
              applications, accessories, or devices for use with the App.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              ><strong>Apple and Android Devices</strong></span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >The following terms apply when you use the App obtained from
              either the Apple Store or Google Play (each an
              <bdt class="block-component"></bdt>"App Distributor"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >) to access the Services: (1) the
              <bdt class="block-component"></bdt>license<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              granted to you for our App is limited to a non-transferable
              <bdt class="block-component"></bdt>license<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              to use the application on a device that
              <bdt class="block-component"></bdt>utilizes<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              the Apple iOS or Android operating systems, as applicable, and in
              accordance with the usage rules set forth in the applicable App
              Distributor’s terms of service; (2) we are responsible for
              providing any maintenance and support services with respect to the
              App as specified in the terms and conditions of this mobile
              application <bdt class="block-component"></bdt>license<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              contained in these Legal Terms or as otherwise required under
              applicable law, and you acknowledge that each App Distributor has
              no obligation whatsoever to furnish any maintenance and support
              services with respect to the App; (3) in the event of any failure
              of the App to conform to any applicable warranty, you may notify
              the applicable App Distributor, and the App Distributor, in
              accordance with its terms and policies, may refund the purchase
              price, if any, paid for the App, and to the maximum extent
              permitted by applicable law, the App Distributor will have no
              other warranty obligation whatsoever with respect to the App; (4)
              you represent and warrant that (i) you are not located in a
              country that is subject to a US government embargo, or that has
              been designated by the US government as a
              <bdt class="block-component"></bdt>"terrorist supporting"<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              country and (ii) you are not listed on any US government list of
              prohibited or restricted parties; (5) you must comply with
              applicable third-party terms of agreement when using the App,
              e.g.<bdt class="block-component"></bdt>,<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              if you have a VoIP application, then you must not be in violation
              of their wireless data service agreement when using the App; and
              (6) you acknowledge and agree that the App Distributors are
              third-party beneficiaries of the terms and conditions in this
              mobile application <bdt class="block-component"></bdt>license<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              contained in these Legal Terms, and that each App Distributor will
              have the right (and will be deemed to have accepted the right) to
              enforce the terms and conditions in this mobile application
              <bdt class="block-component"></bdt>license<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              contained in these Legal Terms against you as a third-party
              beneficiary thereof.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              ><span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  ><bdt
                    class="block-container if"
                    data-type="if"
                    style="text-align: left"
                    ><bdt
                      class="statement-end-if-in-editor"
                      data-type="close"
                    ></bdt></bdt></span></span
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="socialnetwork_link_option"
                  data-type="statement"
                ></bdt></bdt
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="socialmedia"
            style="line-height: 1.5"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >12.</span
                      ></strong
                    ></span
                  ></strong
                >&nbsp;</span
              >SOCIAL MEDIA</strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <br /><bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >As part of the functionality of the Services, you may
                      link your account with online accounts you have with
                      third-party service providers (each such account, a
                      <bdt class="block-component"></bdt>"Third-Party
                      Account"<bdt class="statement-end-if-in-editor"></bdt>) by
                      either: (1) providing your Third-Party Account login
                      information through the Services; or (2) allowing us to
                      access your
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account, as is permitted under the applicable terms and
                      conditions that govern your use of each
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account. You represent and warrant that you are entitled
                      to disclose your
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account login information to us and/or grant us access to
                      your
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account, without breach by you of any of the terms and
                      conditions that govern your use of the applicable
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account, and without obligating us to pay any fees or
                      making us subject to any usage limitations imposed by the
                      third-party service provider of the
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account. By granting us access to any
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Accounts, you understand that (1) we may access, make
                      available, and store (if applicable) any content that you
                      have provided to and stored in your
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account (the <bdt class="block-component"></bdt>"Social
                      Network Content"<bdt
                        class="statement-end-if-in-editor"
                      ></bdt
                      >) so that it is available on and through the Services via
                      your account, including without limitation any friend
                      lists and (2) we may submit to and receive from your
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account additional information to the extent you are
                      notified when you link your account with the
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account. Depending on the
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Accounts you choose and subject to the privacy settings
                      that you have set in such
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Accounts, personally identifiable information that you
                      post to your
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Accounts may be available on and through your account on
                      the Services. Please note that if a
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account or associated service becomes unavailable or our
                      access to such
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account is terminated by the third-party service provider,
                      then Social Network Content may no longer be available on
                      and through the Services. You will have the ability to
                      disable the connection between your account on the
                      Services and your
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP
                      WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH
                      YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
                      AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We
                      make no effort to review any Social Network Content for
                      any purpose, including but not limited to, for accuracy,
                      legality, or non-infringement, and we are not responsible
                      for any Social Network Content. You acknowledge and agree
                      that we may access your email address book associated with
                      a
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account and your contacts list stored on your mobile
                      device or tablet computer solely for purposes of
                      identifying and informing you of those contacts who have
                      also registered to use the Services. You can deactivate
                      the connection between the Services and your
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account by contacting us using the contact information
                      below or through your account settings (if applicable). We
                      will attempt to delete any information stored on our
                      servers that was obtained through such
                      <span style="font-size: 14.6667px">Third-Party</span>
                      Account, except the username and profile picture that
                      become associated with your account.</span
                    >
                  </div></bdt
                ></bdt
              ></bdt
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.1">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt data-type="body"
                  ><div class="MsoNormal" style="line-height: 1.5">
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                    ></span></div></bdt></bdt
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="3rd_party_option"
                  data-type="statement"
                ></bdt></bdt
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="thirdparty"
            style="line-height: 1.5"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >13.</span
                      ></strong
                    ></span
                  ></strong
                >&nbsp;</span
              >THIRD-PARTY WEBSITES AND CONTENT</strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >The Services may contain (or you may be sent via the
              <bdt class="block-component"></bdt>Site or App<bdt
                class="block-component"
              ></bdt
              >) links to other websites (<bdt class="block-component"></bdt
              >"Third-Party Websites"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >) as well as articles, photographs, text, graphics, pictures,
              designs, music, sound, video, information, applications, software,
              and other content or items belonging to or originating from third
              parties (<bdt class="block-component"></bdt>"Third-Party
              Content"<bdt class="statement-end-if-in-editor"></bdt>). Such
              <span style="font-size: 14.6667px">Third-Party</span> Websites and
              <span style="font-size: 14.6667px">Third-Party</span> Content are
              not investigated, monitored, or checked for accuracy,
              appropriateness, or completeness by us, and we are not responsible
              for any Third-Party Websites accessed through the Services or any
              <span style="font-size: 14.6667px">Third-Party</span> Content
              posted on, available through, or installed from the Services,
              including the content, accuracy, offensiveness, opinions,
              reliability, privacy practices, or other policies of or contained
              in the
              <span style="font-size: 14.6667px">Third-Party</span> Websites or
              the <span style="font-size: 14.6667px">Third-Party</span> Content.
              Inclusion of, linking to, or permitting the use or installation of
              any <span style="font-size: 14.6667px">Third-Party</span> Websites
              or any
              <span style="font-size: 14.6667px">Third-Party&nbsp;</span>Content
              does not imply approval or endorsement thereof by us. If you
              decide to leave the Services and access the
              <span style="font-size: 14.6667px">Third-Party</span> Websites or
              to use or install any
              <span style="font-size: 14.6667px">Third-Party</span> Content, you
              do so at your own risk, and you should be aware these Legal Terms
              no longer govern. You should review the applicable terms and
              policies, including privacy and data gathering practices, of any
              website to which you navigate from the Services or relating to any
              applications you use or install from the Services. Any purchases
              you make through
              <span style="font-size: 14.6667px">Third-Party</span> Websites
              will be through other websites and from other companies, and we
              take no responsibility whatsoever in relation to such purchases
              which are exclusively between you and the applicable third party.
              You agree and acknowledge that we do not endorse the products or
              services offered on
              <span style="font-size: 14.6667px">Third-Party</span> Websites and
              you shall hold us blameless from any harm caused by your purchase
              of such products or services. Additionally, you shall hold us
              blameless from any losses sustained by you or harm caused to you
              relating to or resulting in any way from any
              <span style="font-size: 14.6667px">Third-Party</span> Content or
              any contact with
              <span style="font-size: 14.6667px">Third-Party</span>
              Websites.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt class="statement-end-if-in-editor" data-type="close"></bdt
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="advertiser_option"
                  data-type="statement"
                ></bdt></bdt
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="sitemanage"
            style="line-height: 1.5"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >14.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >SERVICES MANAGEMENT</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            We reserve the right, but not the obligation, to: (1) monitor the
            Services for violations of these Legal Terms; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Legal Terms, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Services or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems; and (5) otherwise manage the Services in a manner designed
            to protect our rights and property and to facilitate the proper
            functioning of the Services.
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="privacy_policy_option"
                  data-type="statement"
                ></bdt></bdt
              ><bdt class="block-container if" data-type="if"
                ><bdt data-type="conditional-block"
                  ><bdt
                    class="block-component"
                    data-record-question-key="privacy_policy_followup"
                    data-type="statement"
                    style="font-size: 14.6667px"
                  ></bdt></bdt></bdt
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="ppno"
            style="line-height: 1.5"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >15.</span
                      ></strong
                    ></span
                  ></strong
                >&nbsp;</span
              >PRIVACY POLICY</strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >We care about data privacy and security. By using the Services,
              you agree to be bound by our Privacy Policy posted on the
              Services, which is incorporated into these Legal Terms. Please be
              advised the Services are hosted in
              <span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><bdt class="block-component"></bdt>the
                <bdt class="question">United States</bdt
                ><bdt class="block-component"></bdt></span
              ><bdt class="block-component"></bdt>. If you access the Services
              from any other region of the world with laws or other requirements
              governing personal data collection, use, or disclosure that differ
              from applicable laws in
              <span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><bdt class="block-component"></bdt>the
                <bdt class="question">United States</bdt
                ><bdt class="block-component"></bdt></span
              ><bdt class="block-component"></bdt>, then through your continued
              use of the Services, you are transferring your data to
              <span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><bdt class="block-component"></bdt>the
                <bdt class="question">United States</bdt
                ><bdt class="block-component"></bdt></span
              ><bdt class="block-component"></bdt>, and you expressly consent to
              have your data transferred to and processed in
              <span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><bdt class="block-component"></bdt>the
                <bdt class="question">United States</bdt
                ><bdt class="block-component"></bdt></span
              ><bdt class="block-component"></bdt>.<bdt
                class="block-container if"
                data-type="if"
                id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
                ><bdt data-type="conditional-block"
                  ><span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    ><bdt
                      class="block-container if"
                      data-type="if"
                      id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
                      ><bdt data-type="conditional-block"
                        ><bdt data-type="body"
                          ><span
                            style="
                              font-size: 11pt;
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><bdt class="block-component"></bdt
                            ><bdt
                              class="block-container if"
                              data-type="if"
                              id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
                              ><bdt data-type="conditional-block"
                                ><bdt
                                  class="block-component"
                                  data-record-question-key="user_u13_option"
                                  data-type="statement"
                                  ><span
                                    style="
                                      font-size: 11pt;
                                      line-height: 16.8667px;
                                      color: rgb(89, 89, 89);
                                    "
                                    ><bdt
                                      class="statement-end-if-in-editor"
                                    ></bdt></span></bdt></bdt></bdt></span></bdt></bdt></bdt></span></bdt></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5"><br /></div>
          <div class="MsoNormal" style="line-height: 1.5">
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              ><bdt class="block-container if" data-type="if"
                ><bdt data-type="conditional-block"
                  ><span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    ><bdt class="block-container if" data-type="if"
                      ><bdt data-type="conditional-block"
                        ><bdt data-type="body"
                          ><span
                            style="
                              font-size: 11pt;
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><bdt class="block-container if" data-type="if"
                              ><bdt
                                class="statement-end-if-in-editor"
                                data-type="close"
                                ><span
                                  style="
                                    font-size: 11pt;
                                    line-height: 16.8667px;
                                    color: rgb(89, 89, 89);
                                  "
                                  ><bdt class="statement-end-if-in-editor"
                                    ><span
                                      style="
                                        font-size: 11pt;
                                        line-height: 16.8667px;
                                        color: rgb(89, 89, 89);
                                      "
                                      ><bdt
                                        class="block-container if"
                                        data-type="if"
                                        ><bdt data-type="conditional-block"
                                          ><span
                                            style="
                                              font-size: 11pt;
                                              line-height: 16.8667px;
                                              color: rgb(89, 89, 89);
                                            "
                                            ><bdt
                                              class="block-container if"
                                              data-type="if"
                                              ><bdt
                                                data-type="conditional-block"
                                                ><bdt data-type="body"
                                                  ><span
                                                    style="
                                                      font-size: 11pt;
                                                      line-height: 16.8667px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><bdt
                                                      class="block-container if"
                                                      data-type="if"
                                                      ><bdt
                                                        class="statement-end-if-in-editor"
                                                        data-type="close"
                                                        ><span
                                                          style="
                                                            font-size: 11pt;
                                                            line-height: 16.8667px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><bdt
                                                            class="statement-end-if-in-editor"
                                                          ></bdt></span></bdt></bdt></span></bdt></bdt></bdt></span></bdt></bdt></span></bdt></span></bdt></bdt></span></bdt></bdt></bdt></span></bdt></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt data-type="conditional-block"
                ><bdt
                  class="block-component"
                  data-record-question-key="copyright_agent_option"
                  data-type="statement"
                  ><bdt class="block-component"></bdt
                  ><bdt class="block-component"></bdt></bdt
                ><bdt
                  class="block-container if"
                  data-type="if"
                  style="text-align: left"
                  ><bdt
                    class="statement-end-if-in-editor"
                    data-type="close"
                  ></bdt></bdt></bdt
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <bdt class="block-component"></bdt
            ><bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt class="statement-end-if-in-editor" data-type="close"
                ><bdt class="block-component"></bdt></bdt
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="copyrightyes"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span style="font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >16.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >COPYRIGHT INFRINGEMENTS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >We respect the intellectual property rights of others. If you
              believe that any material available on or through the Services
              infringes upon any copyright you own or control, please
              immediately notify us using the contact information provided below
              (a <bdt class="block-component"></bdt>"Notification"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >). A copy of your Notification will be sent to the person who
              posted or stored the material addressed in the Notification.
              Please be advised that pursuant to applicable law you may be held
              liable for damages if you make material misrepresentations in a
              Notification. Thus, if you are not sure that material located on
              or linked to by the Services infringes your copyright, you should
              consider first contacting an attorney.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <bdt
              class="block-container if"
              data-type="if"
              style="text-align: left"
              ><bdt
                class="statement-end-if-in-editor"
                data-type="close"
              ></bdt></bdt
            ><bdt class="block-component"></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="terms"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >17.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >TERM AND TERMINATION</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >These Legal Terms shall remain in full force and effect while you
              use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
              LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
              SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
              FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
              BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
              THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
              TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE
              <bdt
                class="block-container if"
                data-type="if"
                id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
                ><bdt data-type="conditional-block"
                  ><bdt
                    class="block-component"
                    data-record-question-key="user_account_option"
                    data-type="statement"
                  ></bdt
                  ><bdt data-type="body">YOUR ACCOUNT AND&nbsp;</bdt></bdt
                ><bdt
                  class="statement-end-if-in-editor"
                  data-type="close"
                ></bdt></bdt
              >ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
              WARNING, IN OUR SOLE DISCRETION.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="modifications"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >18.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >MODIFICATIONS AND INTERRUPTIONS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >We reserve the right to change, modify, or remove the contents of
              the Services at any time or for any reason at our sole discretion
              without notice. However, we have no obligation to update any
              information on our Services.<bdt class="block-component"></bdt> We
              will not be liable to you or any third party for any modification,
              price change, suspension, or discontinuance of the Services.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >We cannot guarantee the Services will be available at all times.
              We may experience hardware, software, or other problems or need to
              perform maintenance related to the Services, resulting in
              interruptions, delays, or errors. We reserve the right to change,
              revise, update, suspend, discontinue, or otherwise modify the
              Services at any time or for any reason without notice to you. You
              agree that we have no liability whatsoever for any loss, damage,
              or inconvenience caused by your inability to access or use the
              Services during any downtime or discontinuance of the Services.
              Nothing in these Legal Terms will be construed to obligate us to
              maintain and support the Services or to supply any corrections,
              updates, or releases in connection therewith.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="law"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >19.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >GOVERNING LAW</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              ><bdt class="block-component"></bdt
            ></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="
                font-size: 11pt;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >These Legal Terms shall be governed by and defined following the
              laws of <bdt class="block-component"></bdt
              ><bdt class="question">Australia</bdt
              ><span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><bdt class="statement-end-if-in-editor"></bdt></span
              >. <bdt class="question">PAYPIPE PTY LTD</bdt> and yourself
              irrevocably consent that the courts of
              <span
                style="
                  font-size: 11pt;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><bdt class="block-component"></bdt
                ><bdt class="question">Australia</bdt
                ><span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  ><bdt class="statement-end-if-in-editor"></bdt></span
              ></span>
              shall have exclusive jurisdiction to resolve any dispute which may
              arise in connection with these Legal Terms.<bdt
                class="statement-end-if-in-editor"
              ></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="disputes"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span style="line-height: 24.5333px; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >20.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >DISPUTE RESOLUTION</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <bdt class="block-component"></bdt
            ><bdt class="block-component"></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span style="font-size: 15px"
              >You agree to irrevocably submit all disputes related to these
              Legal Terms or the legal relationship established by these Legal
              Terms to the jurisdiction of the
              <bdt class="block-component"></bdt
              ><bdt class="question">Australia</bdt
              ><bdt class="statement-end-if-in-editor"></bdt> courts.
              <bdt class="question">PAYPIPE PTY LTD</bdt> shall also maintain
              the right to bring proceedings as to the substance of the matter
              in the courts of the country where you reside or, if these Legal
              Terms are entered into in the course of your trade or profession,
              the state of your principal place of business.</span
            ><bdt class="statement-end-if-in-editor"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <bdt class="statement-end-if-in-editor"
              ><bdt class="else-block"></bdt
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="corrections"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span style="font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >21.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >CORRECTIONS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            There may be information on the Services that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Services at any time, without prior
            notice.
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="disclaimer"
            style="line-height: 1.5; text-align: left"
          >
            <span style="font-size: 19px; color: rgb(0, 0, 0)"
              ><strong
                ><span style="line-height: 24.5333px; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >22.</span
                        ></strong
                      ></span
                    ></strong
                  ></span
                >
                DISCLAIMER</strong
              ></span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
              AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
              THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
              EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
              THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
              THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE
              CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE
              SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
              (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
              RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY
              <bdt class="block-component"></bdt>UNAUTHORIZED<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
              INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
              INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,
              (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
              TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
              (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
              ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
              ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
              THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
              RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY
              A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR
              ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
              ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
              RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
              THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
              PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
              ENVIRONMENT, YOU SHOULD USE YOUR BEST
              <bdt class="block-component"></bdt>JUDGMENT<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              AND EXERCISE CAUTION WHERE APPROPRIATE.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="liability"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >23.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >LIMITATIONS OF LIABILITY</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><span data-custom-class="body_text"
                >IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
                LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
                OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE
                HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span
              >
              <bdt
                class="block-container if"
                data-type="if"
                id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                ><span data-custom-class="body_text"
                  ><bdt data-type="conditional-block"
                    ><bdt
                      class="block-component"
                      data-record-question-key="limitations_liability_option"
                      data-type="statement"
                    ></bdt
                    ><bdt data-type="body"
                      >NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                      HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
                      REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
                      LIMITED TO
                      <bdt
                        class="block-container if"
                        data-type="if"
                        id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                        ><bdt data-type="conditional-block"
                          ><bdt
                            class="block-component"
                            data-record-question-key="limitations_liability_option"
                            data-type="statement"
                          ></bdt
                          ><bdt data-type="body"
                            >THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO
                            US<bdt data-type="conditional-block"
                              ><bdt
                                class="block-component"
                                data-record-question-key="limilation_liability_time_option"
                                data-type="statement"
                              ></bdt>
                              <bdt data-type="body"
                                ><span
                                  style="
                                    font-size: 11pt;
                                    color: rgb(89, 89, 89);
                                    text-transform: uppercase;
                                  "
                                  >DURING THE
                                  <bdt
                                    class="block-container question question-in-editor"
                                    data-id="98461079-8520-8e55-edbd-84fdc37a26d4"
                                    data-type="question"
                                    >six (6)</bdt
                                  >
                                  MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
                                  ARISING</span
                                ></bdt
                              ></bdt
                            ><bdt
                              class="statement-end-if-in-editor"
                              data-type="close"
                            ></bdt>
                            OR <bdt class="block-component"></bdt
                            ><bdt class="question">$10,000.00 USD</bdt
                            ><bdt class="statement-end-if-in-editor"></bdt
                            ><bdt
                              class="block-component"
                            ></bdt></bdt></bdt></bdt
                      ><span
                        style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                        ><bdt
                          class="block-container if"
                          data-type="if"
                          id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                          ><bdt
                            class="statement-end-if-in-editor"
                            data-type="close"
                            ><span data-custom-class="body_text">.</span></bdt
                          >&nbsp;</bdt
                        ></span
                      ><span data-custom-class="body_text"
                        >CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
                        ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION
                        OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
                        YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
                        MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
                        RIGHTS.</span
                      ></bdt
                    ></bdt
                  ></span
                ><bdt class="statement-end-if-in-editor" data-type="close"
                  ><span data-custom-class="body_text"></span></bdt></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="indemnification"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >24.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >INDEMNIFICATION</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys’ fees and expenses, made by any third party due to or
              arising out of:
              <bdt
                class="block-container if"
                data-type="if"
                id="475fffa5-05ca-def8-ac88-f426b238903c"
                ><bdt data-type="conditional-block"
                  ><bdt
                    class="block-component"
                    data-record-question-key="user_post_content_option"
                    data-type="statement"
                  ></bdt
                  ><bdt data-type="body"
                    >(1) your Contributions;&nbsp;</bdt
                  ></bdt
                ><bdt
                  class="statement-end-if-in-editor"
                  data-type="close"
                ></bdt></bdt
              >(<span style="font-size: 14.6667px">2</span>) use of the
              Services; (<span style="font-size: 14.6667px">3</span>) breach of
              these Legal Terms; (<span style="font-size: 14.6667px">4</span>)
              any breach of your representations and warranties set forth in
              these Legal Terms; (<span style="font-size: 14.6667px">5</span>)
              your violation of the rights of a third party, including but not
              limited to intellectual property rights; or (<span
                style="font-size: 14.6667px"
                >6</span
              >) any overt harmful act toward any other user of the Services
              with whom you connected via the Services. Notwithstanding the
              foregoing, we reserve the right, at your expense, to assume the
              exclusive <bdt class="block-component"></bdt>defense<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              and control of any matter for which you are required to indemnify
              us, and you agree to cooperate, at your expense, with our
              <bdt class="block-component"></bdt>defense<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              of such claims. We will use reasonable efforts to notify you of
              any such claim, action, or proceeding which is subject to this
              indemnification upon becoming aware of it.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="userdata"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >25.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >USER DATA</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >We will maintain certain data that you transmit to the Services
              for the purpose of managing the performance of the Services, as
              well as data relating to your use of the Services. Although we
              perform regular routine backups of data, you are solely
              responsible for all data that you transmit or that relates to any
              activity you have undertaken using the Services. You agree that we
              shall have no liability to you for any loss or corruption of any
              such data, and you hereby waive any right of action against us
              arising from any such loss or corruption of such data.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="electronic"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >26.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >Visiting the Services, sending us emails, and completing online
              forms constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Services, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SERVICES. You hereby waive any rights or requirements under
              any statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic
              means.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="sms"
            style="line-height: 1.5; text-align: left"
          >
            <span style="font-size: 19px"
              ><strong>27. SMS TEXT MESSAGING</strong></span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <span style="font-size: 15px"
              ><bdt class="block-component"></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5; text-align: left"
          >
            <strong><span style="font-size: 15px">Opting Out</span></strong>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <span style="font-size: 15px"><br /></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span style="font-size: 15px"
              ><bdt class="block-component"></bdt
              ><bdt class="question"
                >If at any time you wish to stop receiving SMS, update your
                notifications preferences in your account settings.</bdt
              ><bdt class="statement-end-if-in-editor"></bdt
            ></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span style="font-size: 15px"
                >Message and Data Rates</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span style="font-size: 15px"
              >Please be aware that message and data rates may apply to any SMS
              messages sent or received. The rates are determined by your
              carrier and the specifics of your mobile plan.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_2"
            style="line-height: 1.5; text-align: left"
          >
            <strong><span style="font-size: 15px">Support</span></strong>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span style="font-size: 15px"
              >If you have any questions or need assistance regarding our SMS
              communications, please email us at
              <bdt class="question">hello@paypipe.io</bdt
              ><bdt class="block-component"></bdt>.<br
            /></span>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <span style="font-size: 15px"
              ><bdt class="statement-end-if-in-editor"></bdt></span
            ><bdt class="block-component"></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="california"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >28.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >CALIFORNIA USERS AND RESIDENTS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.</span
            >
          </div>

          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <bdt class="statement-end-if-in-editor"></bdt>
          </div>

          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="misc"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >29.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >ENTIRE AGREEMENT</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >These Legal Terms and any policies or operating rules posted by
              us on the Services or in respect to the Services constitute the
              entire agreement and understanding between you and us. Our failure
              to exercise or enforce any right or provision of these Legal Terms
              shall not operate as a waiver of such right or provision. These
              Legal Terms operate to the fullest extent permissible by law. We
              may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of
              these Legal Terms is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Legal Terms and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Legal Terms or use of the
              Services. You agree that these Legal Terms will not be construed
              against us by virtue of having drafted them. You hereby waive any
              and all <bdt class="block-component"></bdt>defenses<bdt
                class="statement-end-if-in-editor"
              ></bdt>
              you may have based on the electronic form of these Legal Terms and
              the lack of signing by the parties hereto to execute these Legal
              Terms.</span
            >
          </div>

          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>

          
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="paypipes-role-and-obligations"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >30.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >PAYPIPE'S ROLE AND OBLIGATIONS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- PAYPIPE'S ROLE AND OBLIGATIONS -->

<ol>
  <li>
    Paypipe provides the Paypipe Service in consideration for the Paypipe
    Service Fee.
  </li>

  <li>
    Paypipe only permits individuals over 18 years of age to become Users.
  </li>

  <li>
    Users must be natural persons, but can specify within their Account
    description that they represent a business entity.
  </li>

  <li>
    In its absolute discretion, Paypipe may refuse to allow any person to become
    a User or cancel or suspend or modify any existing Account including if
    Paypipe reasonably forms the view that a User's conduct (including a breach
    of these Terms and Conditions) is detrimental to the operation of the
    Paypipe platform.
  </li>

  <li>
    No charges apply in respect of the following actions on the Paypipe
    platform:

    <ol>
      <li>registering and creating an Account;</li>
      <li>creating and sharing an Offer; or</li>
      <li>
        Users accessing and reviewing offers and jobs on the Paypipe platform.
      </li>
    </ol>
  </li>

  <li>
    Paypipe accepts no liability for any aspect of the Client and Service
    Provider interaction, including but not limited to the description,
    performance or delivery of jobs or services.
  </li>

  <li>
    Paypipe is not responsible for, and does not warrant the truth or accuracy
    of any aspect of any information provided by Users, including, but not
    limited to, the ability of Service Providers to perform jobs or supply
    items, or the honesty or accuracy of any information provided by Clients or
    the Clients' ability to pay for the Services requested.
  </li>

  <li>
    The Paypipe Service is provided on an "as is" basis, and without any
    warranty or condition, express or implied. To the extent permitted by law,
    we and our suppliers specifically disclaim any implied warranties of title,
    merchantability, fitness for a particular purpose and non-infringement.
  </li>

  <li>
    Paypipe has no obligation to any User to assist or involve itself in any
    dispute between Users, although may do so to improve the User experience.
    Please refer to <a href="#dispute-resolution-process">Caluse 30 Dispute Resolution</a> below.
  </li>

  <li>
    You understand and agree that:

    <ol>
      <li>
        Paypipe does not undertake any investigation in relation to any Service
        Provider or third party service provider before they become a User,
        including criminal checks, verification of qualification or license
        held, or any character or other checks of the suitability of a Service
        Provider or third party service provider to perform any job which they
        may claim to be able to provide on the platform. The third party payment
        platform provider maintains “Know Your Client” requirements that Users
        must comply with in order to access Paypipe’s services;
      </li>

      <li>
        You are solely responsible for conducting any appropriate background
        checks and obtaining references, licenses, certifications or proof of
        insurance prior to engaging a Service Provider to perform services;
      </li>

      <li>
        You are solely responsible for making your own evaluations, decisions
        and assessments about choosing a Service Provider; and
      </li>

      <li>
        You assume all risks and you agree to expressly release, indemnify and
        hold harmless Paypipe from any and all loss, liability, injury, death,
        damage, or costs arising or in any way related to the services.
      </li>
    </ol>
  </li>
</ol>

<p>
  Paypipe only agrees to perform such duties as are expressly set forth in these
  Terms and Conditions (as amended for time to time) and the applicable Payment
  Instructions and no other duties will be implied. We have no liability under,
  and no duty to inquire as to, the provisions of any agreement, other than the
  Terms and Conditions (including these Terms and Conditions and the applicable
  Payment Instructions). We will be under no duty to inquire about or
  investigate any agreement or communication between the Client and a Service
  Provider, even if shared on the Paypipe platform. We have the right to rely
  upon, and will not be liable for relying on, any written notice, instruction,
  or request furnished to us by a Client or Service Provider in accordance with
  these Terms and Conditions or the applicable Payment Instructions, if we
  reasonably believe that such notice, instruction, or request is genuine and
  that it is signed or presented by the proper party or parties. We have no duty
  to inquire about or investigate the validity, accuracy, or content of any such
  notice, instruction, or request. We have no duty to solicit any payments or
  releases that may be due to or from any Paypipe Account. We may execute any of
  our powers and perform any of our duties under these Terms and Conditions and
  the applicable Payment Instructions directly or through agents or attorneys
  (and will be liable only for the careful selection of any such agent or
  attorney) and may consult with counsel, Accountants, and other skilled persons
  to be selected and retained by us. If we are uncertain as to our duties or
  rights under these Terms and Conditions or receive instructions, claims, or
  demands from any party to these Terms and Conditions that, in our opinion,
  conflict with any of the provisions of these Terms and Conditions or the
  applicable Payment Instructions, we will be entitled to refrain from taking
  any action, and our sole obligation will be to keep safely all property held
  in the Paypipe Account until we are directed otherwise in writing by a Client
  and the relevant Service Provider or by a final order or judgment of an
  arbitrator or court of competent jurisdiction.
</p>

          </div>
      
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <bdt class="block-component"
            ><span style="font-size: 15px"></span
        ></bdt>
        </div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <br />
        </div>
    
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="user-obligations"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >31.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >USER OBLIGATIONS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- USER OBLIGATIONS -->

<ol>
  <li>
    You will at all times:

    <ol>
      <li>
        comply with these Terms and Conditions (as updated from time to time)
        and all applicable laws and regulations;
      </li>

      <li>only post accurate information on the Paypipe platform; and</li>

      <li>
        ensure that You are aware of any laws that apply to You as a Client or
        Service Provider, or in relation to Your use of the Paypipe platform.
      </li>
    </ol>
  </li>

  <li>
    You agree that any content (whether provided by Paypipe, a User or a third
    party) on the Paypipe platform may not be used on third party sites or for
    other business purposes without Paypipe's prior written consent.
  </li>

  <li>
    You must not use the Paypipe platform for any illegal or immoral purpose.
  </li>

  <li>
    You must maintain control of Your Paypipe Account at all times. This
    includes not allowing others to use Your Account, or by transferring or
    selling Your Account or any of its content to another person.
  </li>

  <li>
    You grant Paypipe an unrestricted, worldwide, royalty-free license to use,
    reproduce, modify and adapt any content and information shared on the
    Paypipe platform for the purpose of publishing material on the Paypipe
    platform and as otherwise may be required to provide the Paypipe Service,
    for the general promotion of the Paypipe Service, and as permitted by these
    Terms and Conditions.
  </li>

  <li>
    You agree that any information shared on the Paypipe platform must not, in
    any way whatsoever, be potentially or actually harmful to Paypipe or any
    other person. Harm includes, but is not limited to, economic loss that will
    or may be suffered by Paypipe.
  </li>

  <li>
    Without limiting any provision of these Terms and Conditions, any
    information You supply to Paypipe or publish in an Offer (including in part
    thereof) must be up to date and kept up to date and must not:

    <ol>
      <li>be false, inaccurate or misleading or deceptive;</li>

      <li>be fraudulent or involve the sale of counterfeit or stolen items;</li>

      <li>
        infringe any third party's copyright, patent, trademark, trade secret or
        other proprietary rights or intellectual property rights, rights of
        publicity, confidentiality or privacy;
      </li>

      <li>
        violate any applicable law, statute, ordinance or regulation (including,
        but not limited to, those governing export and import control, consumer
        protection, unfair competition, criminal law, antidiscrimination and
        trade practices/fair trading laws);
      </li>

      <li>be defamatory, libelous, threatening or harassing;</li>

      <li>
        be obscene or contain any material that, in Paypipe's sole and absolute
        discretion, is in any way inappropriate or unlawful, including, but not
        limited to obscene, inappropriate or unlawful images; or
      </li>

      <li>
        contain any malicious code, data or set of instructions that
        intentionally or unintentionally causes harm or subverts the intended
        function of any Paypipe platform, including, but not limited to viruses,
        trojan horses, worms, time bombs, cancelbots, easter eggs or other
        computer programming routines that may damage, modify, delete,
        detrimentally interfere with, surreptitiously intercept, access without
        authority or expropriate any system, data or Personal Information.
      </li>
    </ol>
  </li>

  <li>
    If You are a Service Provider, You must have the right, licence or all other
    necessary skills (as applicable) to provide the relevant Services and the
    right to work in the jurisdiction where the Services are performed. You must
    comply with tax and regulatory obligations in relation to any payment
    (including payment to Service Providers) made with respect to a Job or
    Service.
  </li>

  <li>
    You must not, when supplying Services or contributing to a Job, charge a
    Client any fees on top of the Service Provider Earnings. However, the
    parties may agree to amend the applicable fees through the Paypipe platform.
  </li>

  <li>
    For the proper operation of the Paypipe platform (including proper pricing
    and compliance with Policies), the Service Provider must ensure that,
    provided the Client provides its prior written consent to the subcontracting
    of any part of the performance of the Service or Job to a third party, then
    that third party must also be a registered User of the Paypipe platform.
  </li>

  <li>
    If Paypipe determines at its sole discretion that You have breached any
    obligation under <a href="#user-obligations">Clause 31 “User Obligations”</a> or that You have breached one or more Offer
    agreement, it reserves the rights to remove any content, or Offer You have
    submitted to the Paypipe Service or cancel or suspend Your Account and/or
    any Jobs.
  </li>

  <li>
    With respect to information shared by Users:

    <ol>
      <li>
        each of the Service Provider and the Client agree to keep all
        information shared or otherwise pertaining to the Project confidential,
        and vice versa;
      </li>

      <li>
        the Service Provider agrees not to disclose or use any confidential
        information of the Client for any purpose other than performing the
        services under the contract;
      </li>

      <li>
        the Service Provider acknowledges that the Client's confidential
        information is the sole property of the Client;
      </li>

      <li>
        the Service Provider agrees to take all reasonable steps to prevent the
        unauthorized disclosure or use of the Client's confidential information;
        and
      </li>

      <li>
        The non-disclosure obligations continue after the termination of the
        Contract for a period of three years.
      </li>
    </ol>
  </li>
</ol>

          </div>
      
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <bdt class="block-component"
            ><span style="font-size: 15px"></span
        ></bdt>
        </div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <br />
        </div>
    
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="community-guidelines"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >32.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >COMMUNITY GUIDELINES</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- COMMUNITY GUIDELINES -->

<p>
  Paypipe is committed to fostering a positive, respectful, and inclusive
  community. These guidelines outline the standards for acceptable behavior and
  are designed to ensure a safe and welcoming environment for all users. By
  participating in any form of communication on Paypipe, you agree to adhere to
  these guidelines.
</p>

<ol>
  <li>
    <h3>RESPECT AND COURTESY</h3>
    <p>
      Treat all users with respect and courtesy. Harassment, discrimination, or
      any form of abusive behavior will not be tolerated. Do not engage in
      personal attacks, insults, or inflammatory comments. Debate the topic, not
      the person.
    </p>
  </li>

  <li>
    <h3>NO HATE SPEECH OR DISCRIMINATION</h3>
    <p>
      Paypipe prohibits all forms of hate speech, including but not limited to
      speech that attacks or demeans individuals or groups based on race,
      ethnicity, nationality, religion, gender, gender identity, sexual
      orientation, disability, or age.
    </p>
  </li>

  <li>
    <h3>PRIVACY AND CONFIDENTIALITY</h3>
    <p>
      Users will respect the privacy and personal information of others. Do not
      share personal information, whether your own or others', without explicit
      consent. Do not solicit personal information from other users.
    </p>
  </li>

  <li>
    <h3>NO SPAM OR UNSOLICITED ADVERTISING</h3>
    <p>
      Users will refrain from posting spam, unsolicited advertisements, or
      promotional content. This includes repetitive messages, irrelevant links,
      and excessive self-promotion.
    </p>
  </li>

  <li>
    <h3>INTELLECTUAL PROPERTY AND COPYRIGHT</h3>
    <p>
      Users will respect intellectual property rights. Do not share or
      distribute copyrighted material without permission from the owner. Users
      will always attribute content to the original creator if you share or
      reference their work.
    </p>
  </li>

  <li>
    <h3>APPROPRIATE CONTENT</h3>
    <p>
      Users will keep all content appropriate for a diverse audience. Users will
      not post or share content that is obscene, sexually explicit, violent, or
      otherwise inappropriate. Users will avoid posting content that could be
      considered offensive or inflammatory.
    </p>
  </li>

  <li>
    <h3>NO ILLEGAL ACTIVITIES</h3>
    <p>
      Users will not engage in or promote illegal activities, including but not
      limited to hacking, drug use, or violence. Users will abide by all local,
      national, and international laws when using Paypipe.
    </p>
  </li>

  <li>
    <h3>REPORTING</h3>
    <p>
      If a User encounters any content or behavior that violates these
      guidelines, please report it to our moderation team via email to
      legal@paypipe.io. Paypipe will review all reports and take appropriate
      action. Users agree that they will not misuse the reporting function.
      False or malicious reports will not be tolerated by Paypipe.
    </p>
  </li>

  <li>
    <h3>VIOLATIONS OF COMMUNITY GUIDELINES</h3>
    <p>
      Violations of these guidelines may result in actions including but not
      limited to warnings, content removal, account suspension, or permanent ban
      from Paypipe. Paypipe reserves the right to enforce these guidelines at
      its sole discretion and to modify them as necessary.
    </p>
  </li>
</ol>

          </div>
      
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <bdt class="block-component"
            ><span style="font-size: 15px"></span
        ></bdt>
        </div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <br />
        </div>
    
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="fees-and-payment-facility"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >33.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >FEES AND PAYMENT FACILITY</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- FEES AND PAYMENT FACILITY -->

<ol>
  <li>
    A Service Provider is charged a Paypipe Service Fee as consideration for the
    Service Provider's use of the Paypipe Services.
  </li>

  <li>
    At such time that the Client releases the Job Payment to the Service
    Provider, the Paypipe Service Fee will be deducted from the Job Payment.
  </li>

  <li>
    The Paypipe Service Fees are GST inclusive (or equivalent tax on supplies,
    including VAT).
  </li>

  <li>
    Subject to clause
    <a href="#release-conditions">Clause 34 “Release Conditions”</a>
    below, all Fees payable to Paypipe are non-cancellable and non-refundable.
  </li>

  <li>
    If Paypipe introduces a new service on the Paypipe platform, the Fees
    applying to that service will be payable from the launch of the service.
  </li>

  <li>
    Paypipe reserves the right to amend the amount of any Fees (including the
    Paypipe Service Fee) from time to time.
  </li>

  <li>Paypipe uses a Payment Provider to operate the Payment Account.</li>

  <li>
    In so far as it is relevant to the provision of the Payment Account, the
    terms at
    <a href="https://stripe.com/ssa/" target="_blank"
      >https://stripe.com/ssa/</a
    >
    are incorporated into these Terms and Conditions and will prevail over these
    Terms and Conditions to the extent of any inconsistency in relation to the
    provision of the Payment Account.
  </li>

  <li>
    If Paypipe changes its Payment Provider, You may be asked to agree to any
    further additional terms with those providers. If you do not agree to them,
    you will be given alternative means of payment at the discretion of Paypipe.
  </li>
</ol>

          </div>
      
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <bdt class="block-component"
            ><span style="font-size: 15px"></span
        ></bdt>
        </div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <br />
        </div>
    
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="release-conditions"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >34.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >RELEASE CONDITIONS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- RELEASE CONDITIONS -->

<ol>
  <li>
    As used in these Terms and Conditions, “Release Condition” means any of the
    following:

    <ol>
      <li>
        Client clicks button on Paypipe platform [in response to a Request
        release from the relevant Service Provider] to agree to release funds to
        Service Provider;
      </li>

      <li>
        Client does not take any action for 10 days from the date of a Service
        Provider's Release request, in which case Service Provider and Client
        agree that Paypipe is authorized and irrevocably instructed to
        immediately release to the Service Provider the amount associated with
        the applicable Milestone in connection with such Release request;
      </li>

      <li>
        Service Provider or Client initiates a dispute before a payment has been
        released to the Service Provider, in which case the funds will be
        released in accordance with the outcome of the Dispute Resolution
        Process;
      </li>

      <li>
        Service Provider cancels the Job before a payment has been released to
        the Service Provider, in which case the funds are returned to the
        Client;
      </li>

      <li>
        Client cancels the Job before a payment has been released to Service
        Provider and Service Provider approves the request or takes no action
        within 7 days, in which case the funds are released to the Client;
      </li>

      <li>
        issuance of the final order of a court or arbitrator of competent
        jurisdiction from which appeal is not taken, in which case the funds
        will be released in accordance with such order; or
      </li>

      <li>
        we believe, in our sole discretion, that fraud, an illegal act, or a
        violation of Paypipe's Terms and Conditions has been committed or is
        being committed or attempted, in which case Client and Service Provider
        irrevocably authorize and instruct Paypipe Secure Payments to take such
        actions as we deem appropriate in our sole discretion and in accordance
        with applicable law, in order to prevent or remedy such acts, including
        without limitation to return the funds associated with such acts to
        their source of payment.
      </li>
    </ol>
  </li>

  <li>
    <h2>RELEASE OF FUNDS TO CLIENT</h2>

    <p>
      Service Provider and Client authorize and instruct Paypipe to immediately
      release funds to the Client on the occurrence of any of the following
      Release Conditions, such authorization and instruction to be irrevocable
      except as expressly provided in these Terms and Conditions:
    </p>

    <ol>
      <li>
        Service Provider cancels the Job or accepts Client's request to cancel
        the Job (as described in <a href="#refunds-and-cancellations">Clause 35 “Refunds and Cancellations”</a>), and funds for a Milestone or the
        Job are held by Paypipe;
      </li>

      <li>
        Client and Service Provider have jointly submitted Supplemental
        Instructions in writing and signed by both Client and Service Provider
        to Paypipe at legal@paypipe.com requesting a Release to the Client, and
        Paypipe has agreed to the Supplemental Instructions;
      </li>

      <li>
        Client and Service Provider agree to close the Job without release of
        funds to the Service Provider;
      </li>

      <li>Service Provider has declined dispute resolution;</li>

      <li>
        Service Provider has failed to timely respond to a request for a refund
        submitted through the platform, Notice of Dispute, or otherwise failed
        to comply with the Dispute resolution process, as required by the
        Paypipe Dispute Resolution Team;
      </li>

      <li>
        issuance of an order of a court, arbitrator, or other judicial body of
        apparent competent jurisdiction in favor of the Client, in whole or in
        part, to the extent required by the order; or
      </li>

      <li>
        a condition to release funds to Client described elsewhere in these
        Terms and Conditions applies.
      </li>
    </ol>
  </li>

  <li>
    <h3>DORMANT ENGAGEMENTS</h3>

    <ol>
      <li>
        To be fair to Clients and Service Providers, Paypipe has a procedure for
        Fixed-Price (One-time or Milestones) Jobs that appear to be Dormant
        Engagements (as defined below). For purposes of determining dormant
        status, “activity” means a change to the Job, including Milestone
        updates or requests, Job Funding, Fund Release, Refunds, Funding
        requests, Release requests, requests to cancel the job, or actions under
        the Dispute Resolution Process.
      </li>

      <li>
        A “Dormant Engagement” is an active One-time job or Milestone but has
        had no activity for 60 consecutive calendar days after the last
        Milestone date contained in the Job terms (“Dormant Date”). Dormant
        Engagements are subject to the following rules:

        <ol>
          <li>Paypipe will notify Client when the Job becomes Dormant.</li>

          <li>
            If the Client does not take any action within 7 calendar days after
            the Dormant Date and notification, Paypipe will notify the Service
            Provider that the Job is Dormant (“Service Provider Notice of
            Dormant Engagement”).
          </li>

          <li>
            If Service Provider submits a Release request within 7 calendar days
            after the Service Provider Notice of Dormant Engagement and Client
            does not take any action for 10 calendar days from the date of the
            Release request, the Service Provider and Client authorize and
            irrevocably instruct Paypipe to immediately release to the Service
            Provider the amount related to the Milestone with a Release request.
          </li>

          <li>
            If neither Service Provider nor Client take any action for 7
            calendar days after the Service Provider Notice of Dormant
            Engagement, the Service Provider and the Client authorize and
            irrevocably instruct Paypipe to immediately release funds to the
            Client.
          </li>
        </ol>
      </li>
    </ol>
  </li>
</ol>

          </div>
      
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <bdt class="block-component"
            ><span style="font-size: 15px"></span
        ></bdt>
        </div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <br />
        </div>
    
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="refunds-and-cancellations"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >35.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >REFUNDS AND CANCELLATIONS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- REFUNDS AND CANCELLATIONS -->

<p>
  The Client and the Service Provider are encouraged to come to a mutual
  agreement if refunds or cancellations are necessary. If there are no funds
  held by Paypipe for the relevant Job, the Client and the Service Provider have
  the ability to cancel the Job by clicking on Cancel Job on the platform. If
  funds are held by Paypipe, refunds and cancellations must be initiated by the
  Client or the Service Provider by following the steps in this Clause.
</p>

<ol>
  <li>
    <h3>REFUNDS AND CANCELLATIONS BY SERVICE PROVIDER</h3>

    <ol>
      <li>
        If a Service Provider wants to cancel a Job with funds held by Paypipe,
        They must select “Cancel job” on the Paypipe platform. When the Service
        Provider cancels the Job on the Paypipe platform, the Service Provider
        and the Client agree that Paypipe is authorized and irrevocably
        instructed to immediately release to the Client all funds held for the
        Job at that time.
      </li>

      <li>
        The Service Provider may issue a refund to the Client up to the amount
        paid for the relevant Job in the past 180 days by clicking on “Issue a
        refund” on the Paypipe platform. The Service Provider may not issue a
        refund in an amount greater than the combined amount of funds held by
        Paypipe for the job, funds for transactions pending to be placed in the
        Service Provider's Account, and funds for submitted Milestones. By
        selecting “Issue a refund”, Service Provider agrees that Paypipe is
        authorized and irrevocably instructed to immediately release the funds
        to the Client.
      </li>
    </ol>
  </li>

  <li>
    <h3>REFUNDS AND CANCELLATIONS BY CLIENT</h3>

    <ol>
      <li>
        If the Client intends to cancel a Job and Paypipe is holding the funds
        intended as payment for that Job, the Client must select “Cancel job” on
        the Paypipe platform. The Service Provider must select the option to
        either approve or dispute Client's cancellation within 7 calendar days.
        If the Service Provider approves the cancellation, the Service Provider
        and the Client agree that Paypipe is authorized and irrevocably
        instructed to immediately release to the Client all funds held for the
        relevant Job at that time. If the Service Provider intends to dispute
        the cancellation, the Service Provider must initiate the dispute within
        7 calendar days. If Service Provider takes no action within 7 calendar
        days from the date notification of the cancellation is sent to Service
        Provider by Paypipe, Service Provider and Client agree that Paypipe is
        authorized and irrevocably instructed to immediately release to the
        Client all funds held for the Job at that time. If the Service Provider
        disputes the cancellation, the Service Provider and the Client will be
        offered dispute resolution assistance in accordance with Paypipe's
        Dispute Resolution Process.
      </li>

      <li>
        The Client may request a refund up to the amount paid on a Job in the
        past 180 days by clicking on “Request a refund” on the Paypipe platform.
        The Client may not request a refund in an amount greater than the
        combined amount of funds held by Paypipe for the relevant Job, funds for
        pending transactions held in the Service Provider's Account, and funds
        previously received by the Service Provider for submitted Milestones.
        The Service Provider must select the option to either approve or reject
        the Request for refund within 14 calendar days of receiving the
        notification of the refund request. If the Service Provider:

        <ol>
          <li>
            approves the refund, Service Provider and Client agree that Paypipe
            is authorized and irrevocably instructed to immediately release to
            the Client the refund amount from the funds held for the Job at that
            time; or
          </li>

          <li>
            rejects the request, the Client will be notified of the rejection.
            If the Client intends to dispute the matter, they must initiate a
            dispute by selecting “Dispute decision” on the Paypipe platform
            within 7 calendar days of receiving the rejection notification.
          </li>

          <li>
            takes no action within 14 calendar days of receiving the
            notification of the refund request, the Service Provider and Client
            agree that Paypipe is authorized and irrevocably instructed to
            immediately release to the Client the refund amount from the funds
            held for the Job at that time.
          </li>
        </ol>
      </li>
    </ol>
  </li>
</ol>

          </div>
      
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <bdt class="block-component"
            ><span style="font-size: 15px"></span
        ></bdt>
        </div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <br />
        </div>
    
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="payment-terms"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >36.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >PAYMENT TERMS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- PAYMENT TERMS -->

<ol>
  <li>
    <h3>PAYMENT INSTRUCTIONS</h3>

    <p>
      Paypipe will use and release funds deposited in a Client's Paypipe Account
      only in accordance with these Terms and Conditions and the applicable
      Payment Instructions. You acknowledge and agree that Paypipe acts merely
      as a payment facilitation service. Paypipe has fully discharged its
      obligations to you if Paypipe provides the Payment Services described in
      these Terms and Conditions and the applicable Payment Instructions.
      Paypipe is only obligated to perform those duties expressly described in
      these Terms and Conditions and any applicable Payment Instructions.
      Paypipe will release funds from any Paypipe Account in reliance on your
      authorization, these Terms and Conditions, and the applicable Payment
      Instructions or as required by applicable law.
    </p>

    <ol>
      <li>
        Fixed-Price Jobs: If Users elect fixed-price compensation, then the
        Users agree that they will be bound by, and Paypipe will follow the
        Fixed-Price Payment Instructions.
      </li>

      <li>
        Hourly Jobs: If Users choose hourly compensation or if the Client makes
        bonus or additional payments, then the Users agree that they will be
        bound by, and Paypipe will follow, the Bonus and Expense Payment
        Agreement with Payment Instructions.
      </li>
    </ol>
  </li>

  <li>
    <h3>SERVICE PROVIDER APPOINTMENT OF PAYPIPE AS AGENT</h3>

    <p>
      Service Providers authorize Paypipe to act for them to collect and hold
      payments from Clients. Where a Service Provider requests payment related
      to an Hourly Contract or the release of funds from a Fixed-Price Payment
      Agreement, they hereby appoint Paypipe as their agent to obtain funds from
      the Client and credit them to the Service Provider's Paypipe Account as
      agreed in the applicable Payment Instructions. The Service provider fully
      discharges and credits the relevant Client for all payments and releases
      of funds that Paypipe receives on the Service Provider's behalf from or on
      behalf of the relevant Client.
    </p>
  </li>

  <li>
    <h3>TITLE TO FUNDS</h3>

    <p>
      Paypipe and our Affiliates (including any third party provider) are not
      banks. Paypipe partners with Stripe Payments Company for money
      transmission services and Account services with funds held at Evolve Bank
      & Trust, Member FDIC.Paypipe is not a financial services business.
    </p>
  </li>

  <li>
    <h3>NO INTEREST</h3>

    <p>
      You will not earn any interest on the money we hold in your Paypipe
      Account and you understand that we charge fees for our services, as
      explained in <a href="#fees-and-payment-facility">Clause 33 “Fees and Payment Facility”</a>.
    </p>

    <p>
      You agree that you will not receive interest or other earnings on the
      funds held in your Paypipe Account. Paypipe or our Affiliates may charge
      or deduct fees, may receive a reduction in fees or expenses charged, and
      may receive other compensation in connection with the services we provide.
    </p>
  </li>

  <li>
    <h3>CLIENT PAYMENTS ON JOBS</h3>

    <p>
      For the purposes of this clause, “Service Provider Fees” means the price
      set for a specific job or project. This fee is determined by the Service
      Provider and is the amount that the Client agrees to pay upon accepting
      the offer. The Service Provider Fees cover the cost of the services to be
      rendered by the Service Provider as detailed in the project offer or
      proposal. These fees are held securely by Paypipe until the successful
      completion and acceptance of the job by the Client, at which point they
      are released to the Service Provider in accordance with our payment
      handling procedures.
    </p>

    <ol>
      <li>
        <h3>HOURLY JOBS</h3>
        <p>
          The Service Provider will invoice the Client on a weekly basis through
          the Paypie platform for the Service Provider Fees, and the Client will
          pay invoices consistent with the Payment Instructions. When the Client
          approves an Hourly Invoice for an Hourly Job, the Client automatically
          and irrevocably authorizes and instructs Paypipe to charge Client's
          Payment Method.
        </p>
      </li>

      <li>
        <h3>FIXED-PRICE JOBS</h3>
        <p>
          The Client becomes obligated to pay applicable amounts into the
          Paypipe Account immediately upon sending a Fixed-Price Contract offer
          (for the full amount if it's a “One-time” job or for the first
          Milestone, if Milestones are used), upon activating any additional
          Milestone, or upon accepting a Fixed-Price Job. When a Client
          authorizes the payment release to the Service Provider Fees for a
          Fixed-Price Job, the Client automatically and irrevocably authorizes
          and instructs Paypipe (or its Affiliates) to charge the Client's
          Payment Method for the amount owed to the Service Provider under that
          Job.
        </p>
        <p>
          The Client acknowledges and agrees that for both Hourly Jobs and
          Fixed-Price Jobs, failure by the Client to decline or dispute an
          Hourly Invoice or request for payment is an authorization and
          instruction to release payment, as described more fully in the
          applicable Payment Instructions.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h3>DISBURSEMENTS TO SERVICE PROVIDERS ON JOBS</h3>

    <ol>
      <li>
        Paypipe disburses funds that are available in the applicable Service
        Provider's Paypipe Account and payable to a Service Provider as
        described in the relevant Payment Instructions. If a Service Provider is
        not able to withdraw funds due to economic sanctions applicable to
        Paypipe or our Affiliates, or due to Paypipes' payment partners' refusal
        to service certain geographic areas, Paypipe will suspend automatic
        disbursement as provided in the prior paragraph and will safeguard such
        funds in a Paypipe Account, or in another suitable Account, as
        determined in Paypipe's sole discretion, until the earlier of:

        <ol>
          <li>
            the Service Provider is able to and does in fact withdraw the funds;
            or
          </li>

          <li>
            Paypipe is required by law to escheat the funds to an appropriate
            legal authority, after which the Service Provider can seek to
            recover the funds from such authority.
          </li>
        </ol>
      </li>

      <li>
        Except as expressly provided in the Terms and Conditions or the Payment
        Instructions and as prohibited by applicable law, Paypipe may refuse to
        process, may hold the disbursement of the Service Provider Fees or any
        other amounts and offset amounts owed to us, or take such other actions
        with respect to the Paypipe Account as we deem appropriate in our sole
        discretion if:

        <ol>
          <li>we require additional information;</li>

          <li>
            we have reason to believe the Service Provider Fees may be subject
            to dispute or chargeback;
          </li>

          <li>
            we suspect a User has committed or attempted to commit fraud or
            other illicit acts on or through the Paypipe Platform or is using
            the Paypipe Platform unlawfully;
          </li>

          <li>
            we believe there are reasonable grounds for insecurity with respect
            to the performance of obligations under these Terms and Conditions;
            or
          </li>

          <li>
            We deem it necessary in connection with any investigation, required
            by applicable law, or necessary due to circumstances outside of our
            control after a commercially reasonable effort has been made by us
            due to such circumstances.
          </li>
        </ol>

        <p>
          If, after investigation, we determine that the hold on the
          disbursement of the Service Provider Fees is no longer necessary,
          Paypipe will release the hold as soon as practicable.
        </p>
      </li>

      <li>
        In addition, except as expressly provided in the Terms and Conditions or
        the Payment Instructions and to the extent permitted by applicable law,
        we reserve the right to seek reimbursement from you, and you will
        reimburse us, if we:

        <ol>
          <li>
            suspect fraud or criminal activity associated with your payment,
            withdrawal, or Job;
          </li>

          <li>discover erroneous or duplicate transactions; or</li>

          <li>
            have supplied our services in accordance with these Terms and
            Conditions yet we receive any chargeback from the Payment Method
            used by you or your Client despite our provision of the Services in
            accordance with these Terms and Conditions.
          </li>
        </ol>
      </li>
    </ol>

    <p>
      You agree that we have the right to obtain such reimbursement by
      instructing Paypipe (and Paypipe will have the right) to charge your
      Account(s), offset any amounts determined to be owing, deduct amounts from
      future payments or withdrawals, charge your Payment Method, or use other
      lawful means to obtain reimbursement from you. If we are unable to obtain
      such reimbursement, we may, in addition to any other remedies available
      under applicable law, temporarily or permanently revoke your access to the
      Services and close your Account.
    </p>
  </li>

  <li>
    <h3>BONUS AND ADDITIONAL PAYMENTS</h3>

    <ol>
      <li>
        ADDITIONAL PAYMENTS

        <ol>
          <li>
            Additional payments can be made for a variety of reasons, including
            but not limited to:

            <ol>
              <li>
                extra work performed by the Service Provider that was not
                included in the original agreement;
              </li>

              <li>
                costs for materials or other expenses incurred by the Service
                Provider; and
              </li>

              <li>
                bonuses or tips as a gesture of appreciation for the Service
                Provider's work; <br /><br />
                each an “Additional Payment”.
              </li>
            </ol>
          </li>

          <li>
            Both the request for Additional Payment and the issuance of an
            Additional Payment will require confirmation by the relevant Users
            on their respective platforms. Once confirmed, the Additional
            Payment will be processed according to the payment method and terms
            agreed upon by the relevant Users.
          </li>

          <li>
            All Additional Payment requests and transactions will be recorded in
            the job management system on the platform. Both the Client and the
            Service Provider can view the history of Additional Payments in the
            job management menu.
          </li>

          <li>
            Any disputes arising from Additional Payment requests or
            transactions will be subject to the Paypipe Dispute Resolution
            Process as outlined in the Terms and Conditions.By using this
            feature, both Clients and Service Providers agree to adhere to the
            processes and guidelines.
          </li>
        </ol>
      </li>

      <li>
        SERVICE PROVIDER REQUESTS FOR ADDITIONAL PAYMENTS

        <ol>
          <li>
            Service Providers can request an additional payment from the client
            by using the "Request Additional Payment" option in the job
            management menu on the platform. Upon selecting this option, the
            Service Provider will be directed to a screen where they can:

            <ol>
              <li>specify the payment amount;</li>

              <li>provide a reason for the additional payment request; and</li>

              <li>attach supporting files if necessary.</li>
            </ol>
          </li>

          <li>
            The client will receive a notification of the request and can choose
            to accept or reject it.
          </li>
        </ol>
      </li>

      <li>
        CLIENT ISSUANCE OF ADDITIONAL PAYMENT

        <ol>
          <li>
            Clients have the option to issue an additional payment to a Service
            Provider by selecting the "Issue Additional Payment" option in the
            job management menu on the platform.
          </li>

          <li>
            The Client will be directed to a screen to:

            <ol>
              <li>specify the payment amount;</li>

              <li>provide a reason for the additional payment; and</li>

              <li>attach supporting files if necessary.</li>
            </ol>
          </li>

          <li>
            Once confirmed, the additional payment will be processed and the
            Service Provider will be notified of the payment.
          </li>
        </ol>
      </li>
    </ol>
  </li>
</ol>

          </div>
      
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <bdt class="block-component"
            ><span style="font-size: 15px"></span
        ></bdt>
        </div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <br />
        </div>
    
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="non-payment-or-default"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >37.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >NON-PAYMENT OR DEFAULT</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- NON-PAYMENT OR DEFAULT -->

<ol>
  <li>
    If a Client is in “default”, meaning the Client fails to pay the Service
    Provider Fees or any other amounts when due under the Terms and Conditions,
    the Payment Instructions, or a written agreement for payment terms
    incorporating the Terms and Conditions, Paypipe will be entitled to the
    remedies described in <a href="#non-payment-or-default">Clause 37 “Non-Payment or Default”</a> in addition to such other remedies
    that may be available under applicable law or in such written agreement. For
    the avoidance of doubt, the Client will be deemed to be in default on the
    earliest occurrence of any of the following:

    <ol>
      <li>the Client fails to pay the Service Provider Fees when due:</li>

      <li>
        the Client fails to pay a balance that is due or to bring, within a
        reasonable period of time but no more than 30 days after accrual of the
        charge, an Account current after a credit or debit card is declined or
        expires;
      </li>

      <li>
        the Client fails to pay an invoice issued to the Client by Paypipe
        within the time period agreed or, if no period is agreed, within 30
        days;
      </li>

      <li>
        the Client initiates a chargeback with a bank or other financial
        institution resulting in a charge made by Paypipe for Service Provider
        Fees or such other amount due being reversed to the Client; or
      </li>

      <li>
        the Client takes other actions or fails to take any action that results
        in a negative or past-due balance on the Client's Account.
      </li>
    </ol>
  </li>

  <li>
    If a Client is in default, we may, without notice, temporarily or
    permanently close the Client's Account and revoke Client's access to the
    Services, including Client's authority to use the Paypipe platform to
    process any additional payments, or obtain any additional Service Provider
    Services from other Users through the Paypipe platform. However, the Client
    will remain responsible for any amounts that accrue on any open Jobs at the
    time a limitation is put on the Client's Account as a result of the default.
    Without limiting other available remedies, the Client must pay Paypipe upon
    demand for any amounts owed, plus interest on the outstanding amount at the
    lesser of one and one-half percent (1.5%) per month or the maximum interest
    allowed by applicable law, plus attorneys' fees and other costs of
    collection to the extent permitted by applicable law.
  </li>

  <li>
    At our discretion and to the extent permitted by applicable law, Paypipe or
    its Affiliates may, without notice, charge all or a portion of any amount
    that is owed to any Payment Method on file on the Client's Account; set off
    amounts due against other amounts received from Client or held by for Client
    by Paypipe or another Affiliate; make appropriate reports to credit
    reporting agencies and law enforcement authorities; and cooperate with
    credit reporting agencies and law enforcement authorities in any
    investigation or prosecution.
  </li>

  <li>
    Paypipe does not guarantee that a Client is able to pay or will pay Paypipe
    Service Fees, and Paypipe is not liable for and may reverse Service Provider
    Fees if a Client is in default or initiates a chargeback of funds with their
    financial institution.
  </li>
</ol>

          </div>
      
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <bdt class="block-component"
            ><span style="font-size: 15px"></span
        ></bdt>
        </div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <br />
        </div>
    
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="no-return-of-funds-and-no-chargebacks"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >38.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >NO RETURN OF FUNDS AND NO CHARGEBACKS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- NO RETURN OF FUNDS AND NO CHARGEBACKS -->

<ol>
  <li>
    Clients agree that, once Paypipe charges their Payment Methods, the charge
    cannot be refunded except on a condition agreed in these Terms and
    Conditions. Clients agree not to initiate any chargebacks from their credit
    card companies, banks, or the like.
  </li>

  <li>
    Clients acknowledge and agree that Paypipe or its affiliates may charge or
    debit a Client's designated Payment Method for the Service Provider Fees
    incurred as described in the applicable Payment Instructions and the Paypipe
    Service Fee. Once Paypipe or its affiliates charges or debits the Client's
    designated Payment Method for the Service Provider Fees, the charge or debit
    is non-refundable, except as provided in the applicable Payment Instructions
    or as otherwise required by applicable law. The Client also acknowledges and
    agrees that the Terms and Conditions provide a dispute resolution process as
    a way for Client to resolve disputes. To the extent permitted by applicable
    law, Client therefore agrees not to ask its credit card company, bank, or
    other Payment Method provider to charge back any Service Provider Fees or
    other fees charged pursuant to the Terms and Conditions for any reason. A
    chargeback in breach of this obligation is a material breach of the Terms
    and Conditions. If a Client initiates a chargeback in violation of these
    Terms and Conditions, the Client agrees that Paypipe or its affiliates may
    dispute or appeal the chargeback, institute collection action against a
    Client, close the Client's Account, and take such other action it deems
    appropriate.
  </li>
</ol>

          </div>
      
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <bdt class="block-component"
            ><span style="font-size: 15px"></span
        ></bdt>
        </div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left">
        <br />
        </div>
    
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="dispute-resolution-process"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >39.</span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >DISPUTE RESOLUTION PROCESS</span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
           <!-- DISPUTE RESOLUTION PROCESS -->

<ol>
  <li>
    <h3>INTRODUCTION</h3>

    <ol>
      <li>
        If a Client and Service Provider fail to come to a mutual resolution by
        way of the Refund and Cancellation process as stated in
        <a href="#refunds-and-cancellations">Clause 35</a>, Paypipe provides the
        Dispute Resolution Process administered by Paypipe and described in
        <a href="#dispute-resolution-process"
          >this Clause 39 (the “Dispute Resolution Process”)</a
        >
        (the “Dispute Resolution Process”) as a mechanism to resolve the
        dispute.
      </li>

      <li>
        If the funds in dispute are held in a Paypipe Account, the Dispute
        Resolution Process will proceed as described in
        <a href="#dispute-resolution-process-c">Clause 39(c)</a>.
      </li>

      <li>
        If the funds in dispute have been released, the Dispute Resolution
        Process will proceed as described in
        <a href="#dispute-resolution-process-d">Clause 39(d)</a>.
      </li>

      <li>
        The Dispute Resolution Process is not available for disputes filed or
        initiated past the Dispute Assistance deadlines, as set forth in Clauses
        <a href="#dispute-resolution-process-c">Clause 39(c)(i)</a> and
        <a href="#dispute-resolution-process-d">Clause 39(d)(i)</a>, as
        applicable.
      </li>

      <li>
        The Dispute Resolution Process is offered as a form of non-binding
        assistance to facilitate communication and potential resolution of
        disputes between clients and Service Providers. The Dispute Resolution
        Process as administered by Paypipe in
        <a href="#dispute-resolution-process">this Clause 39</a> does not evaluate the quality or
        functionality of work and cannot render binding judgment or
        determination as to the parties' respective rights to the disputed
        funds.
      </li>

      <li>
        Paypipe reserves the right to commence a dispute process if there is a
        disagreement between a Client and a Service Provider related to a
        Paypipe Account. We have the right to institute arbitration or other
        legal proceedings if applicable, including depositing funds held in the
        Paypipe Account with a court of competent jurisdiction, in order to
        resolve any dispute between a Client and a Service Provider related to a
        Paypipe Account. Except as expressly provided, nothing in these Terms
        and Conditions will be construed to limit our legal and equitable
        rights, including, but not limited to, depositing funds held in the
        Paypipe Account with a court of competent jurisdiction. Any corporation
        or association into which Paypipe may be merged or converted or with
        which Paypipe may be consolidated, or any corporation or association to
        which all or substantially all the business of Paypipe may be
        transferred will succeed to all the rights and obligations of Paypipe
        under these Terms and Conditions and the applicable Payment Instructions
        to the extent permitted by applicable law.
      </li>
    </ol>
  </li>

  <li>
    <h3>PROCEDURES</h3>

    <p>
      The Dispute Resolution Team may follow this procedure in the event of a
      dispute:
    </p>

    <ol>
      <li>
        Upon receiving a dispute, the Paypipe Dispute Resolution Team will
        assess all relevant information readily available on the platform
        related to the disputed Job. Paypipe will endeavour to process the
        review ' 30 days however Paypipe accepts no liability for any losses
        incurred due to the extension of this time period at Paypipe's sole
        discretion./li>
      </li>

      <li>
        After completing the assessment, the Paypipe Dispute Resolution Team
        will provide its recommendation to all parties involved in the dispute.
        The recommendation may advise, although will not be limited to, a full
        refund to the Client, full release of funds to the Service Provider, a
        partial refund or a partial release of funds. Parties will have 14 days
        from the date the recommendation is shared to either accept or reject
        the recommendation.
      </li>

      <li>
        If both parties accept the recommendations ' the 14-day period, the
        funds will be released accordingly based on the terms outlined in the
        recommendation.
      </li>

      <li>
        If either party rejects the recommendations of the Paypipe Dispute
        Resolution independent Team ' the 14-day period, the Paypipe Dispute Resolution Team
        will issue a Notice of Non-resolution. In the case of a Notice of
        Non-resolution, the parties are advised to seek independent legal
        advice.
      </li>

      <li>
        The relevant funds will be automatically released in accordance with the
        recommendations of the Paypipe Dispute Resolution Team 90 days after the
        date of the Notice of Non-Resolution unless Paypipe receives written
        notification by the relevant User of the following:

        <ol>
          <li>
            a legal determination of the dispute (by a court or counsel of
            competent jurisdiction);
          </li>

          <li>
            another form of agreed resolution in writing (a copy of which is
            provided the Paypipe); or
          </li>

          <li>
            notice that proceedings are underway to resolve the matter outside
            of Paypipe's Dispute Resolution Procedures.
          </li>
        </ol>
      </li>

      <li>
        All parties involved in the dispute agree to comply with the final
        decision, whether it results from the Paypipe Dispute Resolution Team's
        recommendation or a pursuant legal process. Paypipe reserves the right
        to update this Dispute Resolution Process at its sole discretion.
      </li>
    </ol>

    <p>
      By using Paypipe's services, you agree to adhere to this Dispute
      Resolution Process in the event of a dispute.
    </p>
  </li>

  <li id="dispute-resolution-process-c">
    <h3>DISPUTE OF FUNDS HELD IN PAYPIPE ACCOUNTS</h3>

    <p>
      This Clause applies to disputes over funds that are held in a Paypipe
      Account as of the date the dispute is filed (“Dispute”). The scope of the
      Dispute may cover the entirety of the Fixed-Price Contract and all
      Milestones previously funded, approved and released. In the event of a
      Dispute, funds held in a Paypipe Account will remain held in the relevant
      Paypipe Account while the Dispute Resolution Process or Arbitration, as
      applicable, is being administered. The Dispute Resolution Process for a
      Dispute will be administered as set forth below:
    </p>

    <ol>
      <li>
        Dispute Assistance Deadline: Disputes must be initiated before the funds
        in the relevant Paypipe Account have been released in order to be
        eligible for the Dispute Resolution Process under this Clause. Disputes
        over funds that have been released by Paypipe may be eligible for the
        Dispute Resolution Process described in
        <a href="#dispute-resolution-process-d">Clause 39(d)</a>.
      </li>

      <li>
        Initiating A Dispute: Clients have the option to release partial
        Milestone payments or request a refund. In turn, Service Providers may
        initiate a Dispute when a Client fails to release a Milestone payment,
        releases a partial Milestone payment, request a refund, or cancels the
        Job with a Paypipe Account balance, despite any purported delivery of
        work.
      </li>

      <li>
        Notice of a Dispute: Once a Dispute has been filed, Client will be
        notified of the Dispute on the platform ("Notice of Dispute"). The
        Notice of Dispute will request information and supporting documentation
        from the Client, if needed.
      </li>

      <li>
        Lack of Participation: Client must respond to the Notice of Dispute '
        five calendar days. If the Client does not timely respond to the Notice
        of Dispute, the Client and the Service Provider agree that the lack of
        timely response acts as an irrevocable authorization and instruction to
        Paypipe to release the funds from the Client to the Service Provider.
      </li>

      <li>
        Non-Binding Assistance: After the relevant Client responds to the Notice
        of Dispute, the Dispute Resolution Team will review the documentation
        submitted and any information readily available on the platform that
        pertains to the dispute. The Dispute Resolution Team will facilitate
        communication between the parties and help assess whether mutual
        resolution is possible. The Dispute Resolution Team does not evaluate
        the quality or functionality of work and cannot render binding judgment
        or determination as to the parties' respective rights to the disputed
        funds.
      </li>

      <li>
        Resolution of Dispute: If the Dispute Resolution Team is able to
        facilitate a resolution between the Client and the Service Provider that
        both the Client and the Service Provider agree to in writing, or by
        clicking on 'Accept Resolution' on the Paypipe platform, Client and
        Service Provider agree that Paypipe is authorized and irrevocably
        instructed to immediately release funds in accordance with the
        agreed-upon resolution. This will result in a closure of the dispute
        ticket.
      </li>

      <li>
        No Resolution: If no resolution of the dispute has been reached ' 14
        calendar days of the Notice of Dispute, or if the Dispute Resolution
        Team determines in its sole discretion that no resolution between the
        parties is possible through the Dispute Resolution Process, the Dispute
        Resolution Team will issue a “Notice of Non-Resolution” and parties
        should obtain independent legal advice.
      </li>
    </ol>
  </li>

  <li id="dispute-resolution-process-d">
    <h3>DISPUTE OF FUNDS RELEASED</h3>

    <p>
      This Clause applies to disputes filed by Clients or Service Providers over
      funds that have been released as of the date the dispute is filed
      (“Dispute Mediation”). The scope of the Dispute Mediation may cover any
      portion of or the entirety of the Fixed Price Job and any Milestone
      previously funded, approved, and released. The Dispute Resolution Process
      for Dispute Mediation will be administered as set forth below:
    </p>

    <ol>
      <li>
        Dispute Mediation Deadline: Dispute Mediation must be initiated ' 30
        calendar days of the date that funds in the relevant Paypipe Account
        have been released to the Service Provider in order to be eligible for
        the Dispute Resolution Process under this
        <a href="#dispute-resolution-process-d">Clause 39(d)</a>. Any dispute over funds that have
        been released from the relevant Paypipe Account more than 30 days prior
        to the date the dispute is filed is no longer eligible for submission to
        the Dispute Resolution Process.
      </li>

      <li>
        Requesting a Refund: Clients may initiate Dispute Mediation for disputes
        over funds that have been released to Service Provider by requesting a
        refund on the platform. If the Service Provider rejects the request for
        a refund, or takes no action, the dispute will be referred to the
        Dispute Resolution Team.
      </li>

      <li>
        Contacting Support: In addition to the above method, any User may
        contact Paypipe Support for assistance initiating Dispute Mediation.
      </li>

      <li>
        Notice of Dispute Mediation: Once a dispute has been initiated, the
        Paypipe Dispute Mediation team (“Dispute Mediation Team”) will notify
        the Client and the Service Provider of the Dispute Mediation in writing
        and request information and supporting documentation from the parties
        (“Notice of Dispute Mediation”). The Client and the Service Provider
        must respond to the Notice of Dispute Mediation ' five calendar days.
      </li>

      <li>
        Non-Binding Assistance: Once both the Client and the Service Provider
        respond to the Notice of Dispute Mediation, the Dispute Mediation Team
        will review the documentation submitted and any readily available
        information on the platform that pertains to the dispute. The Dispute
        Mediation Team will facilitate communication between the parties and
        help assess whether mutual resolution is possible. The Dispute Mediation
        Team does not evaluate the quality or functionality of work and cannot
        render binding judgment or determination as to the parties' respective
        rights to the disputed funds.
      </li>

      <li>
        Resolution of Dispute Mediation: If the Dispute Mediation Team is able
        to facilitate a resolution between a Client and Service Provider, and if
        the Client and the Service Provider agree in writing to the resolution,
        the Dispute Mediation Team will send the applicable party instructions
        on transferring payment, if any, to a Paypipe Account. By agreeing in
        writing to the resolution, the Client and the Service Provider agree
        that Paypipe is authorized and irrevocably instructed to immediately
        release funds in accordance with the agreed-upon resolution. This will
        result in the closure of the Dispute Mediation ticket.
      </li>

      <li>
        No Resolution: If no resolution of the dispute has been reached ' 14
        calendar days of the Notice of Dispute Mediation, or if the Dispute
        Mediation Team determines in its sole discretion that no resolution
        between the parties is possible through the Dispute Assistance Program,
        the Dispute Mediation Team will issue a “Notice of Non-Resolution”. In
        the case of a Notice of Non-resolution, the parties are advised to seek
        independent legal advice.
      </li>
    </ol>
  </li>
</ol>

          </div>
      

          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <bdt class="block-component"
              ><span style="font-size: 15px"></span
            ></bdt>
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="contact"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span style="line-height: 115%; font-family: Arial"
                ><span style="font-size: 19px"
                  ><strong
                    ><span style="line-height: 24.5333px; font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              id="contact-us-section-clause-no"
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >40</span
                            ></strong
                          ></span
                        ></strong
                      ></span
                    >&nbsp;</strong
                  >CONTACT US</span
                ></span
              ></strong
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >In order to resolve a complaint regarding the Services or to
              receive further information regarding use of the Services, please
              contact us at:</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5; text-align: left">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span style="font-size: 15px"
              ><span style="color: rgb(89, 89, 89)"
                ><bdt class="question"><strong>PAYPIPE PTY LTD</strong></bdt
                ><strong><bdt class="block-component"></bdt></strong></span
            ></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span style="font-size: 15px"
              ><span
                style="
                  line-height: 115%;
                  font-family: Arial;
                  color: rgb(89, 89, 89);
                "
                ><bdt class="question"
                  ><strong
                    ><bdt class="question">81-83 Campbell Street</bdt></strong
                  ></bdt
                ><span
                  style="
                    line-height: 115%;
                    font-family: Arial;
                    color: rgb(89, 89, 89);
                  "
                  ><bdt class="statement-end-if-in-editor"></bdt></span
                ><bdt class="block-component"></bdt></span
            ></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <span style="font-size: 15px"
              ><strong
                ><span style="color: rgb(89, 89, 89)"
                  ><bdt class="question"
                    ><bdt class="block-component"></bdt
                    ><bdt class="question">Surry Hills</bdt
                    ><bdt class="statement-end-if-in-editor"></bdt></bdt
                  ><bdt class="block-component"></bdt>,
                  <bdt class="question">New South Wales</bdt
                  ><bdt class="block-component"></bdt
                  ><bdt class="block-component"></bdt>
                  <bdt class="question">2010</bdt
                  ><bdt class="statement-end-if-in-editor"></bdt></span></strong
              ><strong
                ><span style="color: rgb(89, 89, 89)"
                  ><bdt class="block-component"></bdt></span
                ><bdt class="block-component"></bdt></strong
            ></span>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <bdt class="block-component"><strong></strong></bdt
            ><strong><bdt class="question">Australia</bdt></strong
            ><bdt class="statement-end-if-in-editor"></bdt
            ><bdt class="statement-end-if-in-editor"></bdt
            ><bdt class="statement-end-if-in-editor"><strong></strong></bdt>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                ><strong><bdt class="block-component"></bdt></strong></span
              >&nbsp;</strong
            >
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                ><strong><bdt class="block-component"></bdt></strong></span
            ></strong>
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5; text-align: left"
          >
            <strong
              ><span
                style="font-size:11.0pt;line-height:115%;font-family:Arial;
    Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                ><strong
                  ><bdt class="question"
                    ><bdt class="block-component"></bdt
                    ><bdt class="question">hello@paypipe.io</bdt
                    ><bdt
                      class="statement-end-if-in-editor"
                    ></bdt></bdt></strong></span
            ></strong>
          </div>
        </div>
      </div>
   `;
