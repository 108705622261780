"use client";

import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import * as yup from "yup";
import lodashGet from "lodash/get";

import {
  SocialLink,
  useIsValidPaypipeId,
  UserProfile,
  UserProfileUpdateFields,
  useUpdatePaypipeId,
  useUpdateProfile,
  useUpdateProfileImage,
} from "@/services/UserService";
import TextField from "@/components/input/TextField";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import ComboBox from "@/components/input/ComboBox";
import { squareSizing } from "@/utils/css";
import {
  SOCIAL_LINKS,
  SOCIAL_LINKS_MAP,
} from "@/services/UserService/UserService.config";
import { useToast } from "@/components/misc/Toast";
import { MAX_FILE_SIZE_IN_BYTES } from "@/config/app";
import { Body } from "@/components/Typography";
import { screenLargerThan, screenSmallerThan } from "@/styles";
import RingLoader from "@/components/misc/RingLoader";
import { useForm } from "@/components/input/Form";
import { useModalState } from "@/components/misc/Modal";
import Avatar from "@/components/misc/Avatar";
import { URL_REGEX } from "@/utils/string";

import EditFormButton from "./EditFormButtonNew";
import { IAddButtonProps } from "./AddButton"; 
// import LocationSuggessionInput from "@/features/input/LocationSuggessionInput";

//-----------------------------------

const ERRORS = {
  INVALID_LINK: "INVALID_LINK",
  MAX_LIMIT: "MAX_LIMIT",
};

const VALIDATION_SCHEMA = yup.object().shape({
  givenName: yup.string().required().min(1),
  familyName: yup.string().required().min(1),
  paypipeId: yup.string().required().min(1),
  links: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required().max(20, ERRORS.MAX_LIMIT),
        url: yup.string().required().matches(URL_REGEX, ERRORS.INVALID_LINK),
      })
    )
    .required(),
  location: yup.string(),
});

//-----------------------------------

const StyledContainer = styled.div`
  .field-row {
    & > * {
      margin-top: 1.25rem;
    }
  }

  ${screenLargerThan.tablet} {
    .field-row {
      display: grid;
      align-items: flex-start;
      grid-template-columns: 1fr 1fr;
      gap: 1.25rem;

      & > * {
        margin-top: 1.75rem;
      }
    }
  }

  .profile-image {
    display: flex;
    align-items: center;
    gap: 1rem;

    .image {
      ${squareSizing("6.25rem")};
      border-radius: 10rem;
      object-fit: cover;
    }

    label {
      position: relative;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
    }

    .button {
      background: #f5f5f5;
      padding: 0.75rem 1rem;
      border: none;
    }
  }
`;

const StyledLinkInput = styled.div`
  .cta-button {
    ${squareSizing("3.5rem")};
    border-radius: 10rem;
  }

  .url-field {
    padding-inline-end: 0;
  }

  & + & {
    margin-top: 1.25rem;
  }

  ${screenLargerThan.tablet} {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
  }

  ${screenSmallerThan.tablet} {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .field {
      flex-grow: 1;
    }
  }

  ${screenSmallerThan.tablet} {
    flex-wrap: wrap;
    gap: 0.75rem;

    & + & {
      margin-top: 1.75rem;
    }

    .select-field {
      width: 100%;
    }
  }
`;

const StyledDashedButton = styled(Button)`
  border-style: dashed;
  width: 100%;
  --bg-color: #f9fafb !important;
  border: 1px dashed rgba(0, 0, 0, 0.17);
  color: #737373;
  padding-block: 1rem;
`;

const StyledError = styled(Body)`
  color: #f04438;
`;

const StyledGhostButton = styled(Button)`
  display: block;
  text-align: center;
  margin-inline: auto;
`;

//-----------------------------------

type FormValues = {
  givenName: string;
  familyName: string;
  location: string;
  paypipeId: string;
  links: SocialLink[];
};

const SOCIAL_LINKS_OPTION = SOCIAL_LINKS.map((item) => ({
  ...item,
  label: item.displayName || item.platformName || "",
  value: item.platformName,
}));

const LinkInput: React.FC<{
  index: number;
  className?: string;
}> = ({ index, className }) => {
  const { values, setFieldValue, touchedAndHasError, setFieldTouched, errors } =
    useForm<FormValues>();
  const nameKey = `links[${index}].name`;
  const name = lodashGet(values, nameKey, "");
  const isNameMaxError = lodashGet(errors, nameKey, "") === ERRORS.MAX_LIMIT;

  const urlKey = `links[${index}].url`;
  const url = lodashGet(values, urlKey, "");
  const isUrlInvalid = lodashGet(errors, urlKey, "") === ERRORS.INVALID_LINK;

  const handleDelete = useCallback(() => {
    const updatedLinks = [...values.links];
    updatedLinks.splice(index, 1);
    setFieldValue("links", updatedLinks);
  }, [values.links, index, setFieldValue]);

  const { prefix = "" } = SOCIAL_LINKS_MAP[name] || {};

  const urlOptions = useMemo(
    () =>
      SOCIAL_LINKS_OPTION.filter(
        ({ value }) =>
          value === name || !values.links.find(({ name }) => value === name)
      ),
    [values.links, name]
  );

  return (
    <StyledLinkInput className={className}>
      <div className="field">
        <ComboBox
          allowsCustomValue
          scrollToTopOnFocus
          filterBySearch={false}
          placeholder="Select Platform"
          value={name}
          className="link-field"
          label="Social link"
          options={urlOptions}
          onChange={(value) => {
            setFieldValue(nameKey, value);
          }}
          hasError={touchedAndHasError(nameKey)}
          onBlur={() => {
            setFieldTouched(nameKey);
          }}
        />
        {isNameMaxError && (
          <StyledError size="md" className="mt-2 px-2">
            Please limit the link name to 20 characters max
          </StyledError>
        )}
      </div>

      <div className="field">
        <TextField
          scrollToTopOnFocus
          useFloatingLabel={false}
          className="field url-field"
          variant="background"
          hasError={touchedAndHasError(urlKey)}
          onBlur={() => {
            setFieldTouched(urlKey);
          }}
          appendContent={
            <Button
              variant="ghost"
              colorVariant="gray"
              className="cta-button"
              onClick={handleDelete}
            >
              <Icon
                isSrcRelative
                src="dustbin.svg"
                size="md"
                colorVariant="gray"
              />
            </Button>
          }
          value={prefix ? (!url || url === prefix ? "" : url) : url}
          onChange={(value) => {
            if (!prefix) {
              setFieldValue(urlKey, value);
              return;
            }

            if (value.includes(prefix)) {
              value = value.split(prefix)[1];
            }

            if (!value) {
              setFieldValue(urlKey, "");
              return;
            }

            setFieldValue(urlKey, `${prefix}${value}`);
          }}
          placeholder={prefix || "Enter URL"}
        />
        {isUrlInvalid && (
          <StyledError size="md" className="mt-2 px-2">
            Please enter a valid URL , i.e https://www.portfolio.com
          </StyledError>
        )}
      </div>
    </StyledLinkInput>
  );
};

//-----------------------------------

const EditMainDetails: React.FC<{
  className?: string;
  dashedVariantProps?: IAddButtonProps;
}> = ({ className, dashedVariantProps }) => {
  const addButtonRef = useRef<null | HTMLButtonElement>(null);

  const { createToast } = useToast();
  const { profileData, updateFields, accountData } = useUpdateProfile();
  const { update: updateProfileImage } = useUpdateProfileImage();
  const { update: updatePaypipeId } = useUpdatePaypipeId();

  const [isUpdating, setIsUpdating] = useState(false);
  const modalState = useModalState({
    onOpenChange: () => {
      setIsUpdating(false);
    },
  });

  const initialValues = useMemo(
    () =>
      ({
        givenName: accountData?.given_name || "",
        familyName: accountData?.family_name || "",
        paypipeId: accountData?.paypipe_id || "",
        location: profileData?.location || "",
        links: profileData?.links || [],
      } satisfies FormValues),
    [accountData, profileData]
  );

  const [paypipeId, setPaypipeId] = useState(accountData?.paypipe_id || "");
  const { data: paypipeIdValidationData, isValidating: isValidatingPaypipeId } =
    useIsValidPaypipeId({
      id: paypipeId === initialValues.paypipeId ? "" : paypipeId,
    });

  const [newImageFile, setNewImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState(accountData?.picture || "");
  const imageUpdated = !!newImageFile;

  //----------------------------

  const apply = useCallback(
    (values: FormValues) => {
      if (isUpdating) {
        return;
      }

      const { givenName, familyName, location, links, paypipeId } = values;

      const promises: Promise<any>[] = [];
      if (imageUpdated) {
        promises.push(updateProfileImage(newImageFile));
        setNewImageFile(null);
      }

      const paypipeIdUpdated = paypipeId !== initialValues.paypipeId;
      if (paypipeIdUpdated) {
        promises.push(updatePaypipeId(paypipeId));
      }

      const linksUpdated =
        JSON.stringify(links) !== JSON.stringify(initialValues.links || []);
      const locationUpdated = location !== initialValues.location;
      const profileDataUpdated = locationUpdated || linksUpdated;
      const accountDataUpdated =
        givenName !== initialValues.givenName ||
        familyName !== initialValues.familyName;
      if (profileDataUpdated || accountDataUpdated) {
        const updatedProfileData: Partial<UserProfile> = { location };
        if (linksUpdated) {
          updatedProfileData.links = links.filter(
            (item) => !!item.name && !!item.url
          );
        }

        const updatedAccountData: Partial<UserProfileUpdateFields> = {};
        if (accountDataUpdated) {
          updatedAccountData.given_name = givenName;
          updatedAccountData.family_name = familyName;
        }

        promises.push(
          updateFields(updatedProfileData, { accountData: updatedAccountData })
        );
      }

      setIsUpdating(true);
      return Promise.allSettled(promises).finally(() => {
        setIsUpdating(false);
      });
    },
    [
      updateFields,
      imageUpdated,
      updateProfileImage,
      newImageFile,
      updatePaypipeId,
      initialValues,
      isUpdating,
    ]
  );

  //--------

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      const [file] = e.target.files;

      if (file) {
        if ((file.size || 0) > MAX_FILE_SIZE_IN_BYTES) {
          createToast({
            variant: "error",
            title: "Please choose files smaller than 5mb",
            timeoutInMilliseconds: 1000 * 5,
          });

          return;
        }

        setImageSrc(URL.createObjectURL(file));
        setNewImageFile(file);
      }
    },
    [createToast]
  );

  //----------------------------

  const paypipeIdUpdated = paypipeId !== initialValues.paypipeId;
  const hasPaypipeIdError = paypipeIdUpdated
    ? isValidatingPaypipeId
      ? false
      : !paypipeIdValidationData.isValid
    : false;
  const disableSave = isValidatingPaypipeId || hasPaypipeIdError || isUpdating;
  const disableCancel = isUpdating;

  return (
    <EditFormButton<FormValues>
      heading="My information"
      dashedVariantProps={dashedVariantProps}
      modalState={modalState}
      saveButtonProps={{
        disabled: disableSave,
      }}
      cancelButtonProps={{
        disabled: disableCancel,
      }}
      modalProps={{
        width: "860px",
      }}
      editButtonProps={{ className }}
      formProps={{
        initialValues,
        yupValidationSchema: VALIDATION_SCHEMA,
        onSubmit: apply,
        onChange: (values) => {
          setPaypipeId(values.paypipeId);
        },
      }}
      content={({
        context: { values, setFieldValue, touchedAndHasError, setFieldTouched },
      }) => {
        const addLink = () => {
          const updatedLinks = [
            ...values.links,
            {
              name: "",
              url: "",
            },
          ];
          setFieldValue("links", updatedLinks);

          if (addButtonRef.current) {
            setTimeout(() => {
              addButtonRef.current?.scrollIntoView({
                behavior: "smooth",
              });
            }, 500);
          }
        };

        return (
          <StyledContainer>
            <div className="profile-image">
              <Avatar
                className="image"
                img={imageSrc}
                firstName={accountData?.given_name || ""}
                lastName={accountData?.family_name || ""}
              />

              <label>
                <Button
                  className="button"
                  colorVariant="gray"
                  disabled={isUpdating}
                >
                  Change Photo
                  <input
                    type="file"
                    onChange={handleChange}
                    accept="image/*"
                    disabled={isUpdating}
                  />
                </Button>
              </label>
            </div>

            <div className="field-row">
              <TextField
                variant="background"
                placeholder="First name"
                value={values.givenName}
                onChange={(value) => {
                  setFieldValue("givenName", value);
                }}
                hasError={touchedAndHasError("givenName")}
                onBlur={() => {
                  setFieldTouched("givenName");
                }}
              />

              <TextField
                variant="background"
                placeholder="Last name"
                value={values.familyName}
                onChange={(value) => {
                  setFieldValue("familyName", value);
                }}
                hasError={touchedAndHasError("familyName")}
                onBlur={() => {
                  setFieldTouched("familyName");
                }}
              />
            </div>

            <div className="field-row">
              <div>
                <TextField
                  variant="background"
                  placeholder="Paypipe ID"
                  value={values.paypipeId}
                  onChange={(value) => {
                    setFieldValue("paypipeId", value);
                  }}
                  hasError={
                    hasPaypipeIdError || touchedAndHasError("paypipeId")
                  }
                  onBlur={() => {
                    setFieldTouched("paypipeId");
                  }}
                  appendContent={isValidatingPaypipeId && <RingLoader />}
                />

                {!paypipeIdValidationData.isValid &&
                  paypipeIdValidationData.errors.map((error) => (
                    <StyledError size="md" key={error} className="mt-2 px-2">
                      - {error}
                    </StyledError>
                  ))}
              </div>

              <TextField
                variant="background"
                placeholder="Location"
                value={values.location}
                onChange={(value) => {
                  setFieldValue("location", value);
                }}
                hasError={touchedAndHasError("location")}
                onBlur={() => {
                  setFieldTouched("location");
                }}
              />
            </div>

            {/* <div className="field-row">
              <LocationSuggessionInput />
            </div> */}

            <hr className="mt-4 mb-4" />

            {values.links.map((_, i) => (
              <LinkInput key={i} index={i} className="mb-3" />
            ))}

            <StyledDashedButton
              ref={addButtonRef}
              colorVariant="gray"
              onClick={addLink}
            >
              <Icon isSrcRelative src="plus_round.svg" size="sm" />
              {values.links.length ? "Add Another Link" : "Add a Link"}
            </StyledDashedButton>

            <StyledGhostButton
              variant="ghost"
              colorVariant="black"
              className="mt-2"
              onClick={addLink}
            >
              Can't find what you're after?
            </StyledGhostButton>
          </StyledContainer>
        );
      }}
    />
  );
};

export default EditMainDetails;
