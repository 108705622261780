"use client";

import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import * as yup from "yup";
import lodashGet from "lodash/get";

import { Specialization, useUpdateProfile } from "@/services/UserService";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";
import ComboBox, { Item } from "@/components/input/ComboBox";
import SKILLS_LIST from "@/features/user/ProfileForm/content/skills.json";
import { screenLargerThan, screenSmallerThan } from "@/styles";
import { useForm } from "@/components/input/Form";
import { useModalState } from "@/components/misc/Modal";

import EditFormButton from "./EditFormButtonNew";
import DurationInput from "./DurationInput";
import { IAddButtonProps } from "./AddButton";

const VALIDATION_SCHEMA = yup.object().shape({
  experiences: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().min(1).required(),
        experience_duration: yup.number().min(1).required(),
        experience_duration_unit: yup.string().min(1).required(),
      })
    )
    .required(),
});

type FormValues = {
  experiences: Specialization[];
};

const OPTIONS: Item[] = SKILLS_LIST.map((item) => ({
  label: item,
  value: item,
})).splice(0, 10);

const StyledExperienceInput = styled.div`
  .cta-button {
    border-radius: 10rem;
    align-self: center;
    ${squareSizing("3.5rem")};
  }

  & + & {
    margin-top: 1.25rem;
  }

  ${screenSmallerThan.tablet} {
    display: flex;
    align-items: center;
    gap: 1rem;

    flex-wrap: wrap;
    gap: 0.75rem;

    & + & {
      margin-top: 1.75rem;
    }

    .field {
      flex-grow: 1;
      height: 100%;
    }

    .select-field {
      width: 100%;
    }
  }

  ${screenLargerThan.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 1rem;
  }
`;

const StyledDashedButton = styled(Button)`
  border-style: dashed;
  width: 100%;
  --bg-color: #f9fafb !important;
  border: 1px dashed rgba(0, 0, 0, 0.17);
  color: #737373;
  padding-block: 1rem;
  margin-top: 1.5rem;

  ${screenSmallerThan.tablet} {
    margin-top: 1.75rem;
  }
`;

const ExperienceInput: React.FC<{
  index: number;
  className?: string;
}> = ({ index, className }) => {
  const {
    values,
    touched,
    setFieldValue,
    setFieldTouched,
    touchedAndHasError,
  } = useForm<FormValues>();

  const nameKey = `experiences[${index}].name`;
  const name = lodashGet(values, nameKey, "");

  const durationKey = `experiences[${index}].experience_duration`;
  const duration = lodashGet(values, durationKey, 0);
  const durationTouched = lodashGet(touched, durationKey, false);

  const unitKey = `experiences[${index}].experience_duration_unit`;
  const unit = lodashGet(values, unitKey, "");

  const handleDelete = useCallback(() => {
    const updatedLinks = [...values.experiences];
    updatedLinks.splice(index, 1);
    setFieldValue("experiences", updatedLinks);
  }, [values.experiences, index, setFieldValue]);

  return (
    <StyledExperienceInput className={className}>
      <ComboBox
        allowsCustomValue
        placeholder="Select"
        value={name}
        className="field select-field"
        label="Test"
        options={OPTIONS}
        onChange={(value) => {
          setFieldValue(nameKey, value);
        }}
        hasError={touchedAndHasError(nameKey)}
        onBlur={() => {
          setFieldTouched(nameKey);
        }}
      />

      <DurationInput
        className="field duration-field"
        placeholder="Experience"
        duration={duration}
        unit={unit}
        onChange={({ duration, unit }) => {
          setFieldValue(durationKey, duration);
          setFieldValue(unitKey, unit);
        }}
        durationHasError={durationTouched && duration < 1}
        onBlur={() => {
          setFieldTouched(durationKey);
          setFieldTouched(unitKey);
        }}
      />

      <Button
        variant="secondary"
        colorVariant="gray"
        className="cta-button"
        onClick={handleDelete}
      >
        <Icon isSrcRelative src="dustbin.svg" size="sm" colorVariant="black" />
      </Button>
    </StyledExperienceInput>
  );
};

const EditExperience: React.FC<{
  className?: string;
  dashedVariantProps?: IAddButtonProps;
}> = ({ className, dashedVariantProps }) => {
  const addButtonRef = useRef<null | HTMLButtonElement>(null);

  const { profileData, updateField } = useUpdateProfile();
  const [isUpdating, setIsUpdating] = useState(false);
  const modalState = useModalState({
    onOpenChange: () => {
      setIsUpdating(false);
    },
  });

  const initialValues = useMemo(
    () =>
      ({
        experiences: profileData.specializations || [],
      } satisfies FormValues),
    [profileData]
  );

  //---------------------

  const apply = useCallback(
    ({ experiences }: FormValues) => {
      setIsUpdating(true);

      return updateField("specializations", experiences).finally(() => {
        setIsUpdating(false);
      });
    },
    [updateField]
  );

  //---------------------

  return (
    <EditFormButton<FormValues>
      heading="Add Experience"
      modalState={modalState}
      dashedVariantProps={dashedVariantProps}
      editButtonProps={{ className }}
      formProps={{
        initialValues,
        yupValidationSchema: VALIDATION_SCHEMA,
        onSubmit: apply,
      }}
      saveButtonProps={{
        disabled: isUpdating,
      }}
      cancelButtonProps={{
        disabled: isUpdating,
      }}
      modalProps={{
        width: "968px",
      }}
      content={({
        context: {
          values: { experiences },
          setFieldValue,
        },
      }) => (
        <div>
          {experiences.map((_, i) => (
            <ExperienceInput key={i} index={i} />
          ))}

          <StyledDashedButton
            ref={addButtonRef}
            colorVariant="gray"
            onClick={() => {
              const updated = [
                ...experiences,
                {
                  name: "",
                  experience_duration: 0,
                  experience_duration_unit: "Days",
                  id: -1,
                },
              ];
              setFieldValue("experiences", updated);

              if (addButtonRef.current) {
                setTimeout(() => {
                  addButtonRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }, 500);
              }
            }}
          >
            <Icon isSrcRelative src="plus_round.svg" size="sm" />
            {experiences.length ? "Add Another Experience" : "Add Experience"}
          </StyledDashedButton>
        </div>
      )}
    />
  );
};

export default EditExperience;
