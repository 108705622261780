"use client";

import React, { useCallback, useEffect, useRef } from "react";
import { useKeyboard } from "react-aria";
import { useTextField } from "@react-aria/textfield";
import { mergeProps, useId } from "@react-aria/utils";

import { joinClassNames } from "@/utils/classNames";

import {
  StyledInput,
  StyledInputContainer,
  StyledTextArea,
  StyledTextAreaContainer,
} from "./TextField.styles";
import { ITextFieldProps } from "./TextField.types";
import { useResponsive } from "@/styles";

const TextField: React.FC<ITextFieldProps> = ({
  prependContent,
  appendContent,
  className,
  label = "",
  id: idFromProps,
  style,
  textArea,
  inputClassName,
  onClick,
  onBlur,
  hasError = false,
  useFloatingLabel: useFloatingLabelFromProps = true,
  variant = "outline",
  onKeyDown,
  onKeyUp,
  min,
  max,
  scrollToTopOnFocus = true,
  scrollToTopDelayInMs = 500,
  ...restProps
}) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  const containerRef = useRef<any>();
  const ref = useRef<any>();
  const ariaLabel = label || restProps.name || restProps.placeholder;
  const { inputProps } = useTextField(
    {
      ...restProps,
      className: inputClassName,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabel,
      isDisabled: restProps.disabled,
    } as any,
    ref
  );

  const { keyboardProps } = useKeyboard({
    onKeyDown,
    onKeyUp,
  });

  const id = useId(idFromProps);

  const props = {
    ...mergeProps(restProps, inputProps),
  };

  const ContainerComponent = textArea
    ? StyledTextAreaContainer
    : StyledInputContainer;

  const useFloatingLabel = useFloatingLabelFromProps && variant !== "outline";

  //--------------------------------

  const scrollInToView = useCallback(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, scrollToTopDelayInMs);
  }, [scrollToTopDelayInMs]);

  useEffect(() => {
    if (!isScreenSmallerThanTablet || !scrollToTopOnFocus) {
      return;
    }

    const containerEl = containerRef.current;
    if (containerEl) {
      containerEl.addEventListener("click", scrollInToView, true);
    }

    const inputEl = ref.current;
    if (inputEl) {
      inputEl.addEventListener("focus", scrollInToView, true);
    }

    return () => {
      if (containerEl) {
        containerEl.removeEventListener("click", scrollInToView, true);
      }

      if (inputEl) {
        inputEl.removeEventListener("focus", scrollInToView, true);
      }
    };
  }, [scrollInToView, isScreenSmallerThanTablet, scrollToTopOnFocus]);

  //--------------------------------

  return (
    <ContainerComponent
      ref={containerRef}
      $hasValue={!!inputProps.value}
      $hasLabel={!!ariaLabel}
      $hasError={hasError}
      $useFloatingLabel={useFloatingLabel}
      $variant={variant}
      className={className}
      style={style}
      onClick={onClick}
    >
      {prependContent}
      {textArea ? (
        <StyledTextArea
          {...(props as any)}
          rows="1"
          $hasError={hasError}
          $useFloatingLabel={useFloatingLabel}
          ref={(value) => {
            ref.current = value;

            if (value) {
              value.style.height = "auto";
              value.style.height = `${value.scrollHeight}px`;
            }
          }}
          {...inputProps}
          {...keyboardProps}
          className={joinClassNames(inputClassName, inputProps.className)}
          onChange={(e: any) => {
            if (inputProps.onChange) inputProps.onChange(e);

            e.target.style.height = "auto";
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          aria-label={ariaLabel}
          id={idFromProps || id}
          onBlur={onBlur}
        />
      ) : (
        <StyledInput
          {...(props as any)}
          $variant={variant}
          $hasError={hasError}
          $useFloatingLabel={useFloatingLabel}
          ref={ref}
          {...inputProps}
          {...keyboardProps}
          className={joinClassNames(inputClassName, inputProps.className)}
          aria-label={ariaLabel}
          id={idFromProps || id}
          min={min}
          max={max}
          onBlur={onBlur}
        />
      )}
      {useFloatingLabel && <label>{ariaLabel}</label>}
      {appendContent}
    </ContainerComponent>
  );
};

export default TextField;
