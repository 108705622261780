import React from "react";

import {
  StyledAuthenticationButton,
  StyledBottomSection,
  StyledBottomSectionContent,
  StyledContent,
  StyledContentSection,
  StyledHeading,
  StyledInputContainer,
  StyledLogo,
  StyledPage,
  StyledTopSection,
  StyledTopSectioncontainer,
} from "./OnboardingPage.styles";

const OnboardingPage: React.FC<{
  inputSection?: React.ReactNode;
}> = ({ inputSection }) => {
  return (
    <StyledPage>
      <StyledTopSectioncontainer>
        <StyledTopSection>
          <img
            className="swatch-1"
            src="/assets/images/pages/onboarding/offer_accepted.svg"
            alt=""
          />
          <img
            className="swatch-2"
            src="/assets/images/pages/onboarding/payment_released.svg"
            alt=""
          />
        </StyledTopSection>
      </StyledTopSectioncontainer>

      <StyledBottomSection>
        <StyledBottomSectionContent>
          {false && <StyledLogo />}

          <StyledContentSection>
            <div>
              <StyledHeading size="md">
                Get paid on time. Every time.
              </StyledHeading>
              <StyledContent size="md">
                Get paid faster, effortlessly with Paypipe. Simplified
                proposals, seamless communication, and on-time payments so you
                can focus on what you do best.
              </StyledContent>
            </div>
          </StyledContentSection>

          {!!inputSection ? (
            inputSection
          ) : (
            <StyledInputContainer>
              <StyledAuthenticationButton
                authType="login"
                className="w-100 pt-2 pb-2 mt-1"
              >
                Login to Paypipe
              </StyledAuthenticationButton>
              <StyledAuthenticationButton
                authType="register"
                className="w-100"
                variant="ghost"
                colorVariant="black"
              >
                Sign up
              </StyledAuthenticationButton>
            </StyledInputContainer>
          )}
        </StyledBottomSectionContent>
      </StyledBottomSection>
    </StyledPage>
  );
};

export default OnboardingPage;
