import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";

import {
  Portfolio,
  useSubscriptionDetails,
  useUpdateProfile,
} from "@/services/UserService";
import PortfolioCard from "@/features/PaypipeId/PortfolioCard";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { Body, Heading } from "@/components/Typography";
import Modal, { useModalState } from "@/components/misc/Modal";
import { FileObject } from "@/features/input/FilesInput";
import { mediaService } from "@/config/services";
import { generateRandomString } from "@/utils/string";
import DeleteConfirmation from "@/features/user/ProfileForm/components/DeleteConfirmation";
import { useToast } from "@/components/misc/Toast";
import { getViewPortHeightCssString, screenSmallerThan } from "@/styles";

import EditPortfolio from "./EditPortfolioNew";
import { MAX_NON_PREMIUM_USER_PORTFOLIO_COUNT } from "@/services/UserService/UserService.config";
import AddButton from "./AddButton";

export const StyledContainer = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
`;

const StyledDashedButton = styled(AddButton)`
  border-style: dashed;
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.17);
  padding-block: 1rem;
  height: 20.625rem;
  flex-direction: column;
  border-radius: 0.625rem !important;
`;

const StyledDescription = styled(Body)`
  color: #64748b;
`;

const StyledModal = styled(Modal)`
  ${screenSmallerThan.tablet} {
    padding: 0;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;

  & > .content {
    flex-grow: 1;
    overflow: auto;
  }

  ${screenSmallerThan.tablet} {
    height: ${getViewPortHeightCssString()};
    overflow: hidden;
    padding-block: 1rem;

    & > * {
      padding-inline: 1rem;
    }
  }
`;

export type EditingPortfolio = Portfolio & { assets?: FileObject[] };

const PortfolioSection: React.FC<{
  portfolios: Portfolio[];
  canEdit?: boolean;
}> = ({ portfolios: portfoliosFromProps, canEdit }) => {
  const { createToast } = useToast();
  const { hasPremium } = useSubscriptionDetails();
  const { updateField, isProfileDataLoading } = useUpdateProfile();
  const [editingIndex, setEditingIndex] = useState<null | number>(null);
  const [portfolios, setPortfolios] = useState(portfoliosFromProps);
  const [editingPortfolioData, setEditingPortfolioData] =
    useState<EditingPortfolio | null>(null);
  const modalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setIsSaving(false);
        setEditingIndex(null);
        setEditingPortfolioData(null);
      }
    },
  });
  const [isSaving, setIsSaving] = useState(false);

  const [deletionIndex, setDeletionIndex] = useState<null | number>(null);
  const deletionModalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setDeletionIndex(null);
      }
    },
  });

  //-----------------------------

  const handleApply = useCallback(
    (values: EditingPortfolio) => {
      if (editingIndex === null) {
        modalState.close();
        return;
      }

      const updatedPortfolios = [...portfolios];

      setIsSaving(true);

      (async function () {
        try {
          const { assets = [] } = values;
          const payload = { ...values };

          const updatedAttatchments = await Promise.all(
            assets
              .filter(({ file, mediaFile }) => !!file || !!mediaFile)
              .map(({ file, mediaFile }) =>
                mediaFile
                  ? Promise.resolve(mediaFile)
                  : mediaService
                      .uploadFile(file as File)
                      .then((res) => res.data.data)
              )
          );
          payload.attachments = updatedAttatchments;
          delete payload.assets;
          if (editingIndex === -1) {
            updatedPortfolios.push(payload);
          } else {
            updatedPortfolios[editingIndex] = payload;
          }
          setPortfolios(updatedPortfolios);

          await updateField("portfolios", updatedPortfolios);
        } catch {}
      })().then(() => {
        setIsSaving(false);
        modalState.close();
      });
    },
    [portfolios, editingIndex, updateField, modalState]
  );

  const handleDelete = useCallback(
    (index: number) => {
      const updatedPortfolios = [...portfolios];
      updatedPortfolios.splice(index, 1);
      setPortfolios(updatedPortfolios);
      updateField("portfolios", updatedPortfolios)
        .then(() => {
          createToast({
            title: "Portfolio deleted",
            timeoutInMilliseconds: 5000,
          });
        })
        .catch(() => {
          createToast({
            variant: "error",
            title: "Something went wrong, Portfolio not deleted.",
            timeoutInMilliseconds: 8000,
          });
          setPortfolios(portfoliosFromProps);
        });
    },
    [portfolios, updateField, createToast, portfoliosFromProps]
  );

  const handleAdd = useCallback(() => {
    setEditingIndex(-1);
    setEditingPortfolioData({
      id: generateRandomString(),
      name: "",
      description: "",
      tech: [],
      attachments: [],
    } satisfies EditingPortfolio);
    modalState.open();
  }, [modalState]);

  //-----------------------------

  if (isProfileDataLoading) {
    return <SplashScreen />;
  }

  const isCreatingNew = editingIndex === -1;
  const showAddButton =
    canEdit &&
    (hasPremium || portfolios.length < MAX_NON_PREMIUM_USER_PORTFOLIO_COUNT);

  return (
    <>
      <Modal state={deletionModalState}>
        {deletionIndex !== null && (
          <DeleteConfirmation
            title="Delete confirmation"
            description={`Are you sure want to delete "${portfolios[deletionIndex].name}" ?`}
            onCancel={deletionModalState.close}
            onDelete={() => {
              handleDelete(deletionIndex);
              deletionModalState.close();
            }}
          />
        )}
      </Modal>

      <StyledModal
        showCloseButton
        state={modalState}
        isDismissable={false}
        isKeyboardDismissDisabled={false}
        width="1080px"
        mobileProps={{ fullscreen: true }}
      >
        <StyledContent>
          <div className="header">
            <Heading size="lg" className="mb-2">
              {isCreatingNew ? "Add New Portfolio Piece" : "Edit Portfolio"}
            </Heading>
            <StyledDescription size="md" className="mb-4 description">
              Make changes to user profile here. Click save when you're done.
            </StyledDescription>
          </div>

          <div className="content">
            {!!editingPortfolioData && (
              <EditPortfolio
                portfolio={editingPortfolioData}
                onCancel={modalState.close}
                onSave={handleApply}
                cancelButtonProps={{
                  disabled: isSaving,
                }}
                saveButtonProps={{
                  disabled: isSaving,
                  children: isCreatingNew
                    ? isSaving
                      ? "Adding"
                      : "Add"
                    : isSaving
                    ? "Saving"
                    : "Save",
                }}
              />
            )}
          </div>
        </StyledContent>
      </StyledModal>

      <StyledContainer>
        {portfolios.map((p, i) => (
          <PortfolioCard
            key={i}
            portfolio={p}
            className="mb-3"
            canEdit={canEdit}
            onDelete={() => {
              setDeletionIndex(i);
              deletionModalState.open();
            }}
            onEdit={() => {
              setEditingIndex(i);
              setEditingPortfolioData(p);
              modalState.open();
            }}
          />
        ))}

        {showAddButton &&
          (portfolios.length ? (
            <StyledDashedButton colorVariant="gray" onClick={handleAdd}>
              Add Portfolio Piece
            </StyledDashedButton>
          ) : (
            <AddButton size="lg" onClick={handleAdd}>
              Highlight a project, your role, and key results. Include visuals
              if possible.
            </AddButton>
          ))}
      </StyledContainer>
    </>
  );
};

export default PortfolioSection;
