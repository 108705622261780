"use client";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { Body } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";
import Button from "@/components/input/Button";

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid var(--clr-border-25, #f6f8fa);
`;

export const StyledIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${squareSizing("3rem")};
  border-radius: 10rem;
`;

export const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledRightSection = styled.div`
  flex: 1;
`;

export const StyledContent = styled(Body)`
  color: var(--clr-neutral-solid-700, #272835);
`;

export const StyledCta = styled(Button)`
  padding: 0.35rem 0.5rem;
  text-transform: capitalize;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
