"use client";

import React, { useMemo } from "react";

import {
  useContractList,
  useManageContract,
} from "@/services/ContractsService";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import { Display } from "@/components/Typography";
import { SITE_PATHS } from "@/config/routing";
import { getChatRoomPageUrl } from "@/services/ChatService";
import { useHash } from "@/services/Routing";
import { IBreadcrumbItemProps } from "@/components/navigation/Breadcrumb";
import { appFeatures } from "@/config/services";

import {
  NoChatContainer,
  StyledCardSection,
  StyledChatRoomPage,
  StyledChatRoomSection,
  StyledContainer,
} from "./ChatsPage.styles";
import ChatList from "./components/ChatList";
import SearchInput from "./components/SearchInput";
import NoChats from "./components/NoChats";
import { CHAT_DETAILS_HASH, CONTRACT_DETAILS_HASH } from "../ChatRoomPage";

const ChatsPage: React.FC = () => {
  const { hash } = useHash();
  const { contractId: contractIdFromUrl, contractDetails } = useManageContract({
    redirectIfNotFound: false,
    useContractIdFromUrl: true,
  });
  const { data: contractList, isLoading } = useContractList();

  const hasNoData = !contractList.length && !isLoading;

  const breadCrumbs = useMemo(() => {
    const items: IBreadcrumbItemProps[] = [];

    if (contractIdFromUrl) {
      items.push({
        ariaLabel: "Chat list page",
        children: "Chats",
        href: SITE_PATHS.CHAT_PAGE,
      });

      items.push({
        ariaLabel: "Chat room page",
        children: contractDetails?.title,
        href: getChatRoomPageUrl(contractIdFromUrl),
      });

      if (hash) {
        if (hash === CHAT_DETAILS_HASH) {
          items.push({
            ariaLabel: "Chat details",
            children: "Details",
            href: `${getChatRoomPageUrl(
              contractIdFromUrl
            )}${CHAT_DETAILS_HASH}`,
          });
        }

        if (hash === CONTRACT_DETAILS_HASH) {
          items.push({
            ariaLabel: "Job details",
            children: "Job",
            href: `${getChatRoomPageUrl(
              contractIdFromUrl
            )}${CONTRACT_DETAILS_HASH}`,
          });
        }
      }
    }

    return items;
  }, [contractIdFromUrl, contractDetails, hash]);

  const noChatJsx = (
    <NoChatContainer>
      <NoChats />
    </NoChatContainer>
  );
  const chatJsx = (
    <StyledContainer>
      <StyledCardSection>
        <Display size="md">Chats</Display>

        <SearchInput />
        <ChatList />
      </StyledCardSection>

      <StyledChatRoomSection>
        {contractIdFromUrl && <StyledChatRoomPage />}
      </StyledChatRoomSection>
    </StyledContainer>
  );

  return (
    <DesktopPrimaryLayout
      headerProps={{ logo: { component: "Inbox" } }}
      breadcrumbs={breadCrumbs}
    >
      {hasNoData
        ? appFeatures.isSupported("CHAT.USE_STREAMIO")
          ? chatJsx
          : noChatJsx
        : chatJsx}
    </DesktopPrimaryLayout>
  );
};

export default ChatsPage;
