import React from "react";
import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { squareSizing } from "@/utils/css";
import Icon from "@/components/misc/Icon";
import {
  overflowEllipsis,
  screenLargerThan,
  screenSmallerThan,
} from "@/styles";

const StyledContainer = styled.div`
  background: #ffffff;
  border-radius: 2.25rem;
  overflow: hidden;

  ${screenLargerThan.tablet} {
    display: flex;
    align-items: center;
    padding: 3rem 4rem 4rem 4rem;
  }

  .highlighted-content {
    display: flex;
    align-items: center;
    color: var(--clr-primary-100, #5f57ff);
    background: rgba(95, 87, 255, 0.1);
    padding: 0.25rem;
    padding-inline-end: 0.5rem;
    border-radius: 10rem;
    width: fit-content;

    .icon {
      ${squareSizing("2rem")};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--clr-primary-100, #5f57ff);
      padding: 0.25rem;
      border-radius: 10rem;
      margin-right: 0.5rem;
    }

    ${screenSmallerThan.tablet} {
      margin: 0 auto;
    }
  }

  .title {
    font-size: 4rem;
    font-weight: 400;
    color: #000000;
    text-transform: capitalize;

    b {
      color: var(--clr-primary-100, #5f57ff);
    }

    ${screenSmallerThan.tablet} {
      text-align: center;
    }
  }

  .content {
    font-size: 1.5rem;
    font-weight: 400;
    color: #000000;

    ${screenSmallerThan.tablet} {
      text-align: center;
    }
  }

  .cta-container {
    font-size: 1rem;
    font-weight: 400;
    color: #949494;
    background: #f6f6f6;
    border-radius: 10rem;
    padding: 0.5rem;

    button {
      border-radius: inherit;
    }

    ${screenSmallerThan.tablet} {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      & > span {
        ${overflowEllipsis()}
      }
    }

    ${screenLargerThan.tablet} {
      font-size: 1.5rem;
      padding-inline-start: 1.5rem;
      width: max-content;

      button {
        margin-inline-start: 0.75rem;
      }
    }
  }

  .content-container {
    ${screenLargerThan.tablet} {
      width: 40%;
      max-width: 32rem;
    }

    ${screenSmallerThan.tablet} {
      padding: 3rem 1.25rem 0 1.25rem;
    }
  }

  .illustration {
    object-fit: contain;

    ${screenLargerThan.tablet} {
      height: 100%;
      width: auto;
      margin-bottom: -4rem;
      max-width: 60%;
    }

    ${screenSmallerThan.tablet} {
      margin-top: 2rem;
      height: auto;
      width: 100%;
    }
  }
`;

const ClaimIdsection: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledContainer className={className}>
      <div className="content-container">
        <div className="highlighted-content">
          <span className="icon">
            <Icon
              src="/assets/pages/paypipe-id/claim.svg"
              colorVariant="white"
              size="xs"
            />
          </span>
          Claim your Paypipe ID now!
        </div>

        <div className="title mt-3">
          Want this to be <b>you?</b>
        </div>

        <div className="content mt-3">
          Create your own Paypipe profile and begin simplifying your work
          process today.
        </div>

        <div className="cta-container mt-3">
          <span>paypipe.me/yourname</span>
          <Button>Claim Your Paypipe ID</Button>
        </div>
      </div>

      <img
        className="illustration"
        src="assets/pages/paypipe-id/cta-illustration.png"
        alt=""
      />
    </StyledContainer>
  );
};

export default ClaimIdsection;
