"use client";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Button from "@/components/input/Button";
import { squareSizing } from "@/utils/css";
import { screenSmallerThan, transientOptions } from "@/styles";

import { MenuVariant } from "./NavMenu.types";

export const StyledNav = styled.div``;

export const StyledUl = styled.ul<{
  $varaint: MenuVariant;
  $sidebarCompact?: boolean;
}>`
  list-style: none;
  padding: 0;
  margin: 0;
  background: var(--clr-background-primary, #fff);

  li {
    position: relative;
    ${({ $sidebarCompact }) => ($sidebarCompact ? "padding: 0 !important;" : "")};
  }

  ${({ $varaint, $sidebarCompact }) =>
    $varaint === "b"
      ? `
        li {
          margin-top: ${$sidebarCompact ? "0.75rem" : "0.5rem"};
        }
      `
      : $varaint === "a"
      ? `
        li + li {
          border-top: 1px solid var(--clr-border-ukwn-8, #f8f8fb);
        }
      `
      : ""}
`;

export const StyledIconContainer = styled.div`
  ${squareSizing("2.5rem")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;

  ${screenSmallerThan.tablet} {
    background: var(--clr-background-0, #f8fafb);
  }
`;

export const StyledTitle = styled.div<{ $sidebarCompact?: boolean }>`
  flex: 1;
  font-size: ${({ $sidebarCompact }) =>
    $sidebarCompact ? "0.75rem" : "0.875rem"};
  font-weight: ${({ $sidebarCompact }) => ($sidebarCompact ? "500" : "600")};
  line-height: ${({ $sidebarCompact }) =>
    $sidebarCompact ? "1rem" : "1.25rem"};
  text-align: left;
`;

const NAV_ITEMS_STYLES = ({
  $active,
  $varaint,
  $sidebarCompact,
}: {
  $active?: boolean;
  $varaint?: MenuVariant;
  $sidebarCompact?: boolean;
} = {}) => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${$sidebarCompact ? "column" : "row"};
  gap: ${$sidebarCompact ? "0rem" : "1rem"};
  ${$sidebarCompact ? "" : "width: 100%;"};
  text-decoration: none;
  color: ${
    $active ? "var(--clr-text-900, #0d0d12)" : "var(--clr-text-500, #666D80)"
  };
 
  ${
    $varaint === "b"
      ? `
        padding-block: 0.15rem;
        ${$sidebarCompact ? "padding: 0 !important" : "padding-inline: 0.35rem 0.75rem"};
        border-radius: 0.5rem;
        background: ${
          $active ? "var(--clr-neutral-solid-25, #f6f8fa)" : "transparent"
        };

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0.25rem;
          width: 0.25rem;
          height: 60%;
          min-height: 1.25rem;
          background: var(--clr-primary-100, #5f57ff);
          transform: translate( ${$active ? "0" : "-1rem"}, -50%);
          opacity: ${$active ? "1" : "0"};
          border-top-right-radius: 1.25rem;
          border-bottom-right-radius: 1.25rem;
          transition: transform 240ms ease;
        }
      `
      : $varaint === "a"
      ? `
        padding-block: 0.75rem;
      `
      : ""
  }

  
`;

export const StyledLink = styled(Link, transientOptions)<{
  $active?: boolean;
  $varaint?: MenuVariant;
  $sidebarCompact?: boolean;
}>`
  ${NAV_ITEMS_STYLES}
`;

export const StyledButton = styled(Button)<{
  $active?: boolean;
  $varaint?: MenuVariant;
  $sidebarCompact?: boolean;
}>`
  ${NAV_ITEMS_STYLES}
`;
